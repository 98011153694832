import React from "react";
import LinkCustom from "./Link";
import TableHeaderSpace from "./TableHeaderSpace";
import useWindowWidth from "./WindowWidth";
import { t } from "../../CentralDataMangement/translation/Translation";
import NoRecords from "./NoRecords";

interface TableHeaderProps {
  label: string;
  width: number;
}

interface ActionItem {
  label: string;
  icon: any;
  handleClick: (value: any) => void;
  iconClassName: string;
}
interface TableProps {
  headers: TableHeaderProps[];
  content: any;
  isSNo: boolean;
  actionlabel?: string;
  variables?: any;
  action?: ActionItem[];
}

const Table: React.FC<TableProps> = ({
  headers,
  content,
  actionlabel,
  variables,
  action,
  isSNo,
}) => {
  const isDesktop = useWindowWidth(1024);

  return (
    <>
      {
        content?.length > 0 ? (
          <table className="table pwa table-hover">
            <thead className="headSticky">
              <tr className="TableHeader">
                {headers?.map((header, index) => (
                  <th key={index} style={{ width: `${header?.width}%` }}>
                    {header?.label}
                  </th>
                ))}
                {actionlabel && <th>{actionlabel}</th>}
              </tr>
              <TableHeaderSpace headerLength={headers?.length} />
            </thead>
            <tbody>{
              content?.map((row: any, index: number) => (
                <tr key={row?.id}>
                  {/* Map through variables and headers */}
                  {variables.map((variable: any, i: number) => (
                    <td key={variable} data-label={headers[i]?.label}>
                      {row[variable]}
                      {row[variable] === true && "Active"}
                    </td>
                  ))}
                  {/* Actions column */}
                  <td className="px-2">
                    {action?.map((item: any) => (
                      isDesktop ? (
                        <LinkCustom
                          key={item?.label}
                          className={item?.iconClassName}
                          onClick={() => item?.handleClick(row)}
                        >
                          <span title={item?.label}>{item?.icon}</span>
                        </LinkCustom>) : (
                        <LinkCustom key={item?.label} className="searchBtn text-decoration-none w-75 d-flex align-items-center justify-content-center mx-auto mb-3"
                          onClick={() => item?.handleClick(row)}>
                          {item?.label} </LinkCustom>

                      )
                    ))}
                  </td>
                </tr>
              ))}

            </tbody>


          </table>
        ) :
          <NoRecords isDiv={true} message={t("No results found")} className="text-danger" />}
    </>
  );
};

export default Table;
