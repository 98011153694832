import React, { useState } from "react";
import { t } from "../CentralDataMangement/translation/Translation";
import CheckBoxField from "../common/atoms/CheckBoxField";
import LabelField from "../common/atoms/LabelField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import Calender from "../common/molecules/Calender";
import LeftPart from "./LeftPart";
import ParitiarCommitteeSection from "./ParitairCommiteeSection";
import RightPart from "./RightPart";
import Navigation from "./Navigation";
import { CooperationAgreementState, Formdata, Option } from "./Interfaces";
import RadioField from "../common/atoms/RadioField";
import { validateSection } from "./Service";
import { AbsoluteyouAgentMandatory } from "./State";
import { getNestedValue } from "./CompanyInformation";


interface AbsoluteyouAgentProps {
  agreementData: any;
  formState?: CooperationAgreementState;
  section: keyof Formdata;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | Option, section: keyof Formdata) => void;
  handleDateFormat?: (dateValue: Date | null, fieldPath: string, section: keyof Formdata, index?: number) => void;
  errors: any;
  updateErrors: any;
  handleSelectChange: (selectedOption: Option, fieldPath: string, section: keyof Formdata) => void;
  countries?: Array<Option> | any;
  handleNext: () => void;
  handlePrevious: () => void;
  handleSaveAsDraftClick: () => void;
}
const AbsoluteyouAgent: React.FC<AbsoluteyouAgentProps> = ({
  agreementData,
  handleChange,
  formState,
  section,
  handleDateFormat,
  errors,
  updateErrors,
  handleSelectChange,
  countries,
  handleNext,
}) => {
  const [isLastTab, setIsLastTab] = useState(false);
  const handlenext = () => {
    const error = validateSection(section, agreementData, AbsoluteyouAgentMandatory, []);
    updateErrors(section, error);
    if (Object?.keys(error)?.length > 0) {
      return;
    } else {
      handleNext();
    }
  };

  const handlePrevious = () => {
    console.log("Previous clicked");
  };

  return (
    <>
      <div className="row">
        <div className="col-4">
          <div className="position-relative agreementStartDate">
            <Calender
              onChange={(date: Date | null) => {
                if (date) {
                  handleDateFormat?.(date, "start_date", section);
                }
              }}
              label={t("Start date of agreement")}
              selectedDate={agreementData?.start_date ? new Date(agreementData?.start_date) : null}
              name="start_date"
              error={getNestedValue(errors?.[section], "start_date") || ""}  // Fix error prop
              isDisabled={agreementData?.start_date ? true : false}
              isMandatory
            />
          </div>
        </div>
        <div className="col-4 multiSelectWrapper">
          <SelectWithSearch
            fieldLabel={`${t("AbsoluteYou consultant")}:`}
            title={t("AbsoluteYou consultant")}
            name="sales_agent_id"
            placeHolder={t("Select")}
            search
            options={formState?.sales || []}
            value={formState?.sales?.find(
              (option: Option) => option.value === agreementData?.sales_agent_id
            ) || null}
            onChange={(selectedOption: Option) => {
              handleSelectChange(selectedOption, "sales_agent_id", section);
            }}
            isMulti={false}
            isDisabled={agreementData?.sales_agent_id ? true : false}
            className="select-field"
            error={getNestedValue(errors?.[section], "sales_agent_id") || ""}
            isTranslate
            isMandatory
          />
        </div>
        <div className="col-4 multiSelectWrapper">
          <SelectWithSearch
            fieldLabel={`${t("AbsoluteYou office number")}:`}
            title={t("AbsoluteYou office number")}
            name="FirstStep.office_id"
            placeHolder={t("Select")}
            search
            options={formState?.offices?.[agreementData?.sales_agent_id] || []}
            value={formState?.offices?.[agreementData?.sales_agent_id]?.find(
              (option: Option) => option.value === agreementData?.FirstStep?.office_id
            ) || null}
            onChange={(selectedOption: Option) => {
              handleSelectChange(selectedOption, "FirstStep.office_id", section);
            }}
            isMulti={false}
            isDisabled={agreementData?.FirstStep?.office_id ? true : false}
            className="select-field"
            error={getNestedValue(errors?.[section], "FirstStep.office_id") || ""}
            isTranslate
            isMandatory
          />
        </div>
        <div className="col-12 my-3">
          <CheckBoxField
            name="FirstStep.add_project"
            label={
              t("Activate 'Add project' for the employer in the planning") + "."
            }
            className="my-checkbox"
            isChecked={agreementData?.FirstStep?.add_project}
            onChangeHandler={(e) => handleChange(e, section)}
            id="activateAddProject"
            lineHeight="1vw"
          />
        </div>
        <div className="col-6 mb-3">
          <div className="mb-2">
            <LabelField title={t("In which language Werkpostfiche should be present")} />
          </div>
          <RadioField
            name="FirstStep.wp_fische_language"
            label={t("Dutch")}
            className="radioBtn"
            ischecked={Number(agreementData?.FirstStep?.wp_fische_language) === 1}
            handleChange={(e) => handleChange(e, section)}
            id="yesLabourRegulations"
            value={1}
            containerClassName="companyLanguage me-4 d-inline-flex"
          />

          <RadioField
            name="FirstStep.wp_fische_language"
            label={t("French")}
            className="radioBtn"
            ischecked={Number(agreementData?.FirstStep?.wp_fische_language) === 2}
            handleChange={(e) => handleChange(e, section)}
            id="noLabourRegulations"
            value={2}
            containerClassName="companyLanguage d-inline-flex"
          />
        </div>
        <div className="col-6 mb-3">
          <div className="mb-2">
            <LabelField title={t("Who will sign the Werkpostfiche")} />
          </div>
          <RadioField
            name="FirstStep.who_will_sign_the_wpf"
            label={t("Employer")}
            className="radioBtn"
            ischecked={Number(agreementData?.FirstStep?.who_will_sign_the_wpf) === 0}
            handleChange={(e) => handleChange(e, section)}
            id="yesLabourRegulations"
            value={0}
            containerClassName="companyLanguage me-4 d-inline-flex"
          />

          <RadioField
            name="FirstStep.who_will_sign_the_wpf"
            label={t("Co-employer")}
            className="radioBtn"
            ischecked={Number(agreementData?.FirstStep?.who_will_sign_the_wpf) === 1}
            handleChange={(e) => handleChange(e, section)}
            id="noLabourRegulations"
            value={1}
            containerClassName="companyLanguage d-inline-flex"
          />
        </div>
        <div className="col-6 multiSelectWrapper">
          <ParitiarCommitteeSection
            optionsList={[]}
            selectedValue={null}
            selectedEmpType={null}
            handlePCFields={() => { }}
            state={undefined}
            addParitiarComitee={() => { }}
            pcChangeHandler={() => { }}
            pcDeleteHandler={() => { }}
            employeeTypes={[]}
            isBlueCollar={true}
            isWhiteCollar={false}
          />
        </div>
        <div className="col-6 multiSelectWrapper">
          <ParitiarCommitteeSection
            optionsList={[]}
            selectedValue={null}
            selectedEmpType={null}
            handlePCFields={() => { }}
            state={undefined}
            addParitiarComitee={() => { }}
            pcChangeHandler={() => { }}
            pcDeleteHandler={() => { }}
            employeeTypes={[]}
            isBlueCollar={false}
            isWhiteCollar={true}
          />
        </div>
        <div className="col-12 absoluteAgentTable mt-4">
          <div className="row">
            <div className="col-4 pe-0">
              <LeftPart />
            </div>
            <div className="col-8 ps-0">
              <RightPart />
            </div>
          </div>
        </div>
      </div>
      <Navigation
        isLastTab={isLastTab}
        onPrevious={handlePrevious}
        onNext={handlenext}
      />
    </>
  );
};

export default AbsoluteyouAgent;
