import React, { useEffect, useState } from "react";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import Header from "../common/layout/Header";
import TableHeaderWithIcon from "../common/molecules/TableHeaderWithIcon";
import EmployeeDetailsCard from "../common/molecules/EmployeeDetailsCard";
import ShiftTiming from "../Planning/ShiftTiming";
import "./css/timeRegistration.css";
import userImage from "../../static/images/User.svg";
import OverFlowWrapper from "../common/OverFlowWrapper";
import DateAndTime from "../common/atoms/DateWithTime";
import { useLocation } from "react-router-dom";
import Icon from "../../Icon";
import PlanningEmployeeDetailsCard from "../common/molecules/PlanningEmployeeDetailsCard";
import { useSelector } from "react-redux";
import { selectCurrentLocations } from "../../features/auth/AuthSlice";
import { ApiCall } from "../../services/ApiServices";
import { ENCODAGE_CREATE, ENCODAGE_OVERVIEW } from "../../routes/ApiEndpoints";
import Button from "../common/atoms/Button";
import NoRecords from "../common/atoms/NoRecords";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";
import { calculateEncodageTotalTime, calculateShiftsTotalTime } from "../Planning/Services/PlanningTimingHelper";
import CustomActionIcon from "../common/molecules/Actions/CustomActionIcon";
import LabelField from "../common/atoms/LabelField";
import InputTextfield from "../CentralDataMangement/configurations/manageCoefficientDeviations/InputTexfield";
import CustomNotify from "../common/atoms/CustomNotify";
const TimeRegistrationOverview = () => {
  const widthStylesColOne = useResponsiveValue("100%", "50%", "9%");
  const widthStylesColTwo = useResponsiveValue("100%", "100%", "89%");
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(() => {
    // Initialize activeTab based on location state if available
    return location.state?.activeTab || "home";
  });
  const [openEmployees, setOpenEmployees] = useState<Employee[]>([]);
  const [closedEmployees, setClosedEmployees] = useState<Employee[]>([]);

  const [selectedShift, setSelectedShift] = useState<{
    [key: string]: string[]
  }>({});

  const [employeeErrors, setEmployeeErrors] = useState<{
    [key: string]: string | null;
  }>({});

  const [searchVisible, setSearchVisible] = useState<boolean>(false); // State for toggling search input visibility
  const [searchQuery, setSearchQuery] = useState<string>(""); // State for search query



  interface EncodageItem {
    enId: number;
    start_time: string;
    end_time: string;
    total_count: string;
    total_break: string;
    sequence: string;
    encoded_by: number;
  }

  interface ScheduleItem {
    planned: Array<{
      shift_id: number;
      start_time: string;
      end_time: string;
      total_count: string;
      total_break: string;
      sequence: number;
    }>;
    timeRegistration: Array<{
      time_registraion_id: number;
      start_time: string;
      end_time: string;
      total_count: string;
      total_break: string;
      sequence: string;
    }>;
    encodage: EncodageItem[];
  }

  interface Employee {
    employee_id: number;
    employee_name: string;
    employee_type_id: number;
    employee_type: string;
    pc: string;
    pc_id: number;
    location_id: number;
    location_name: string;
    function_id: number;
    function: string;
    salary: string;
    planning_id: number;
    planning_employee_detail_id: number;
    schedule: Record<string, ScheduleItem>;
    start_date: string;
    end_date: string;
  }


  const iconStyle = useResponsiveValue("3vw", "2.5vw", "0.8vw");
  const IconProps = {
    width: iconStyle,
    height: iconStyle,
    isIcon: true,
  };

  const companyId = useSelector((state: any) => state.auth?.currentCompany?.id);
  const locations = useSelector(selectCurrentLocations) ?? [];

  // console.log(locations);

  // for locations selection
  const initialCurrentIndex = 0;
  const [currentIndex, setCurrentIndex] = useState(initialCurrentIndex);

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => {
      return prevIndex < locations.length - 1 ? prevIndex + 1 : prevIndex;
    });
  };
  const handlePreviousClick = () => {
    setCurrentIndex((prevIndex) => {
      return prevIndex > 0 ? prevIndex - 1 : prevIndex;
    });
  };

  // for week selection
  const getStartAndEndDate = (date: Date) => {
    const dayOfWeek = date.getDay();
    const startOfWeek = new Date(
      date.getTime() - dayOfWeek * 24 * 60 * 60 * 1000
    );
    const endOfWeek = new Date(startOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000);

    return { startOfWeek, endOfWeek };
  };

  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const handleDateChange = (newDate: Date) => {
    setDate(newDate);
    const { startOfWeek, endOfWeek } = getStartAndEndDate(newDate);
    setStartDate(startOfWeek.toLocaleDateString("en-CA")); // YYYY-MM-DD
    setEndDate(endOfWeek.toLocaleDateString("en-CA")); // YYYY-MM-DD
  };

  const handleEncodageChange = (
    employeeId: number,
    date: string,
    updatedEncodage: EncodageItem[],
    parentIndex: number
  ) => {
    setOpenEmployees((prevEmployees) => {
      const updatedEmployees = [...prevEmployees]; // Create a new array
      if (updatedEmployees[parentIndex]) {  // Check if index exists
        updatedEmployees[parentIndex] = {
          ...updatedEmployees[parentIndex],
          schedule: {
            ...updatedEmployees[parentIndex].schedule,
            [date]: {
              ...updatedEmployees[parentIndex].schedule[date],
              encodage: updatedEncodage,
            },
          },
        };
      }
      return updatedEmployees;
    });
  };

  const toggleShiftSelection = (employeeId: number, shiftDate: string, parentIndex: number): void => {
    const compositeKey = `${employeeId}_${parentIndex}`;

    setSelectedShift((prev) => {
      const employeeShifts = prev[compositeKey] || [];
      const isSelected = employeeShifts.includes(shiftDate);

      // Clear error when a checkbox is selected
      if (!isSelected) {
        setEmployeeErrors((prevErrors) => ({
          ...prevErrors,
          [compositeKey]: null // Clear error when selecting a checkbox
        }));
      }

      return {
        ...prev,
        [compositeKey]: isSelected
          ? employeeShifts.filter((date) => date !== shiftDate)
          : [...employeeShifts, shiftDate],
      };
    });
  };



  const fetchEmployeeData = async (validated: number) => {
    try {
      let dataObj = {
        company_id: companyId,
        location_id: locations[currentIndex]?.id,
        start_date: startDate,
        end_date: endDate,
        validated: validated,
      };

      if (
        dataObj["start_date"] !== "" &&
        dataObj["end_date"] !== "" &&
        dataObj["company_id"] != null &&
        dataObj["location_id"] != null
      ) {
        let result = await ApiCall.service(
          ENCODAGE_OVERVIEW,
          "POST",
          dataObj,
          true
        );
        console.log(result);

        if (result.status === "success") {
          const employeesData: Employee[] = result.data; // Explicitly typing employeesData
          const formattedEmployees = employeesData.map((employee) => ({
            employee_id: employee.employee_id,
            employee_name: employee.employee_name,
            employee_type_id: employee.employee_type_id,
            employee_type: employee.employee_type,
            pc: employee.pc,
            pc_id: employee.pc_id,
            location_id: employee.location_id,
            location_name: employee.location_name,
            function_id: employee.function_id,
            function: employee.function,
            salary: employee.salary,
            image: userImage,
            planning_id: employee.planning_id,
            planning_employee_detail_id: employee.planning_employee_detail_id,
            schedule: employee.schedule,
            start_date: employee.start_date,
            end_date: employee.end_date,
          }));

          validated === 0
            ? setOpenEmployees(formattedEmployees)
            : setClosedEmployees(formattedEmployees);

          console.log(openEmployees, "res");

        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch open data
  const fetchOpenData = async () => {
    await fetchEmployeeData(0);
  };

  // Fetch closed data
  const fetchClosedData = async () => {
    await fetchEmployeeData(1);
  };

  const postData = async (data: Employee) => {
    // Implement your API call here
    console.log("Posting data:", data);

    try {
      let result = await ApiCall.service(
        ENCODAGE_CREATE,
        "POST",
        { data: [data] },
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      console.log(result, "res for post");
      if (result.status === 'success') {
        fetchOpenData();
        fetchClosedData();
        // window.location.reload()
      }

      if (result.status === 'validation-error') {
        const errorMessages = Object.values(result.data).join(', ') || t("Something went wrong");
        CustomNotify({ type: "error", message: t(errorMessages) });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSend = (employee: Employee, parentIndex: number) => {
    const compositeKey = `${employee.employee_id}_${parentIndex}`;
    const employeeSelectedShifts = selectedShift[compositeKey] || [];

    if (employeeSelectedShifts.length === 0) {
      setEmployeeErrors((prevErrors) => ({
        ...prevErrors,
        [compositeKey]: t("Please select at least one shift."),
      }));
      return;
    }

    // Clear error for the employee if they have selected shifts
    setEmployeeErrors((prevErrors) => ({
      ...prevErrors,
      [compositeKey]: null,
    }));

    const filteredSchedule: Record<string, ScheduleItem> = Object.keys(
      employee.schedule
    )
      .filter((date) => employeeSelectedShifts.includes(date))
      .reduce((acc, date) => {
        acc[date] = employee.schedule[date];
        return acc;
      }, {} as Record<string, ScheduleItem>);

    if (Object.keys(filteredSchedule).length > 0) {
      const filteredData: Employee = {
        ...employee,
        schedule: filteredSchedule,
      };

      postData(filteredData);

      // Reset selected shifts for the specific employee
      setSelectedShift((prevSelectedShift) => ({
        ...prevSelectedShift,
        [compositeKey]: [], // Clear selected shifts for this employee
      }));
    }
  };

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
      // Clear the state after using it to prevent it from persisting
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  useEffect(() => {
    const { startOfWeek, endOfWeek } = getStartAndEndDate(date);
    setStartDate(startOfWeek.toLocaleDateString("en-CA")); // YYYY-MM-DD
    setEndDate(endOfWeek.toLocaleDateString("en-CA")); // YYYY-MM-DD
  }, [date]);
  // console.log(date, startDate, endDate, "dates");

  useEffect(() => {
    fetchOpenData();
    fetchClosedData();
  }, [companyId, currentIndex, startDate, endDate,]);

  let totalValidatedHours = 0;
  const getTotalValidatedHours = (closedEmployees: Employee[]): string => {

    if (closedEmployees && closedEmployees.length > 0) {
      closedEmployees.forEach((employee) => {
        // Check if the employee has a schedule
        if (employee.schedule) {
          // Iterate through all dates in the employee's schedule
          Object.keys(employee.schedule).forEach((date) => {
            const scheduleItem = employee.schedule[date];

            // Check if the current date has an encodage array
            if (scheduleItem?.encodage && scheduleItem.encodage.length > 0) {
              // Use type 2 to get decimal hours, which can be added
              totalValidatedHours += calculateEncodageTotalTime(scheduleItem.encodage, 2);
            }
          });
        }
      });
    }

    // Convert total hours to HH:MM format
    const hours = Math.floor(totalValidatedHours);
    const minutes = Math.round((totalValidatedHours - hours) * 60);

    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  };

  const getTotalValidatedHoursPerEmployee = (employee: Employee): string => {
    let totalHours = 0;

    if (employee.schedule) {
      Object.keys(employee.schedule).forEach((date) => {
        const scheduleItem = employee.schedule[date];

        if (scheduleItem?.encodage && scheduleItem.encodage.length > 0) {
          totalHours += calculateEncodageTotalTime(scheduleItem.encodage, 2);
        }
      });


    }
    const hours = Math.floor(totalHours);
    const minutes = Math.round((totalHours - hours) * 60);

    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;

  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(event.target.value); // Update search query
  };

  // Toggle the visibility of the search input
  const toggleSearchVisibility = (): void => {
    setSearchVisible((prev) => {
      if (prev == true) {
        setSearchQuery("");
      }
      return !prev;
    });
  };

  // Filter open employees by search query
  const filteredOpenEmployees = openEmployees.filter((employee) =>
    employee.employee_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Filter closed employees by search query
  const filteredClosedEmployees = closedEmployees.filter((employee) =>
    employee.employee_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const formatEmployeeName = (name: string) => {
    const [firstName, lastName = ''] = name.split(' ');
    return `${firstName} ${lastName.charAt(0).toUpperCase()}.`;
  };

  return (
    <>
      <Header
        headerName={t("Time registration per employee")}
        headerClassName="myAccountCardTitle"
      />
      <TableHeaderWithIcon
        onNextClick={handleNextClick}
        onPreviousClick={handlePreviousClick}
        title={locations[currentIndex]?.name || t("No Location Name")}
        TableHeaderWithIconClassName="plannedLocationHeader px-3"
        TableHeaderTitle="poppins-semibold text-white"
      />
      <div className="contentBg rounded-top-0 p-3">
        <div className="row mb-3">
          <div className="col-lg-4 col-md-4 align-self-center">
            <ul className="nav nav-pills timeRegistrationTabs" role="tablist">
              <li className="nav-item">
                <a
                  className={`nav-link ${activeTab === "home" ? "active" : ""}`}
                  data-bs-toggle="pill"
                  href="#home"
                  onClick={() => setActiveTab("home")}
                >
                  {t("Open")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${activeTab === "menu1" ? "active" : ""
                    }`}
                  data-bs-toggle="pill"
                  href="#menu1"
                  onClick={() => setActiveTab("menu1")}
                >
                  {t("Closed")}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-8 align-self-center text-center d-flex justify-content-end justify-content-lg-center">
            <DateAndTime
              isWeek={true}
              isIcon={true}
              monthFormat="long"
              dataTimeWrapperClassName="d-inline-flex align-items-center justify-content-center timeRegistrationDateWrapper"
              dataTimeClassName="mx-3 planningPlannedDate"
              dayClassName="me-1"
              isWeekDate={true}
              iconFillColor="#8571b9"
              onDateChange={handleDateChange}
            />
          </div>
          <div className="col-lg-4 col-12 align-self-center text-end">
            {/* <span
              onClick={() => { }}
              title={t("Print")}
              className="secondaryColorHoverEffect float-end"
            >
              <Icon isIcon={true} width="1vw" height="1vw" name="printIcon" />
            </span> */}
            <div className="d-flex align-items-center justify-content-end">
              {activeTab === "menu1" && (
                <div className="me-lg-3 totalValidatedHours">
                  {t("Total validated hours") + ": " + getTotalValidatedHours(closedEmployees)}
                </div>
              )}
              {searchVisible && (
                <InputTextfield
                  placeholder={t("Search by employee name")}
                  value={searchQuery}
                  handleChange={handleSearchChange}
                />
              )}
              <span
                onClick={toggleSearchVisibility}
                title={t("Search")}
                className="secondaryColorHoverEffect ms-3 float-end"
              >
                <Icon isIcon={true} width="1vw" height="1vw" name="searchIcon" />
              </span>
            </div>
          </div>
        </div>
        {/* Tab panes */}
        <div className="tab-content">
          <div
            id="home"
            className={`tab-pane ${activeTab === "home" ? "active" : ""}`}
          >
            {openEmployees.length > 0 ? <div style={{ paddingLeft: "0.2vw" }}>
              <div style={{ width: widthStylesColOne }}></div>
              <div style={{ width: widthStylesColTwo }} className="ms-auto" >
                <table className="table table-borderless timeRegistrationTableHeader mb-0">
                  <thead>
                    <tr>
                      <th></th>
                      {[
                        "Planned hours",
                        "Clocked-in hours",
                        "Effective hours worked",
                      ].map((category) => (
                        <th
                          key={category}
                          colSpan={3}
                          className="text-center categorySection"
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            {category !== "Effective hours worked" && (
                              <Icon name="lockIcon" {...IconProps} fillColor="#87abb8" />
                            )}
                            <span className="ms-2">{t(category)}</span>
                          </div>
                        </th>
                      ))
                      }
                      <th></th>
                    </tr>
                  </thead>
                </table>
                <div className="align-self-center ms-auto">
                </div>
              </div>
            </div> : ""}
            <OverFlowWrapper className="timeRegistrationPerEmployee container-fluid px-0">
              {filteredOpenEmployees && filteredOpenEmployees.length > 0 ? (
                filteredOpenEmployees.map((employee, employeeIndex) => (
                  <div className="d-lg-flex mb-3" key={employee.employee_id} style={{ paddingLeft: "0.2vw" }}>
                    <div className="align-self-center mx-auto mb-3 mb-lg-0" style={{ width: widthStylesColOne }}>
                      <PlanningEmployeeDetailsCard
                        cardClassName="employeeDetailsCardPlanning colorPrimary text-break text-start d-flex align-items-center justify-content-center flex-column text-center"
                        cardMainClassName="timeRegistrationWrapper position-relative"
                        employeeInformationClass="planningEmployeeDetails timeRegistrationEmployeeCard w-100"
                        employeeName={formatEmployeeName(employee.employee_name)}
                        employeeEmpType={employee.employee_type}
                        employeeLocation={employee.location_name}
                        employeeFunction={employee.function}
                        employeePC={employee.pc}
                        employeeSalary={employee.salary}
                      />
                    </div>
                    <div
                      className="align-self-center ms-auto"
                      style={{ width: widthStylesColTwo }}
                    >

                      {Object.entries(employee.schedule).map(
                        ([date, scheduleData], index) => {

                          return (
                            <ShiftTiming
                              parentIndex={employeeIndex}
                              key={index}
                              showLastAction={true}
                              isTimeRegistration={true}
                              category="open"
                              schedule={scheduleData}
                              date={date}
                              shiftIndex={index}
                              selectedDates={selectedShift[employee.employee_id] || []} // Pass the specific employee's selected shifts
                              onEncodageChange={(
                                updatedEncodage: EncodageItem[]
                              ) =>
                                handleEncodageChange(
                                  employee.employee_id,
                                  date,
                                  updatedEncodage,
                                  employeeIndex
                                )
                              }
                              onCheckboxChange={() => toggleShiftSelection(employee.employee_id, date, employeeIndex)}
                              activeEmployee={employee} // Pass the current employee as activeEmployee
                            />
                          );
                        }
                      )}

                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-12 text-end timeRegistrationValidation">
                            <LabelField title={`${selectedShift[`${employee.employee_id}_${employeeIndex}`]?.length || 0} / ${Object.keys(employee.schedule).length} ` + t("validated")} className="me-2" />
                            <CustomActionIcon handleClick={() => handleSend(employee, employeeIndex)} title={t("Send")} iconName="sendIcon" className="timeRegistrationValidationSend secondaryColorHoverEffect" />
                          </div>
                        </div>
                      </div>
                      {employeeErrors[`${employee.employee_id}_${employeeIndex}`] && (
                        <div className="error-message">
                          {employeeErrors[`${employee.employee_id}_${employeeIndex}`]}
                        </div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                < NoRecords message={t("No open encodage for this week")} isDiv={true} className="pt-5" />
              )}
            </OverFlowWrapper>
          </div>
          <div
            id="menu1"
            className={`tab-pane ${activeTab === "menu1" ? "active" : ""}`}
          >
            <div style={{ paddingLeft: "0.2vw" }}>
              <div style={{ width: widthStylesColOne }}></div>
              <div style={{ width: widthStylesColTwo }} className="ms-auto" >
                <table className="table table-borderless timeRegistrationTableHeader mb-0">
                  <thead>
                    <tr>
                      <th></th>
                      {[
                        "Planned hours",
                        "Clocked-in hours",
                        "Effective hours worked",
                      ].map((category) => (
                        <th
                          key={category}
                          colSpan={3}
                          className="text-center categorySection"
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            {category !== "Effective hours worked" && (
                              <Icon name="lockIcon" {...IconProps} fillColor="#87abb8" />
                            )}
                            <span className="ms-2">{t(category)}</span>
                          </div>
                        </th>
                      ))
                      }
                      <th></th>
                    </tr>
                  </thead>
                </table>
                <div className="align-self-center ms-auto">
                </div>
              </div>
            </div>
            <OverFlowWrapper className="timeRegistrationPerEmployee">
              {filteredClosedEmployees && filteredClosedEmployees.length > 0 ? (
                filteredClosedEmployees.map((employee) => (
                  <div className="d-lg-flex mb-3" key={employee.employee_id}>
                    <div className="align-self-center mx-auto mb-3 mb-lg-0" style={{ width: widthStylesColOne }}>
                      <PlanningEmployeeDetailsCard
                        cardClassName="employeeDetailsCardPlanning colorPrimary text-break text-start d-flex align-items-center justify-content-center flex-column text-center"
                        cardMainClassName="timeRegistrationWrapper position-relative"
                        employeeInformationClass="planningEmployeeDetails timeRegistrationEmployeeCard w-100"
                        employeeName={formatEmployeeName(employee.employee_name)}
                        employeeEmpType={employee.employee_type}
                        employeeLocation={employee.location_name}
                        employeeFunction={employee.function}
                        employeePC={employee.pc}
                        employeeSalary={employee.salary}
                      // employeeImage={employee.image}
                      />
                    </div>
                    <div
                      className="align-self-center ms-auto"
                      style={{ width: widthStylesColTwo }}
                    >
                      {Object.entries(employee.schedule).map(
                        ([date, scheduleData], index) => (
                          <>
                            <ShiftTiming
                              key={index}
                              showLastAction={true}
                              isTimeRegistration={true}
                              category="closed"
                              schedule={scheduleData}
                              date={date}
                              shiftIndex={index}
                            />

                          </>
                        )
                      )}

                      {activeTab === "menu1" && (
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 text-end">
                              <span className="color-primary totalText">{t("Total") + " : "}</span>
                              <span className="timeRegistrationClosed ms-2 text-white poppins-medium">{getTotalValidatedHoursPerEmployee(employee)}</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <NoRecords isDiv={true} message={t("No closed encodage for this week")} className="pt-5" />
              )}
            </OverFlowWrapper>
          </div>
        </div>
      </div>
    </>
  );
};

export default translate(TimeRegistrationOverview);
