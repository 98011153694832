import { t } from "../CentralDataMangement/translation/Translation";

export interface TransportOption {
  value: number;
  label: string;
}

export const TRANSPORT_TYPES: TransportOption[] = [
  {
    value: 0,
    label: t("None"),
  },
  {
    value: 1,
    label: t("Subscription"),
  },
];

export const TRANSPORT_ALLOWANCES: TransportOption[] = [
  {
    value: 0,
    label: t("Public"),
  },
  {
    value: 1,
    label: t("Private"),
  },
];

// Helper function to get transport type label
export const getTransportTypeLabel = (value: number): string => {
  return TRANSPORT_TYPES.find(type => type.value === value)?.label || "";
};

// Helper function to get transport allowance label
export const getTransportAllowanceLabel = (value: number): string => {
  return TRANSPORT_ALLOWANCES.find(allowance => allowance.value === value)?.label || "";
};