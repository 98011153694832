import React from "react";
import LabelField from "../../../../common/atoms/LabelField";
import { t } from "../../../../CentralDataMangement/translation/Translation";
import { LabelWithInputField } from "../../../../common/molecules/LabelWithInputField";
import { useResponsiveValue } from "../../../../common/atoms/ResponsivWidth";
import { MyAccount } from "../../MyAccount/MyAccountInterface";

interface SecurityOrganismProps {
  formState: MyAccount;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearFieldError: (fieldPath: string) => void;
  handleShowPasswordToggle: () => void;
  handleShowConfirmPasswordToggle: () => void;
  showPassword: boolean;
  showConfirmPassword: boolean;
}

const SecurityOrganism: React.FC<SecurityOrganismProps> = ({
  formState,
  handleChange,
  clearFieldError,
  handleShowPasswordToggle,
  handleShowConfirmPasswordToggle,
  showPassword,
  showConfirmPassword
}) => {
  const iconStyle = useResponsiveValue("5vw", "3vw", "1vw");

  const inputFieldProps = {
    labelWrapperClassName: "col-4 align-self-center pe-0",
    colClassName: "col-8",
    className: "inputFieldColor",
  };

  const cardTitleProps = {
    className: "myAccountCardTitle colorPrimary mb-3",
  };


  return (
    <div className="contentBg p-0">
      <LabelField title={t("Security")} {...cardTitleProps} />

      {/* Change Password */}
      <div className="row mb-3" onClick={() => clearFieldError("password")}>
        <LabelWithInputField
          label={t("Change password") + ":"}
          type={showPassword ? "text" : "password"}
          value={formState?.formValues?.security?.password}
          name="security.password"
          handleChange={handleChange}
          id="changePass"
          isIcon={true}
          width={iconStyle}
          height={iconStyle}
          error={formState?.errors?.password}
          inputClassWrapper="position-relative"
          iconName={showPassword ? "eyeSolid" : "eyeSlashSolid"}
          handleIcon={handleShowPasswordToggle}
          autoComplete="new-password"
          {...inputFieldProps}
        />
      </div>

      {/* Confirm Password */}
      <div className="row mb-5" onClick={() => clearFieldError("confirm_password")}>
        <LabelWithInputField
          label={t("Repeat password") + ":"}
          type={showConfirmPassword ? "text" : "password"}
          value={formState?.formValues?.security?.confirm_password}
          name="security.confirm_password"
          handleChange={handleChange}
          id="repeatPass"
          isIcon={true}
          width={iconStyle}
          height={iconStyle}
          error={formState?.errors?.confirm_password}
          inputClassWrapper="position-relative"
          iconName={showConfirmPassword ? "eyeSolid" : "eyeSlashSolid"}
          handleIcon={handleShowConfirmPasswordToggle}
          autoComplete="new-password"
          disableCopyPaste
          {...inputFieldProps}
        />
      </div>
    </div>
  );
};

export default SecurityOrganism;