import React, { useState, useContext, useEffect } from "react";
import { PcContext } from "./PcContext";
import {
  GET_PC_END_HOURS,
  STORE_PC_END_HOURS,
} from "../../../routes/ApiEndpoints";
import { ApiCall } from "../../../services/ApiServices";
import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import { TextField, FormHelperText } from "@mui/material";
import Title from "../../common/atoms/Title";
import OverFlowWrapper from "../../common/OverFlowWrapper";
import Button from "../../common/atoms/Button";
import LabelField from "../../common/atoms/LabelField";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import { LabelWithInputField } from "../../common/molecules/LabelWithInputField";

const timeToDecimal = (timeStr) => {
  const [hours, minutes] = timeStr.split(":").map(Number);
  return hours + minutes / 60;
};

const decimalToTime = (decimal) => {
  const hours = Math.floor(decimal);
  const minutes = Math.round((decimal - hours) * 60);
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
};

// Helper to convert European format to number
const parseEuropeanNumber = (value) => {
  if (value === "") return "";
  // Replace comma with dot for parsing
  const normalizedValue = value.replace(",", ".");
  return normalizedValue;
};

// Helper to format number to European format
const formatEuropeanNumber = (value) => {
  if (value === "") return "";
  // Replace dot with comma for display
  return String(value).replace(".", ",");
};

const AgeTimeLimit = () => {
  const { pc_unique_key, pc_view_type } = useContext(PcContext);
  const [data, setData] = useState([]);
  const [disableSave, setDisableSave] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (pc_unique_key !== "") {
      ApiCall.getService(
        `${GET_PC_END_HOURS}/${pc_unique_key}`,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      )
        .then((result) => {
          const enhancedData = result.data.map(item => ({
            ...item,
            min_hours: item.min_hours ? formatEuropeanNumber(item.min_hours) : "",
            max_hours: item.max_hours ? formatEuropeanNumber(item.max_hours) : ""
          }));
          setData(enhancedData || []);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [pc_unique_key]);

  const validateHours = (value, type, index) => {
    if (value === "") return ""; // Allow empty values

    // Check if the input matches the expected format (numbers and one comma)
    const validFormat = /^\d*,?\d*$/.test(value);
    if (!validFormat) {
      return t("Please enter a valid number (use comma as decimal separator)");
    }

    const numValue = parseFloat(parseEuropeanNumber(value));
    if (isNaN(numValue)) {
      return t("Please enter a valid number");
    }
    if (numValue < 0) {
      return t("Hours cannot be negative");
    }
    if (numValue > 24) {
      return t("Hours cannot exceed 24");
    }

    // Compare min and max if both are present
    const item = data[index];
    if (type === "min" && item.max_hours !== "") {
      const maxValue = parseFloat(parseEuropeanNumber(item.max_hours));
      if (numValue > maxValue) {
        return t("Minimum hours cannot be greater than maximum hours");
      }
    }
    if (type === "max" && item.min_hours !== "") {
      const minValue = parseFloat(parseEuropeanNumber(item.min_hours));
      if (numValue < minValue) {
        return t("Maximum hours cannot be less than minimum hours");
      }
    }

    return "";
  };

  const handleTimeChange = (e, index) => {
    const timeStr = e.target.value;
    const newData = [...data];
    newData[index].end_time = timeToDecimal(timeStr);
    setData(newData);
  };

  const handleHoursChange = (e, index, type) => {
    let value = e.target.value;

    // Only allow numbers and one comma
    if (!/^[0-9]*,?[0-9]*$/.test(value) && value !== "") {
      return;
    }

    const newData = [...data];
    newData[index][`${type}_hours`] = value;
    setData(newData);

    const newErrors = { ...errors };
    const validationError = validateHours(value, type, index);
    if (validationError) {
      newErrors[`${index}-${type}`] = validationError;
    } else {
      delete newErrors[`${index}-${type}`];
    }
    setErrors(newErrors);
    setDisableSave(Object.keys(newErrors).length > 0);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    const submissionData = data.map(item => ({
      ...item,
      min_hours: item.min_hours ? parseFloat(parseEuropeanNumber(item.min_hours)) : "",
      max_hours: item.max_hours ? parseFloat(parseEuropeanNumber(item.max_hours)) : ""
    }));

    data.forEach((item, index) => {
      if (item.min_hours !== "") {
        const minError = validateHours(item.min_hours, "min", index);
        if (minError) newErrors[`${index}-min`] = minError;
      }
      if (item.max_hours !== "") {
        const maxError = validateHours(item.max_hours, "max", index);
        if (maxError) newErrors[`${index}-max`] = maxError;
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      ApiCall.service(
        `${STORE_PC_END_HOURS}/${pc_unique_key}`,
        "POST",
        { endTime: submissionData },
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      )
        .then((result) => {
          if (result.status === 201) {
            window.location.reload();
          }
          setDisableSave(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setDisableSave(true);
    }
  };

  return (
    <div className="border p-3 rounded-3 mt-1">
      <OverFlowWrapper className="ageWrapper">
        <Title
          title={t("Edit end time")}
          className="colorPrimary poppins-medium mb-3 pcTitle"
        />
        <form onSubmit={handleSubmit}>
          <div>
            {data.map((item, index) => (
              <div key={item.id}>
                <div className="mb-4">
                  <LabelField title={`End time for ${item.age} years`} />
                  <TextField
                    type="time"
                    onChange={(e) => handleTimeChange(e, index)}
                    value={decimalToTime(item.end_time) || "00:00"}
                    fullWidth
                    className="endTimeTimeWrapper"
                    focused
                  />
                </div>

                <div className="mb-4">
                  <LabelWithInputField
                    label={t("Minimum hours for") + " " + item.age + " " + t("years")}
                    value={item.min_hours}
                    name={`min_hours_${index}`}
                    id={`min_hours_${index}`}
                    handleChange={(e) => handleHoursChange(e, index, "min")}
                    className="ps-2"
                    isTop={true}
                    type="text"

                  />
                  {errors[`${index}-min`] && (
                    <FormHelperText error>{errors[`${index}-min`]}</FormHelperText>
                  )}
                </div>

                <div className="mb-4">
                  <LabelWithInputField
                    label={t("Maximum hours for") + " " + item.age + " " + t("years")}
                    value={item.max_hours}
                    name={`max_hours_${index}`}
                    id={`max_hours_${index}`}
                    handleChange={(e) => handleHoursChange(e, index, "max")}
                    className="ps-2"
                    isTop={true}
                    type="text"

                  />
                  {errors[`${index}-max`] && (
                    <FormHelperText error>{errors[`${index}-max`]}</FormHelperText>
                  )}
                </div>
              </div>
            ))}

            <div className="text-end">
              <Button
                type="submit"
                disabled={disableSave}
                className="searchBtn shadow-none"
              >
                {t("SAVE")}
              </Button>
            </div>
          </div>
        </form>
      </OverFlowWrapper>
    </div>
  );
};

export default translate(AgeTimeLimit);