import React from "react";
import ProfilePageTemplate from "../../components/IdentityManager/authentication/Login/ProfilePageTemplate";
import AccessControl from "../../services/RolesAndPermissions/AccessControl";

const ProfilePage: React.FC = () => {
    return (
        <AccessControl
            requiredPermissions={[
                {
                    permission: "my_account",
                    read: true,
                    update: true,
                },
            ]}
            renderNoAccess={true}
        >
            <ProfilePageTemplate />
        </AccessControl>
    );
};

export default ProfilePage;