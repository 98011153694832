import React, { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { WELCOME_DASHBOARD } from "./RouteConstants";
import EncryptDecryptService from "../services/EncryptDecryptService";

interface PublicRouteProps {
  element: ReactElement;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ element }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = () => {
      try {
        const user = JSON.parse(
          JSON.parse(localStorage.getItem("persist:root") ?? "{}")?.auth ?? "{}"
        );
        const decryptedData = JSON.parse(
          EncryptDecryptService.decryptData(user)
        );
        const authToken = decryptedData?.token;
        const role = decryptedData?.role;

        if (authToken && role) {
          // Use a single, definitive navigation based on role
          if (role?.includes("sales_agent") || role?.includes("SALES_AGENT")) {
            navigate('/dashboard/follow-up');
          }else if (role?.includes("employer") || role?.includes("EMPLOYER")) {
            navigate("/location-dashboard", { replace: true });
          } else if (role?.includes("employee") || role?.includes("EMPLOYEE")) {
            navigate("/", { replace: true });
          }
        }
      } catch (error) {
        console.error("Authentication check failed:", error);
      }
    };

    checkAuth();
  }, [navigate]);

  const user = JSON.parse(
    JSON.parse(localStorage.getItem("persist:root") ?? "{}")?.auth ?? "{}"
  );
  const authToken = JSON.parse(EncryptDecryptService.decryptData(user))?.token;

  return authToken ? null : element;
};

export default PublicRoute;
