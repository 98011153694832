import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../Icon";
import Button from "../common/atoms/Button";
import Link from "../common/atoms/Link";
import Header from "../common/layout/Header";
import AutoCompleteWithSearch from "../common/molecules/AutoCompleteWithSearch";
import EmployeeDetailsCard from "../common/molecules/EmployeeDetailsCard";
import * as ROUTE from "../../routes/RouteConstants";
import { selectPlanningShow } from "../../features/planning/planningSlice";
import PlanningTabs from "../Planning/PlanningTabs";
import { useDispatch, useSelector } from "react-redux";
import { t } from "../CentralDataMangement/translation/Translation";
import { ApiCall } from "../../services/ApiServices";
import OverFlowWrapper from "../common/OverFlowWrapper";
import { COMPANY_EMPLOYEES } from "../../routes/ApiEndpoints";
import NoRecords from "../common/atoms/NoRecords";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import LabelField from "../common/atoms/LabelField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";

const EmployeeOverview = () => {
  const houseIconSize = useResponsiveValue("5vw", "3vw", "1.3vw");
  const filterIconSize = useResponsiveValue("5vw", "3vw", "1vw");
  const navigate = useNavigate();
  const show = useSelector(selectPlanningShow);
  const PER_PAGE = 20;
  const observerTarget = useRef(null);

  const companyId = useSelector(
    (state: any) => state?.auth?.currentCompany?.id
  );

  // Single state object to manage all necessary states
  const [state, setState] = useState({
    displayedData: [] as DataItem[],
    allData: [] as DataItem[],
    searchTerm: "",
    filterStatus: "all",
    isLoading: false,
    hasMore: true,
    visibleCount: PER_PAGE,
  });

  interface DataItem {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    is_fav: boolean;
    status: string | boolean;
    is_invite_accepted: boolean;
    profile_picture_url: string | null;
    employee_type: string;
    employee_type_id: number;
    employee_type_category: string;
    employee_type_category_id: number;
    paritaire_commitee: string;
    paritaire_commitee_id: number;
    functional_salary: string;
    function_id: number;
    function_name: string;
  }

  // Fetch initial data (first 20 records)
  const fetchInitialData = async () => {
    const baseUrl = `${COMPANY_EMPLOYEES}`;
    const searchParams = new URLSearchParams({
      company_id: companyId,
      role: 'employee',
      page: '1',
      per_page: PER_PAGE.toString(),
    });
    const url = `${baseUrl}?${searchParams?.toString()}`;

    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const res = await ApiCall?.getService(url, "GET", CENTRAL_DATA_MANAGEMENT_MICROSERVICE, true);
      if (res?.status === "success") {
        setState((prevState) => ({ ...prevState, displayedData: res?.data || [] }));
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  // Fetch all employee data in background
  const fetchAllData = async () => {
    const baseUrl = `${COMPANY_EMPLOYEES}`;
    const searchParams = new URLSearchParams({
      company_id: companyId,
      role: 'employee',
    });
    const url = `${baseUrl}?${searchParams?.toString()}`;

    try {
      const res = await ApiCall?.getService(url, "GET", CENTRAL_DATA_MANAGEMENT_MICROSERVICE, false);
      if (res?.status === "success") {
        setState((prevState) => ({ ...prevState, allData: res?.data || [] }));
      }
    } catch (error) {
      console.error("Error fetching all data:", error);
    }
  };

  useEffect(() => {
    if (companyId) {
      fetchInitialData();
      fetchAllData();
    }
  }, [companyId]);

  // Filter and search functionality
  const getFilteredData = useCallback(() => {
    return state?.allData?.filter((employee) => {
      const searchLower = state?.searchTerm?.toLowerCase();
      const firstName = employee?.first_name?.toLowerCase() || "";
      const lastName = employee?.last_name?.toLowerCase() || "";
      const email = employee?.email?.toLowerCase() || "";
      const fullName = `${firstName} ${lastName}`;

      const matchesSearch =
        !state?.searchTerm ||
        firstName?.includes(searchLower) ||
        lastName?.includes(searchLower) ||
        email?.includes(searchLower) ||
        fullName?.includes(searchLower);

      const matchesStatus =
        state?.filterStatus === "all" ||
        (state?.filterStatus === "inactive" && employee?.status === "inactive") ||
        (state?.filterStatus === "active" && employee?.status === "active") ||
        (state?.filterStatus === "pending" && employee?.status === "pending") ||
        (state?.filterStatus === "favorite" && employee?.is_fav === true);

      return matchesSearch && matchesStatus;
    });
  }, [state?.allData, state?.searchTerm, state?.filterStatus]);

  // Update displayed data when filters change
  useEffect(() => {
    const filteredData = getFilteredData();
    setState((prevState) => ({
      ...prevState,
      displayedData: filteredData?.slice(0, state?.visibleCount),
      hasMore: state?.visibleCount < filteredData?.length,
    }));
  }, [state?.searchTerm, state?.filterStatus, state?.allData, state?.visibleCount]);

  // Intersection Observer for lazy loading
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0]?.isIntersecting && state?.hasMore && !state?.isLoading) {
          setState((prevState) => ({
            ...prevState,
            visibleCount: prevState?.visibleCount + PER_PAGE,
          }));
        }
      },
      { threshold: 0.1 }
    );

    if (observerTarget?.current) {
      observer?.observe(observerTarget?.current);
    }

    return () => observer?.disconnect();
  }, [state?.hasMore, state?.isLoading]);

  const handleEmployeeClick = (employee: DataItem) => {
    navigate(`${ROUTE?.EMPLOYEE_EDIT?.replace(":id", employee?.id?.toString())}`);
  };

  const toggleFavorite = async (employeeId: number, is_fav: boolean) => {
    try {
      const dataObj = {
        employee_id: employeeId,
        company_id: companyId,
        is_favourite: !is_fav,
      };
      const res = await ApiCall?.service(
        "employee-favourite",
        "POST",
        dataObj,
        true
      );
      if (res?.status === "success") {
        setState((prevState) => ({
          ...prevState,
          displayedData: prevState?.displayedData?.map((employee) =>
            employee?.id === employeeId
              ? { ...employee, is_fav: !is_fav }
              : employee
          ),
          allData: prevState?.allData?.map((employee) =>
            employee?.id === employeeId
              ? { ...employee, is_fav: !is_fav }
              : employee
          ),
        }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSearchChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      searchTerm: value,
      visibleCount: PER_PAGE,
    }));
  };

  const handleStatusChange = (selectedOption: { label: string; value: string }) => {
    setState((prevState) => ({
      ...prevState,
      filterStatus: selectedOption?.value,
      visibleCount: PER_PAGE,
    }));
  };

  const statusOptions = [
    { label: "All", value: "all" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
    { label: "Invitation Pending", value: "pending" },
    { label: "Favorite", value: "favorite" },
  ];

  const getStatusStyle = (status: string) => {
    switch (status?.toLowerCase()) {
      case "active":
        return { color: "green", label: "Active" };
      case "inactive":
        return { color: "red", label: "Inactive" };
      case "pending":
        return { color: "orange", label: "Invitation Pending" };
      default:
        return { color: "gray", label: "Unknown" };
    }
  };

  const filteredTotal = getFilteredData()?.length;

  return (
    <>
      <Header
        headerName={t("Your pool")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-md-2">
        <div className="row">
          <div className="col-8 align-self-center text-center d-block d-lg-none">
            <LabelField
              title={t("Your pool")}
              className="myAccountCardTitle mb-0"
            />
          </div>
          <div className="mb-3 col-lg-12 text-end">
            <Button
              title={t("Add employee")}
              className="saveBtn mobileFont me-3"
              handleClick={() => navigate(ROUTE?.EMPLOYEE_INVITE)}
            />
          </div>
        </div>
      </div>
      <div className="createpagesBg">
        <div className="row mb-3 justify-content-between">
          <div className="col-lg-3 d-flex mb-3 mb-lg-0">
            <div className="d-flex align-items-center">
              <Link className="me-3" href="">
                <span title={t("Home")}>
                  <Icon
                    isIcon={true}
                    className="secondaryColorHoverEffect"
                    name="houseIcon"
                    width={houseIconSize}
                    height={houseIconSize}
                  />
                </span>
              </Link>
              <AutoCompleteWithSearch
                placeholder={t("Search employee")}
                value={state?.searchTerm}
                handleChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="col-lg-5 text-end col-md-6">
            <div className="d-flex align-items-center justify-content-end">
              <div className="multiSelectWrapper w-75">
                <SelectWithSearch
                  options={statusOptions}
                  value={statusOptions?.find(option => option?.value === state.filterStatus) || statusOptions[0]}
                  search
                  onChange={handleStatusChange}
                  placeHolder={t("Select")}
                  isSelectOption={false}
                />
              </div>
              <div className="d-inline-block me-4 poppins-medium colorPrimary ms-3">
                {t("Total") + ":"}
                <span className="ms-1 poppins-semibold">
                  {state?.allData?.length}
                </span>
              </div>
              <span onClick={() => { }}>
                <Icon name="FilterIcon" width={filterIconSize} height={filterIconSize} />
              </span>
            </div>
          </div>
        </div>
        <OverFlowWrapper className="yourPoolWrapper">
          <div className="container-fluid ps-lg-0">
            <div className="row">
              {state?.displayedData?.length > 0 ? (
                <>
                  {state?.displayedData?.map((employee_data) => (
                    <div className="col-lg-3 col-md-6 mb-4" key={employee_data?.id}>
                      <EmployeeDetailsCard
                        employee_data={employee_data}
                        cardConfig={{
                          cardClassName: "text-break text-start d-flex align-items-center justify-content-center",
                          employeeInformationClass: "planningEmployeeDetails",
                          cardMainClassName: "employeeDetailsCardPlanning colorPrimary position-relative h-100",
                          iconClassName: "text-end cardEditIcon position-absolute top-0 end-0 cursor-pointer",
                          iconWrapperClassName: "employeeCardIconStyle",
                          isCardIcon: true,
                          iconName: "EditIcon",
                          favouriteIconName: "starIcon",
                          noFavouriteIconName: "whiteStarIcon",
                          isFavourite: employee_data?.is_fav,
                          status: getStatusStyle(`${employee_data?.status}`)
                        }}
                        handleClick={() => handleEmployeeClick(employee_data)}
                        handleFavIcon={() =>
                          toggleFavorite(employee_data?.id, employee_data?.is_fav)
                        }
                      />
                    </div>
                  ))}
                  {state?.hasMore && (
                    <div ref={observerTarget} className="text-center p-4">
                      {state?.isLoading ? "Loading..." : ""}
                    </div>
                  )}
                </>
              ) : (
                <NoRecords isDiv={true} message={t("No employees available")} className="text-danger" />
              )}
            </div>
          </div>
        </OverFlowWrapper>
      </div>
      {show && <PlanningTabs />}
    </>
  );
};

export default EmployeeOverview;
