import { useState, useEffect } from "react";

export const useResponsiveValue = (
  value1: string,
  value2: string,
  value3: string
): string => {
  // Function to determine the correct value based on window width
  const getResponsiveValue = () => {
    const width = window.innerWidth;
    if (width <= 767) {
      return value1;
    } else if (width <= 1023) {
      return value2;
    } else {
      return value3;
    }
  };

  // Initialize state with the calculated value
  const [responsiveValue, setResponsiveValue] = useState<string>(getResponsiveValue);

  useEffect(() => {
    const updateValue = () => {
      setResponsiveValue(getResponsiveValue());
    };

    // Update value on window resize
    window.addEventListener("resize", updateValue);
    return () => {
      window.removeEventListener("resize", updateValue);
    };
  }, [value1, value2, value3]);

  return responsiveValue;
};
