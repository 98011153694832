import React, { useState, useEffect, useMemo } from "react";
import { t, translate } from "../../translation/Translation";
import { ApiCall } from "../../../../services/ApiServices";

import { useNavigate } from "react-router-dom";
import Header from "../../../common/layout/Header";
import BackLink from "../../../common/atoms/BackLink";
import Button from "../../../common/atoms/Button";
import ResetBtn from "../../../common/atoms/ResetBtn";
import SearchBtn from "../../../common/atoms/SearchBtn";
import OverFlowWrapper from "../../../common/OverFlowWrapper";
import Icon from "../../../../Icon";
import Pagination from "../../../common/atoms/Pagination";
import ConfirmPopup from "../../../common/atoms/ConfirmPopup";
import TableHeaderSpace from "../../../common/atoms/TableHeaderSpace";
import NoRecords from "../../../common/atoms/NoRecords";
import { DELETE_HOLIDAY } from "../../../../routes/ApiEndpoints";
import LabelField from "../../../common/atoms/LabelField";
import "./css/Holiday.css";
import { useResponsiveValue } from "../../../common/atoms/ResponsivWidth";

const currentyear = new Date().getFullYear();
const PER_PAGE = 8;


const TableRendererHolidayList = ({ headers, rows, loadData }) => {

  const iconStyle = useResponsiveValue("5vw", "3vw", "1vw");

  const navigate = useNavigate();
  const basePath = "/manage/holiday-list";

  const [currentPage, setCurrentPage] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);


  const [state, setState] = useState({
    searchTerm: '',
    delete_title: "DELETE",
    filterRows: rows,
    searchKey: 'occasion',
    currentItems: filterrr(),
    pageCount: 0,
    itemOffset: 0,
    currentPage: 0,
    currentYear: currentyear,
  });


  function filterrr() {
    let filteredrow = [];
    rows.filter((hol) => {
      if (Number(hol.year) === currentyear) {
        filteredrow.push(hol);
      }
    });
    filteredrow.sort((a, b) => {
      const [dayA, monthA, yearA] = a.date.split('-');
      const [dayB, monthB, yearB] = b.date.split('-');
      return new Date(yearA, monthA - 1, dayA) - new Date(yearB, monthB - 1, dayB);
    });
    return filteredrow;
  }

  const handleClick = (action) => {
    let y;
    if (action == 'left') {
      if (state.currentYear === currentyear - 1) {
        y = state.currentYear;
        setIsDisabled(true)
      } else {
        y = state.currentYear - 1;
      }
      setCurrentPage(0);
    } else if (action == 'right') {
      y = state.currentYear + 1;
      setCurrentPage(0);
      setIsDisabled(false);
      // }
    }
    const filteredRow = rows.filter((hol) => {
      if (Number(hol.year) === y) {
        return hol;
      }
    })
    filteredRow.sort((a, b) => {
      const [dayA, monthA, yearA] = a.date.split('-');
      const [dayB, monthB, yearB] = b.date.split('-');
      return new Date(yearA, monthA - 1, dayA) - new Date(yearB, monthB - 1, dayB);
    });
    console.log(filteredRow)
    setState({ ...state, currentItems: filteredRow, currentYear: y });
  }


  const handleDelete = async (id) => {
    try {
      const result = await ApiCall.service(
        `${DELETE_HOLIDAY}/${id}`,
        "DELETE"
      );
      if (result.status == "success") {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const confirmDelete = () => {
    if (selectedRow) {
      handleDelete(selectedRow.id);
    }
    setShowDeletePopup(false);
    setSelectedRow(null);
  };

  const closeDeletePopup = () => {
    setShowDeletePopup(false);
    setSelectedRow(null);
  };


  const getNeededActions = (eachRow) => (
    <>
      <span
        title={t("Edit")}
        className="secondaryColorHoverEffect me-2"
        onClick={() => navigate(`${basePath}/edit?id=${eachRow.id}`)}
      >
        <Icon isIcon={true} width="1vw" height="1vw" name="EditIcon" />
      </span>
      <span
        title={t("Delete")}
        className="secondaryColorHoverEffect"
        onClick={() => {
          setSelectedRow(eachRow);
          setShowDeletePopup(true);
        }}
      >
        <Icon isIcon={true} width="1vw" height="1vw" name="delete" />
      </span>
    </>
  );


  /* Setting the number of items to display per page. */
  const PER_PAGE = 8;
  /* Calculating the offset for the current page. */
  const offset = currentPage * PER_PAGE;
  /* Slicing the data array into smaller chunks. */
  const currentPageData = state.currentItems.slice(offset, offset + PER_PAGE);
  /* Calculating the number of pages. */
  const pageCount = Math.ceil(state.currentItems.length / PER_PAGE);

  /**
     * Description
     * @param {selectedPage}} {selected
     * @returns {any}
     */
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber - 1);
  };


  return (

    <>
      <Header
        headerName={t("Holidays list")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-md-2">
        <div className="row">
          <BackLink backLink="/config/settings" />
          <div className="col-8 align-self-center text-center d-block d-lg-none">
            <LabelField
              title={t("Holidays list")}
              className="myAccountCardTitle mb-0"
            />
          </div>
          <div className="col-6 ms-auto text-end">
            <Button
              title={t("Add holiday")}
              className="searchBtn px-3"
              handleClick={() => navigate(`${basePath}/add?id=0`)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6 mx-auto">
            <div className='d-flex justify-content-around align-items-center'>
              <span className={`${isDisabled ? 'disabled' : 'secondaryColorHoverEffect cursor-pointer'}`} onClick={() => handleClick('left')}>
                <Icon name="chevronLeftSolid" width={iconStyle} height={iconStyle} isIcon={true} />
              </span>
              <LabelField title={state.currentYear} />
              <span className={`${isDisabled ? 'disabled' : 'secondaryColorHoverEffect cursor-pointer'}`} onClick={() => handleClick('right')}>
                <Icon name="chevronRight" width={iconStyle} height={iconStyle} isIcon={true} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="managepagesBg">
        <OverFlowWrapper className="table-responsive manageHolidayList">
          <table className="table table-hover customTableProperties pwa">
            <thead>
              <tr className="TableHeader">
                {headers.map((header, index) => (
                  <th
                    key={`tablecol${index}`}
                    scope="col"
                    style={{ width: "25%" }}
                  >
                    {t(header)}
                  </th>
                ))}
              </tr>
              <TableHeaderSpace />
            </thead>
            <tbody>
              {currentPageData.length > 0 ? (
                currentPageData.map((eachRow) => (
                  <tr key={eachRow.id} className="border">
                    <td>{eachRow.date}</td>
                    <td>{eachRow.holiday_occasion['occasion']}</td>
                    <td>{eachRow.type}</td>
                    <td>{getNeededActions(eachRow)}</td>
                  </tr>
                ))
              ) : (
                <NoRecords headerLength={headers.length} />
              )}
            </tbody>
          </table>
        </OverFlowWrapper>

        {pageCount > 1 && (
          <div className="pagination justify-content-center mt-4 px-4">
            <Pagination
              currentPage={currentPage + 1}
              totalPages={pageCount}
              onPageChange={handlePageClick}
            />
          </div>
        )}


        {showDeletePopup && (
          <ConfirmPopup
            show={showDeletePopup}
            onHide={closeDeletePopup}
            title={t("Delete holiday")}
            body={t(`Are you sure you want to delete this holiday?`)}
            confirmTitle={t("Delete")}
            closeTitle={t("Cancel")}
            onConfirmButtonClick={confirmDelete}
            onCloseButtonClick={closeDeletePopup}
          />
        )}

      </div>

    </>
  );

}

export default translate(TableRendererHolidayList);