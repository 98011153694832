import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import Header from "../../common/layout/Header";
import { LabelWithInputField } from "../../common/molecules/LabelWithInputField";
import { t, translate } from "../../CentralDataMangement/translation/Translation";
import LabelField from "../../common/atoms/LabelField";
import Button from "../../common/atoms/Button";
import SelectWithSearch from "../../common/atoms/SelectWithSearch";
import {
  DropdownOptions,
} from "../../Dashboard/services/CompanyInterface";
import { initialState } from "../../IdentityManager/authentication/MyAccount/State";
import {
  selectIbanCountries,
  selectLanguages,
} from "../../../features/settings/settingSlice";
import {
  GET_ACTIVE_FOLLOW_UPS,
  GET_ACTIVE_SALES,
  GET_OFFICES,
  GET_SALES_AGENTS,
  GET_TRADES,
  REGISTER_COUNTRY_LIST,
  REGISTER_SETTINGS_LIST,
  TRADE_DATA,
  TRADES_FOLLOW_UP,
  UPDATE_EMPLOYEE,
} from "../../../routes/ApiEndpoints";
import { ApiCall } from "../../../services/ApiServices";
import {
  formatUserData,
  formValidation,
} from "../../IdentityManager/authentication/MyAccount/services/MyaccountUtils";
import CustomNotify from "../../common/atoms/CustomNotify";
import LabelWithTextAreaField from "../../common/molecules/LabelWithTextAreaField";
import VatNumberField from "../../Dashboard/EmployerRegistration/VatNumberField";
import BackLink from "../../common/atoms/BackLink";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import { MyTrade } from "../services/MyTradeInterface";
import { mapToBackendPayload } from "./HelperFunctions";
import Calender from "../../common/molecules/Calender";
import TimeInput from "../../common/molecules/TimeInput";
import { Navigate, useNavigate } from "react-router-dom";
// import { validateTradeForm } from '../services/TradeformValidationUtils';
import "../css/trade.css";
import { selectAuth } from "../../../features/auth/AuthSlice";

interface Option {
  label: string;
  value: number | null | string;
}
// State management
const initialData: MyTrade = {
  formValues: {
    ...initialState.formValues,
    end_date: undefined,
    next_date: undefined,
    next_time: null,
    trade_email: "",
    contact_person_email: "",
    trade_phone_number: "",
    contact_person_phone: "",
    follow_up_type: null
  },
  errors: initialState.errors,
  countries: initialState.countries,
  country: undefined,
  offices: [],
};

const TradeForm: React.FC = () => {
  // Redux selectors
  const user = useSelector((state: any) => state.auth);
  const languages = useSelector(selectLanguages);
  const ibanCountries = useSelector(selectIbanCountries);
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);

  const [formState, setFormState] = useState<MyTrade>(initialData);
  const [optionsData, setOptionsData] = useState<any>(languages);
  const [reason, setReason] = useState<string>("");
  const [reasonError, setReasonError] = useState<string>("");
  const [followUpTypeOptions, setFollowUpTypeOptions] = useState<DropdownOptions[]>([]);
  const [setSalesOptions, setSalesTypeOptions] = useState<DropdownOptions[]>([]);


  // Memoized values
  const inputFieldProps = useMemo(
    () => ({
      labelWrapperClassName: "col-4 align-self-center pe-0",
      colClassName: "col-8",
      className: "inputFieldColor",
    }),
    []
  );

  const cardTitleProps = useMemo(
    () => ({
      pageTitle: true,
      className: "tab-subtitle colorPrimary mt-4 mt-lg-0 mb-3 mb-lg-2",
    }),
    []
  );

  // Handlers

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      const keys = name.split(".");

      setFormState((prevState) => {
        const updatedFormValues = {
          ...prevState.formValues,
          [name]: value,
          ...(name === "trade_email" && !prevState.formValues.contact_person_email && { contact_person_email: value }), // Set contact_person_email only if it's empty
          ...(name === "trade_phone_number" && !prevState.formValues.trade_phone_number && !prevState.formValues.contact_person_phone && { contact_person_phone: value }), // Set contact_person_phone only if both are empty
        };
        let current: any = updatedFormValues;

        keys.forEach((key, index) => {
          if (index === keys.length - 1) {
            current[key] = value;
          } else {
            current[key] = { ...current[key] };
            current = current[key];
          }
        });

        return {
          ...prevState,
          formValues: updatedFormValues,
        };
      });
    },
    []
  );

  const handleSelectChange = useCallback(
    (
      selectedOption: any,
      fieldPath: string,
      isMulti = false
    ) => {
      const value = isMulti
        ? (selectedOption as DropdownOptions[])?.map(
          (option) => option.value
        ) || []
        : selectedOption
          ? (selectedOption as DropdownOptions).value
          : null;

      if (fieldPath === "follow_up_type") {

        setFormState((prev) => ({
          ...prev,
          formValues: {
            ...prev.formValues,
            follow_up_type: selectedOption,
          },
        }));
      } else {
        setFormState((prev: MyTrade) => {
          const updatedFormValues = { ...prev.formValues };
          setNestedValue(updatedFormValues, fieldPath, value);
          return {
            ...prev,
            formValues: updatedFormValues,
          };
        });
      }

    },
    []
  );

  // Helper functions
  const setNestedValue = (
    obj: Record<string, any>,
    path: string,
    value: any
  ) => {
    const keys = path.split(".");
    let current = obj;

    keys.slice(0, -1).forEach((key) => {
      if (!current[key]) current[key] = {};
      current = current[key];
    });

    current[keys[keys.length - 1]] = value;
  };

  // API calls
  const authData = useSelector(selectAuth);
  const userId = authData.userId;

  const handleSubmit = useCallback(async () => {
    const backendPayload = mapToBackendPayload(formState.formValues, userId);

    if (!user?.token) return; // Early return if user token is not available

    try {
      const response = await ApiCall?.service(
        `${TRADES_FOLLOW_UP}`,
        "POST",
        backendPayload, // Use backendPayload here
        true,
        "api"
      );

      if (response?.status === "success") {
        CustomNotify({
          type: "success",
          message: "Trade created successfully",
        });
        Navigate("/dashboard/follow-up");
      } else {
        CustomNotify({
          type: "error",
          message: response?.message || "Failed to save fare",
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      CustomNotify({
        type: "error",
        message: "An error occurred while saving",
      });
    }
  }, [formState, user]);

  // VAT number update handler
  const updateVat = useCallback(
    (response?: any, errors?: any) => {
      if (errors) {
        setFormState((prev: any) => ({
          ...prev,
          errors: {
            ...prev.errors,
            vat_number: errors?.vat_number || "Error verifying VAT number.",
          },
        }));
        return { errors };
      }

      if (response && !errors) {
        const enterpriseData = response?.enterprise || {};
        const { iban_code = "" } = formState.formValues || {};
        const matchedCountry = formState.countries?.find(
          (country: DropdownOptions) => country.label === enterpriseData.country
        );
        const matchedIban = formState.ibancountries?.find(
          (iban: DropdownOptions) => iban.value === response?.vatcountry_iso
        );
        const contactPerson = enterpriseData.contacts?.[0] || {};


        setFormState((prev: any) => ({
          ...prev,
          verfiedVatnumber: `${iban_code}${enterpriseData.vatnumber || ''}`,
          formValues: {
            ...prev.formValues,
            vat_number: enterpriseData.vatnumber || "",
            official_name: enterpriseData.official_name || "",
            search_name: enterpriseData.search_name || "",
            first_name: user?.token ? user.first_name : "",
            last_name: user?.token ? user.last_name : "",
            trade_email: enterpriseData.mail || "",
            trade_phone_number: enterpriseData.phone || "",
            street: enterpriseData.street || "",
            postal_code: enterpriseData.postal_code || "",
            city: enterpriseData.city || "",
            country: matchedCountry?.value || "",
            contact_person_frist_name: contactPerson.first_name || "",
            contact_person_last_name: contactPerson.last_name || "",
            contact_person_email: contactPerson.mail || "",
            contact_person_phone: contactPerson.mobile || "",
            contact_person_title: contactPerson.title || "",
            contact_id: contactPerson.contact_id || "",
            street_number: enterpriseData.street_nr || "",
            iban_code: matchedIban?.value || iban_code,
            bright_id: enterpriseData?.enterprise_id || ""
          },
        }));
      }
    }, [formState.countries, formState.ibancountries, formState.formValues?.iban_code, user]);

  const handleDateFormat = (dateValue: Date | null, field: string) => {
    const formattedDate = dateValue
      ? new Date(dateValue.getTime() - dateValue.getTimezoneOffset() * 60000) // Adjust for timezone
        .toISOString()
        .slice(0, 10)
      : null;

    const fieldParts = field.split(".");

    const updateState = (state: any, value: any) => {
      let currentState = state;
      fieldParts.forEach((part, index) => {
        if (index === fieldParts.length - 1) {
          currentState[part] = value;
        } else {
          currentState[part] = currentState[part] || {};
          currentState = currentState[part];
        }
      });
      return state;
    };

    setFormState((prevState) => ({
      ...prevState,
      formValues: updateState({ ...prevState.formValues }, formattedDate),
    }));
  };

  // Effects
  useEffect(() => {
    if (ibanCountries) {
      const ibanData = ibanCountries?.map((iban: DropdownOptions) => ({
        value: iban?.value,
        label: iban?.label,
      }));
      setFormState((prev) => ({
        ...prev,
        ibanCountries: ibanData,
      }));
    }
  }, [ibanCountries]);

  const handleTimeChange = (name: string, value: string) => {
    setFormState((prev) => ({
      ...prev,
      formValues: {
        ...prev.formValues,
        [name]: value, // Dynamically set the key based on `name`
      },
    }));
  };

  const isInitialized = useRef(false);


  const fetchUserData = async (userId: number) => {
    const url = `${UPDATE_EMPLOYEE}/${userId}`;
    const response = await ApiCall?.getService(url, "GET", CENTRAL_DATA_MANAGEMENT_MICROSERVICE, true);
    if (response?.status === "success") {
      const formattedData = formatUserData(response?.data);
      setFormState((prev) => ({
        ...prev,
        formValues: { ...prev.formValues, ...formattedData },
      }));
    }
  };


  const initializeForm = useCallback(async () => {

    if (isInitialized.current) return; // Prevent re-initialization
    isInitialized.current = true; // Set to true after first call

    try {
      const [tradeData] =
        await Promise.all([
          ApiCall.getService(TRADE_DATA, "GET", CENTRAL_DATA_MANAGEMENT_MICROSERVICE),
        ]);
      const activeSales = tradeData.data.active_sales.original;
      const countries = tradeData.data.countries.original;
      const trades = tradeData.data.active_trades.original;
      const ibanCountries = tradeData.data.setting_dat.original;
      const offices = tradeData.data.offices.original;
      const salesAgents = tradeData.data.sales_agents.original;
      const followUpTypes = tradeData.data.active_follow_ups.original;
      console.log("mainnns", tradeData);
      const activeSalesOptions: DropdownOptions[] = Object.values(activeSales.data['activeSales'] || {});
      const followUpOptions: DropdownOptions[] = Object.values(followUpTypes.data['activeFollowUps'] || []);
      const loggedInUserSalesName =  user['first_name'];
      const userValue = salesAgents.data.find((agnentData:any) => agnentData.label === loggedInUserSalesName);
      const loggedInUserSales = userValue.value ?? null;


      setSalesTypeOptions(activeSalesOptions);
      setFollowUpTypeOptions(followUpOptions);

      setFormState((prev) => ({
        ...prev,
        formValues: {
          ...prev.formValues,
          office_id: user?.primaryOfficeID,
          office_consultant_id: activeSales.data['defaultSale']?.value || user?.userId,
          countries: 29,
          sales: loggedInUserSales,
          iban_code: "BE",
          trades: trades.data.find((option: { status: number }) => option.status === 1)?.value,
          follow_up_type: followUpTypes.data['defaultFollowUp'],
        },
        countries: countries.data,
        trades: trades.data,
        ibancountries: ibanCountries.data.iban_countries?.map((iban: any) => ({
          value: iban.value,
          label: iban.label,
        })),
        offices: offices.data,
        sales: salesAgents.data,
      }));

      const tradeId = urlParams.get("tradeId");
      if (tradeId) {
        setFormState((prev) => ({
          ...prev,
          formValues: {
            ...prev.formValues,
            trades: (tradeId),
          },
        }));
      }

      if (user?.userId) {
        await fetchUserData(user.userId);
      }
    } catch (error) {
      console.error("Error initializing form:", error);
    }
  }, [user?.primaryOfficeID, user?.userId, urlParams]);

  useEffect(() => {
    initializeForm();
  }, [user, urlParams]);

  return (
    <>
      <Header headerName={t("Add follow up")} headerClassName="myAccountCardTitle" />
      <div className="bg-white" style={{ borderRadius: "2vw" }}>
        <div className="search-bar pb-md-2">
          <div className="row mb-2">
            <BackLink backLink="/dashboard/follow-up" />
          </div>
        </div>

        <div className="createpagesBg mb-3">
          {/* Trade name */}
          <div className="row multiSelectWrapper">
            <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
              <SelectWithSearch
                fieldLabel={`${t("Trade name")}: `}
                title={`${t("Trade name")}:`}
                name="trades"
                placeHolder={t("Select a trade name")}
                search
                options={formState.trades || []}
                value={
                  formState?.trades?.find(
                    (option: DropdownOptions) =>
                      option.value === formState?.formValues?.trades
                  ) || null
                }
                onChange={(selectedOptions: any) =>
                  handleSelectChange(selectedOptions, "trades", false)
                }
                isMulti={false}
                className="select-field"
                isTranslate
                isMandatory
                error={formState?.errors?.trades}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <SelectWithSearch
                fieldLabel={`${t("office id")}: `}
                title={`${t("Office id")}:`}
                name="office_id"
                placeHolder={t("Select a office id")}
                search
                options={formState.offices || []}
                value={
                  formState?.offices?.find(
                    (option: DropdownOptions) =>
                      option.value === formState?.formValues?.office_id
                  ) || null
                }
                onChange={(selectedOptions: any) =>
                  handleSelectChange(selectedOptions, "office_id", false)
                }
                isMulti={false}
                className="select-field"
                isTranslate
                isMandatory
                error={formState?.errors?.office_id}
              />
            </div>

            {/* Office name */}
            <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
              <SelectWithSearch
                fieldLabel={`${t("Sales name")}: `}
                title={`${t("Sales name")}:`}
                name="office_consultant_id"
                placeHolder={t("Select a Sales name")}
                search
                options={formState.sales || []}
                value={
                  formState?.sales?.find(
                    (option: DropdownOptions) =>
                      option.value === formState?.formValues?.office_consultant_id
                  ) || null
                }

                onChange={(selectedOptions: any) =>
                  handleSelectChange(
                    selectedOptions,
                    "office_consultant_id",
                    false
                  )
                }
                isMulti={false}
                className="select-field"
                isTranslate
                isMandatory
                error={formState?.errors?.office_consultant_id}
              />
            </div>

            {/* Company details */}
            <LabelField title={t("Company details")} {...cardTitleProps} />
            <div className="col-lg-4 mb-4 mb-lg-3">
              <VatNumberField
                t={t}
                companyid={0}
                vat_number={formState.formValues.vat_number ?? ""}
                iban_code={formState.formValues.iban_code}
                handleSelectChange={handleSelectChange}
                handleChange={handleChange}
                Editing={false}
                clearFieldError={() => { }}
                updateVat={updateVat}
                isFieldCol={true}
                isDataCol={true}
                isVatGet={true}
                errors={formState?.errors?.vat_number}
              />
            </div>

            {/* Company name */}
            <div className="col-lg-4 col-md-6 mb-4 mb-lg-3">
              <LabelWithInputField
                label={`${t("Company name")}:`}
                type="text"
                value={formState?.formValues?.official_name}
                name="official_name"
                handleChange={handleChange}
                id="official_name"
                error={formState?.errors?.official_name}
                isMandatory
                isTop={true}
                {...inputFieldProps}
              />
            </div>

            {/* Search name */}
            <div className="col-lg-4 col-md-6 mb-4 mb-lg-3">
              <LabelWithInputField
                label={`${t("Search name")}:`}
                type="text"
                value={formState?.formValues?.search_name}
                name="search_name"
                handleChange={handleChange}
                id="search_name"
                error={formState?.errors?.search_name}
                isMandatory
                isTop={true}
                {...inputFieldProps}
              />
            </div>

            {/* Email */}
            <div className="col-lg-4 col-md-6 mb-4 mb-lg-3">
              <LabelWithInputField
                label={`${t("Email")}:`}
                type="email"
                value={formState?.formValues?.contact_person_email || formState?.formValues?.trade_email}
                name="contact_person_email"
                handleChange={handleChange}
                id="contact_person_email"
                error={formState?.errors?.trade_email}
                isMandatory
                isTop={true}
                {...inputFieldProps}
              />
            </div>

            {/* Phone number */}
            <div className="col-lg-4 col-md-6 mb-lg-3">
              <LabelWithInputField
                label={`${t("Phone number")}:`}
                type="text"
                value={formState?.formValues?.contact_person_phone || formState?.formValues?.trade_phone_number}
                name="contact_person_phone"
                handleChange={handleChange}
                id="contact_person_phone"
                error={formState?.errors?.trade_phone_number}
                // isMandatory
                isTop={true}
                {...inputFieldProps}
              />
            </div>

            {/* Address Details */}
            <LabelField title={t("Address Details")} {...cardTitleProps} />
            <div className="col-md-6 mb-4 mb-lg-3">
              <LabelWithInputField
                label={`${t("Street")}:`}
                type="text"
                value={formState?.formValues?.street}
                name="street"
                handleChange={handleChange}
                id="street"
                isMandatory
                error={formState?.errors?.street}
                isTop={true}
                {...inputFieldProps}
              />
            </div>

            <div className="col-lg-3 col-md-6 mb-4 mb-lg-3">
              <LabelWithInputField
                label={`${t("House no")}:`}
                type="text"
                value={formState?.formValues?.street_number}
                name="street_number"
                handleChange={handleChange}
                id="street_number"
                isMandatory
                isTop={true}
                error={formState?.errors?.street_number}
                {...inputFieldProps}
              />
            </div>

            <div className="col-lg-3 col-md-6 mb-4 mb-lg-3">
              <LabelWithInputField
                label={`${t("Bus")}:`}
                type="text"
                value={formState?.formValues?.bus}
                name="address.residence.bus"
                handleChange={handleChange}
                id="bus"
                isTop={true}
                error={formState?.errors?.address_residence_bus}
                {...inputFieldProps}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4 mb-lg-3">
              <LabelWithInputField
                label={`${t("Postal code")}:`}
                type="text"
                value={formState?.formValues?.postal_code}
                name="postal_code"
                handleChange={handleChange}
                id="postal_code"
                isMandatory
                isTop={true}
                error={formState?.errors?.postal_code}
                {...inputFieldProps}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4 mb-lg-3 multiSelectWrapper">
              <LabelWithInputField
                label={`${t("City")}:`}
                type="text"
                value={formState?.formValues?.city}
                name="city"
                handleChange={handleChange}
                id="city"
                isMandatory
                isTop={true}
                error={formState?.errors?.city}
                {...inputFieldProps}
              />
            </div>

            {/* Country */}
            <div className="col-lg-4 col-md-6 multiSelectWrapper mb-lg-3">
              <SelectWithSearch
                fieldLabel={`${t("Country")}: `}
                title={`${t("Country")}:`}
                name="country"
                placeHolder={t("Select")}
                search
                options={formState.countries || []}
                value={
                  formState?.countries?.find(
                    (option: DropdownOptions) =>
                      option.value === formState?.formValues?.countries
                  ) || null
                }
                onChange={(selectedOptions: any) =>
                  handleSelectChange(selectedOptions, "countries", false)
                }
                isMulti={false}
                className="select-field"
                isTranslate
                isMandatory
                isTop={true}
                error={formState?.errors?.countries}
                labelWrapperClassName="col-4 align-self-center"
                colClassName="col-8"
              />
            </div>

            {/* Contact Details */}
            <LabelField title={t("Contact details")} {...cardTitleProps} />
            <div className="col-lg-4 col-md-6 mb-4 mb-lg-3 ">
              <LabelWithInputField
                label={`${t("Contact person's first name")}:`}
                type="text"
                value={formState?.formValues?.contact_person_frist_name}
                name="contact_person_frist_name"
                handleChange={handleChange}
                id="contact_person_frist_name"
                error={formState?.errors?.contact_person_frist_name}
                isMandatory
                isTop={true}
                {...inputFieldProps}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4 mb-lg-3">
              <LabelWithInputField
                label={`${t("Contact person's last name")}:`}
                type="text"
                value={formState?.formValues?.contact_person_last_name}
                name="contact_person_last_name"
                handleChange={handleChange}
                id="contact_person_last_name"
                error={formState?.errors?.contact_person_last_name}
                isMandatory
                isTop={true}
                {...inputFieldProps}
              />
            </div>

            {/* Contact Person’s Role */}
            <div className="col-lg-4 col-md-6 multiSelectWrapper mb-lg-3">
              <LabelWithInputField
                label={`${t("Contact Person’s Role")}:`}
                type="text"
                value={formState?.formValues?.contact_person_title}
                name="contact_person_title"
                handleChange={handleChange}
                id="contact_person_title"
                error={formState?.errors?.contact_person_title}
                isMandatory
                isTop={true}
                {...inputFieldProps}
              />
            </div>

            {/* Follow-up */}
            <LabelField title={t("Follow up details")} {...cardTitleProps} />

            <div className="col-lg-3 col-md-6 mb-4 mb-lg-3 multiSelectWrapper">
              <SelectWithSearch
                fieldLabel={`${t("Follow-up types")}: `}
                title={`${t("Follow-up types")}:`}
                name="follow_up_type"
                placeHolder={t("Select follow-up type")}
                search={true}
                options={followUpTypeOptions}
                value={
                  formState.formValues.follow_up_type
                }
                onChange={(selectedOptions: any) =>
                  handleSelectChange(selectedOptions, "follow_up_type", false)
                }
                isMulti={false}
                className="select-field"
                isTranslate
                isMandatory
                error={formState?.errors?.follow_up_type}
              />

            </div>

            <div className="col-lg-3 col-md-6 mb-4 mb-lg-3">
              <LabelField
                title={t("Follow-up next date") + ":"}
                isMandatory={true}
              />
              <div className="col-12 position-relative calendarSectionWrapper traderNextDate">
                <Calender
                  onChange={(date) =>
                    date && handleDateFormat(date, "next_date")
                  }
                  selectedDate={
                    formState?.formValues?.next_date
                      ? new Date(formState?.formValues?.next_date)
                      : null
                  }
                  // Remove the maxDate prop
                  error={formState?.errors?.next_date}
                  name="next_date"
                />
              </div>
            </div>

            <div className="col-lg-2 col-md-6 mb-4 mb-lg-3">
              <div className="col-12 position-relative calendarSectionWrapper">
                <TimeInput
                  setTime={(e) => handleTimeChange("next_time", e)}
                  index={1}
                  type="time"
                  value={formState.formValues.next_time ?? ""}
                  title={t("Follow-up next time")}
                  isMandatory={true}
                  error={formState?.errors?.next_time}
                  timePickerClassName="tradeTimePicker"
                />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4 mb-lg-3">
              <SelectWithSearch
                fieldLabel={`${t("Sales ")}: `}
                title={`${t("Sales")}:`}
                name="sales"
                placeHolder={t("Select a Sales")}
                search
                options={formState.sales || []}
                value={
                  formState?.sales?.find(
                    (option: DropdownOptions) =>
                      option.value === formState?.formValues?.sales
                  ) || null
                }
                onChange={(selectedOptions: any) =>
                  handleSelectChange(selectedOptions, "sales", false)
                }
                isMulti={false}
                className="select-field"
                isTranslate
                isMandatory
                error={formState?.errors?.sales}
                isDisabled
              />
            </div>

            {/* Feedback */}
            <div className="col-12 mb-3">
              <LabelWithTextAreaField
                label={t("Feedback") + ":"}
                value={formState.formValues.info}
                handleChange={handleChange}
                name="info"
                isMandatory
                error={formState?.errors?.info}
                rows={4}
              />
            </div>
          </div>

          <div className="row pb-lg-4 pb-5 pt-4 pt-lg-0">
            <div className="col-12 text-end">
              <Button
                type="submit"
                className="searchBtn px-3 "
                title={t("Save")}
                style={{ width: "fit-content" }}
                handleClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default translate(TradeForm);
