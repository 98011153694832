import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { OptionProps } from "../../components/common/utlis/TypeAnnotations";
// import { DateInfoProps } from '../../components/common/layout/Header';

// Interface for a company
export interface Company {
    id: number | null;
    name: string;
    official_name: string;
    profile_picture_url: string;
    status?: boolean;
    is_blocked?: boolean;
    locations: Locations[];
}

// Interface for the authentication state
interface Locations {
    address_id: number,
    id: number,
    location_image_id: number,
    name: string
}
interface AuthState {
    token: string;
    email: string;
    isAuthenticated: boolean;
    refreshToken: string | undefined;
    userId: number | null;
    role: string;
    name: string;
    userPermissions: any;
    isSuperAdmin: boolean;
    profilePicName: string;
    candidateId: number | null;
    profilePicId: number | null;
    profilePicPath: string;
    lastLogin: Date | null;
    // dateInfo: DateInfoProps;
    office: string;
    officeId: number | null;
    companies: Array<Company> | null;
    employer_companies: Array<Company> | null;
    currentCompany: Company; //
    primaryOfficeID: number | null
}

// Initial date used for default dateInfo
const initialDate = new Date();

// Initial state for the authentication slice
const initialState: AuthState = {
    token: "",
    email: "",
    isAuthenticated: false,
    refreshToken: "",
    userId: null,
    role: "",
    name: "",
    userPermissions: [],
    isSuperAdmin: false,
    profilePicName: "",
    candidateId: null,
    profilePicId: null,
    profilePicPath: "",
    lastLogin: null,
    companies: [],
    employer_companies: [],
    currentCompany: { id: null, name: "", official_name: "", profile_picture_url: "", locations: [] },
    // dateInfo: {
    //     type: 'month',
    //     value: initialDate.getMonth() + 1, // Month value (1-12)
    //     label: initialDate.toLocaleString('default', { month: 'long' }) + " " + initialDate.getFullYear(), // e.g., "August 2024"
    //     year: initialDate.getFullYear() // Year value
    // },
    office: "",
    officeId: null,
    primaryOfficeID: null
}

const updateOrAddArrayItem = (array: Company[] | null, company: Company) => {
    if (array) {
        const existingIndex = array.findIndex(c => c.id === company.id);
        if (existingIndex >= 0) {
            array[existingIndex] = company;
        } else {
            array.push(company);
        }
    } else {
        array = [company];
    }
};
// Adding a utility function to update a specific company's status and is_blocked
const updateCompanyFields = (
    array: Company[] | null,
    companyId: number,
    updates: Partial<Pick<Company, 'status' | 'is_blocked'>>
) => {
    if (array) {
        const existingIndex = array.findIndex(c => c.id === companyId);
        if (existingIndex >= 0) {
            array[existingIndex] = { ...array[existingIndex], ...updates };
        }
    }
};

// Creating the authentication slice
const AuthSlice = createSlice({
    name: 'auth', // Name of the slice
    initialState, // Initial state for the slice
    reducers: { // Reducer functions
        // Reducer to add user details
        addUserDetails: (state, action: PayloadAction<object>) => {
            return { ...state, ...action.payload }; // Merge the current state with the payload
        },
        // Reducer to remove user details and reset to initial state
        removeUserDetails: (state, action: PayloadAction<void>) => {
            return initialState; // Reset state to initial state
        },
        // Reducer to add user profile picture (currently empty implementation)
        addUserProfilePic: (state, action: PayloadAction<string>) => {
            // Implementation can be added here
        },
        // Reducer to update or add a company
        updateOrAddCompany: (state, action: PayloadAction<Company>) => {
            updateOrAddArrayItem(state.companies, action.payload);
            updateOrAddArrayItem(state.employer_companies, action.payload);
        },
        // New reducer to update status and is_blocked fields for a specific company
        updateCompanyStatusAndBlock: (
            state,
            action: PayloadAction<{ companyId: number; status?: boolean; is_blocked?: boolean }>
        ) => {
            const { companyId, status, is_blocked } = action.payload;
            updateCompanyFields(state.companies, companyId, { status, is_blocked });
            updateCompanyFields(state.employer_companies, companyId, { status, is_blocked });
        },
        // Reducer to set the companies list
        setCompanies: (state, action: PayloadAction<Company[]>) => {
            state.companies = action.payload;
        },
    }
});

// Exporting the actions created by the slice
export const { addUserDetails,
    removeUserDetails,
    updateOrAddCompany,
    setCompanies,
    updateCompanyStatusAndBlock,
} = AuthSlice.actions;

// Selector to access the authentication state
export const selectAuth = (state: RootState) => state.auth;
export const selectCurrentCompany = (state: RootState) => state?.auth?.currentCompany;
export const selectCurrentLocations = (state: RootState) => state?.auth?.currentCompany?.locations;
export const selectEmployerCompanies = (state:RootState) => state?.auth?.employer_companies;
// Adding new selectors for status and is_blocked
export const selectCompanyStatus = (state: RootState, companyId: number) =>
    state.auth.companies?.find((company: Company) => company.id === companyId)?.status;

export const selectCompanyBlockedStatus = (state: RootState, companyId: number) =>
    state.auth.companies?.find((company: Company) => company.id === companyId)?.is_blocked;




// Exporting the reducer function created by the slice
export default AuthSlice.reducer;
