import React, { useState, useEffect } from "react";
import { COST_CENTERS, COST_CENTERS_BY_COMPANY } from "../../../routes/ApiEndpoints";
import { ApiCall } from "../../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import Header from "../../common/layout/Header";
import { t, translate } from "../translation/Translation";
import Icon from "../../../Icon";
import OverFlowWrapper from "../../common/OverFlowWrapper";
import BackLink from "../../common/atoms/BackLink";
import LinkCustom from "../../common/atoms/Link";
import { Link } from "react-router-dom";
import InputTextfield from "../configurations/manageCoefficientDeviations/InputTexfield";
import ResetBtn from "../../common/atoms/ResetBtn";
import SearchBtn from "../../common/atoms/SearchBtn";
import Pagination from "../../common/atoms/Pagination";
import TableHeaderSpace from "../../common/atoms/TableHeaderSpace";
import { useSelector } from "react-redux";
import LoadingIcon from "../../common/utlis/LoadingIcon";
import NoRecords from "../../common/atoms/NoRecords";
import EditIcon from "../../common/molecules/Actions/CustomEdit";
import LabelField from "../../common/atoms/LabelField";

interface CostCenter {
  id: number;
  name: string;
}

const ManageCostCenters: React.FC = () => {
  const [costCenters, setCostCenters] = useState<CostCenter[]>([]); // State to store CostCenters
  const [filteredCostCenters, setFilteredCostCenters] = useState<CostCenter[]>(
    []
  ); // State for filtered CostCenters
  const [searchTerm, setSearchTerm] = useState<string>(""); // State for search input
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const companyId = useSelector((state: any) => state.auth.currentCompany?.id);


  // Fetch CostCenters from API
  const IconProps = {
    isIcon: true,
    width: "1vw",
    height: "1vw",
  };

  useEffect(() => {
    fetchCostCentersByCompany(companyId);
  }, []);

  const fetchCostCentersByCompany = async (companyId: number) => {

    setLoading(true);
    try {
      let payload = {
        company_id: companyId,
      }
      const response = await ApiCall.service(
        COST_CENTERS_BY_COMPANY,
        "POST",
        payload,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status == 'success') {
        const costCentersData = response.data.map((item: any) => ({
          id: item.id,
          name: item.name,
        }));
        setCostCenters(costCentersData);
        setFilteredCostCenters(costCentersData); // Initially set filtered costCenters to all costCenters
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };


  // Handle input change for search
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value); // Only store the search term but don't filter yet
  };

  // Handle reset button click to clear search
  const handleResetClick = () => {
    setSearchTerm("");
    setFilteredCostCenters(costCenters); // Reset to original costCenters
  };

  // Handle search button click
  const handleSearchClick = () => {
    // Filter costCenters based on the search term
    const filteredData = costCenters.filter((costCenter) =>
      costCenter.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCostCenters(filteredData);
  };

  return (
    <>
      <Header
        headerName={t("Manage cost center")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-md-2">
        <div className="row mb-2">
          <BackLink backLink="/" />
          <div className="col-8 align-self-center text-center d-block d-lg-none">
            <LabelField
              title={t("Manage cost center")}
              className="myAccountCardTitle mb-0"
            />
          </div>
          <div className="col-6 text-end ms-auto">
            <Link to="/costcenter/add">
              <div className="searchBtn d-inline-flex align-items-center justify-content-center text-decoration-none w-auto px-3">
                {t("Add cost center")}
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <InputTextfield
              value={searchTerm}
              handleChange={handleInputChange}
              placeholder={t("Cost center")}
              className="ps-2"
            />
          </div>
          <div className="col-md-4 mt-3 mt-md-0">
            <div className="d-flex align-items-center justify-content-lg-start justify-content-end">
              <ResetBtn handleResetClick={handleResetClick} />
              <SearchBtn handleSearchClick={handleSearchClick} />
            </div>
          </div>
        </div>
      </div>
      <div className="managepagesBg">
        <OverFlowWrapper className="table-responsive height-25">
          {loading ? (
            <div className="col-12 text-center my-5">
              <LoadingIcon />
            </div>

          ) : filteredCostCenters.length > 0 ? (
            <table className="table table-hover customTableProperties pwa">
              <thead>
                <tr className="TableHeader">
                  <th style={{ width: "60%" }}>{t("Cost center")}</th>
                  <th>{t("Action")}</th>
                </tr>
                <TableHeaderSpace headerLength={3} />
              </thead>
              <tbody>
                {filteredCostCenters.map((costCenter) => (
                  <tr key={costCenter.id} className="border">
                    <td data-label={t("Cost center")}>{costCenter.name}</td>
                    <td className="px-2">
                      <EditIcon href={`/costcenter/edit/${costCenter.id}`} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <NoRecords isDiv={true} message={error || t("No cost centers found for the company.")} color={error ? "text-danger" : "text-dark"} />
          )}
        </OverFlowWrapper>
        {/* {pageCount > 1 && (
          <div className="pagination justify-content-center mt-4 px-4">
            <Pagination
              currentPage={currentPage + 1}
              totalPages={pageCount}
              onPageChange={handlePageClick}
            />
          </div>
        )} */}
      </div>
    </>
  );
};

export default translate(ManageCostCenters);
