import React from "react";
import Button from "../common/atoms/Button";
import { t } from "../CentralDataMangement/translation/Translation";
import Icon from "../../Icon";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";

interface NavigationProps {
  isLastTab: boolean;
  onPrevious?: () => void; // Added for handling the Previous button
  onNext?: () => void;
  onSubmit?: () => void;
  handleSaveAsDraftClick?: () => void;
}

const Navigation: React.FC<NavigationProps> = ({
  isLastTab,
  onPrevious,
  onNext,
  onSubmit,
  handleSaveAsDraftClick,
}) => {
  const iconSize = useResponsiveValue("5vw", "3vw", "1.25vw");

  const handlePreviousClick = () => {
    if (onPrevious) {
      onPrevious();
    }
  };

  const handleNextClick = () => {
    if (onNext) {
      onNext();
    }
  };

  const handleSubmitClick = () => {
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <div className="row py-4">
      <div className="col-6">
        <span
          onClick={handlePreviousClick}
          className="backIconWrapper"
          title={t("Previous")}
        >
          <Icon
            isIcon={true}
            name="backIcon"
            width={iconSize}
            height={iconSize}
          />
        </span>
      </div>
      <div className="col-6 text-end">
        <Button
          handleClick={handleSaveAsDraftClick}
          title={t("Save as draft")}
          className="saveBtn px-3 me-3"
        />

        {/* Conditionally render Submit Button or Next Icon */}
        {isLastTab ? (
          <Button
            handleClick={handleSubmitClick}
            title={t("Submit")}
            className="saveBtn px-3 me-3"
          />
        ) : (
          <span
            onClick={handleNextClick}
            className="backIconWrapper m-1"
            title={t("Next")}
          >
            <Icon
              isIcon={true}
              name="nextIcon"
              width={iconSize}
              height={iconSize}
            />
          </span>
        )}
      </div>

    </div>
  );
};

export default Navigation;
