import React, { useContext, useEffect } from "react";
import LinkCoeffEmpContext from "./LinkCoeffDeviationEmp/LinkCoeffDeviationEmpContext";
import {
  t,
  translate,
} from "../../../CentralDataMangement/translation/Translation";
import { helpers } from "./LinkCoeffEmpHelper";

const EmployeeTypeSecondPart = () => {
  const { state, updateStateChanges } = useContext(LinkCoeffEmpContext);
  const {
    employeeTypeArray,
    pclinkingValueobj,
    inputRef,
    selectedPc,
    selectedDevaitionObj,
    ConstructedCoefficientData,
    CoefficientDeviationlinkingValueobj,
  } = state;

  useEffect(() => {
    const current = state.inputRef.current["secondpart"];
    const trigger = () => {
      const hasOverflow = current.scrollWidth > current.clientWidth;
      updateStateChanges({
        isOverflow: hasOverflow,
        scrollRight: true,
        scrollLeft: false,
        tableWidth: "97%",
      });
    };
    if (current) {
      trigger();
    }
  }, [selectedPc]);

  // Validate European format number
  const isValidEuropeanNumber = (value) => {
    // Allow empty values
    if (!value) return true;

    // European format: only allows digits, one comma, and up to 4 decimal places
    const europeanNumberRegex = /^-?\d*(?:,\d{0,4})?$/;
    return europeanNumberRegex.test(value);
  };

  // Convert European to standard format for internal storage
  const europeanToStandard = (value) => {
    if (!value) return value;
    return value.replace(",", ".");
  };

  // Convert standard to European format for display
  const standardToEuropean = (value) => {
    if (!value) return value;
    return value.toString().replace(".", ",");
  };

  const handleValueChange = (target, _EmpId, _Coeffid, _ValId) => {
    let value = target.value;

    // Validate input format
    if (!isValidEuropeanNumber(value)) {
      // Revert to previous valid value
      value = CoefficientDeviationlinkingValueobj[_EmpId]?.[_Coeffid]?.[_ValId] || "";
      target.value = value;
      return;
    }

    // Store in standard format internally
    const standardValue = europeanToStandard(value);

    let valueDataObj = {
      ...CoefficientDeviationlinkingValueobj,
      [_EmpId]: {
        ...(CoefficientDeviationlinkingValueobj[_EmpId] || {}),
        [_Coeffid]: {
          ...(CoefficientDeviationlinkingValueobj[_EmpId]?.[_Coeffid] || {}),
          [_ValId]: standardValue,
        },
      },
    };

    updateStateChanges({
      CoefficientDeviationlinkingValueobj: valueDataObj,
    });
  };

  const getEmployeeTypeTableContent = () => {
    let htmlContent = [];
    selectedDevaitionObj.map((coefficient) => {
      htmlContent.push(
        <tr
          key={`${coefficient.value}-1`}
          className="table-second-part-tbody-tr"
        >
          {employeeTypeArray.map((employeeType) => {
            let _EmpId = employeeType.id,
              _ValId = 1,
              _Coeffid = coefficient.value;
            let { matrixKey, value } = getPcLinkingValue(
              _EmpId,
              _Coeffid,
              _ValId
            );

            // Convert to European format for display
            const displayValue = standardToEuropean(value);

            return (
              <td
                key={matrixKey}
                id={matrixKey}
                className="table-second-part-tbody-tr"
              >
                <input
                  type="text"
                  className="form-control border border-white shadow-none text-center"
                  value={displayValue}
                  id={`${_EmpId}_${_Coeffid}_${_ValId}`}
                  ref={(ref) =>
                    (inputRef.current[`${_EmpId}_${_Coeffid}_${_ValId}`] = ref)
                  }
                  onChange={(e) =>
                    handleValueChange(e.target, _EmpId, _Coeffid, _ValId)
                  }
                />
              </td>
            );
          })}
        </tr>
      );
    });

    return htmlContent;
  };

  const getPcLinkingValue = (_EmpId, _Coeffid, _ValId) => {
    let matrixKey = `${_EmpId}-${_Coeffid}-${_ValId}`;
    let valueObj = "";

    if (
      CoefficientDeviationlinkingValueobj[_EmpId] &&
      CoefficientDeviationlinkingValueobj[_EmpId][_Coeffid]
    ) {
      valueObj = CoefficientDeviationlinkingValueobj[_EmpId][_Coeffid][_ValId];
    } else {
      valueObj = ConstructedCoefficientData[_Coeffid]["coefficient"];
      let valueDataObj = {
        ...CoefficientDeviationlinkingValueobj,
        [_EmpId]: {
          ...(CoefficientDeviationlinkingValueobj[_EmpId] || {}),
          [_Coeffid]: {
            ...(CoefficientDeviationlinkingValueobj[_EmpId]?.[_Coeffid] || {}),
            [_ValId]: valueObj,
          },
        },
      };
      updateStateChanges({
        CoefficientDeviationlinkingValueobj: valueDataObj,
      });
    }

    return {
      matrixKey,
      value: valueObj,
    };
  };

  return (
    <div ref={(ref) => (inputRef.current["secondpart"] = ref)}>
      <table className="table mb-0 defaultCoefficientTableRightSection table-bordered customTableProperties">
        <thead>
          <tr
            className="TableHeader align-middle"
            style={{
              height: window.innerWidth <= 767
                ? "3.5vw"
                : window.innerWidth <= 1023
                  ? "10.99vw"
                  : "5.99vw",
            }}
          >
            {employeeTypeArray.map((emp) => (
              <th className="text-center" key={emp.id} title={emp.name}>
                {emp.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{getEmployeeTypeTableContent()}</tbody>
      </table>
    </div>
  );
};

export default translate(EmployeeTypeSecondPart);
