import React, { useState, useEffect, useCallback, useMemo } from "react";
import Popup from "./Popup";
import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import {
  MANAGE_FUNCTIONS_DELETE,
  MANAGE_FUNCTIONS_GET,
} from "../../../routes/ApiEndpoints";
import { ApiCall } from "../../../services/ApiServices";
import LinkCustom from "../../common/atoms/Link";
import { useNavigate } from "react-router-dom";
import Header from "../../common/layout/Header";
import Search from "../../common/atoms/Search";
import ResetBtn from "../../common/atoms/ResetBtn";
import SearchBtn from "../../common/atoms/SearchBtn";
import Icon from "../../../Icon";
import BackLink from "../../common/atoms/BackLink";
import Pagination from "../../common/atoms/Pagination";
import OverFlowWrapper from "../../common/OverFlowWrapper";
import TableHeaderSpace from "../../common/atoms/TableHeaderSpace";
import NoRecords from "../../common/atoms/NoRecords";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import EditIcon from "../../common/molecules/Actions/CustomEdit";
import CustomDelete from "../../common/molecules/Actions/CustomDelete";
import LabelField from "../../common/atoms/LabelField";

const PER_PAGE = 10;

const ManageFunctions = () => {
  const [functions, setFunctions] = useState([]);
  const [functionsTemp, setFunctionsTemp] = useState([]);
  const [searchParams, setSearchParams] = useState({
    pc: "",
    func: "",
    cat: "",
    sal: "",
  });
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [funcnId, setFuncnId] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await ApiCall.getService(
        MANAGE_FUNCTIONS_GET,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.success) {
        setFunctions(response.data);
        setFunctionsTemp(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteFuncn = async () => {
    try {
      const data = { id: funcnId };
      const response = await ApiCall.service(
        MANAGE_FUNCTIONS_DELETE,
        "POST",
        data,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.success) {
        setFunctionsTemp((prev) =>
          prev.filter((func) => func.funcn_id !== funcnId)
        );
        setShowDeletePopup(false);
        fetchData();
        // window.location.reload();
      } else {
        console.error("Failed to delete the function.");
      }
    } catch (error) {
      console.error("An error occurred while deleting the function:", error);
    }
  };

  const closePopup = () => setShowDeletePopup(false);

  const showPopup = useCallback((id) => {
    setFuncnId(id);
    setShowDeletePopup(true);
  }, []);

  const handleSearch = () => {
    const filteredFunctions = functionsTemp.filter((val) => {
      return (
        (searchParams.pc === "" ||
          val.pc_number?.trim().includes(searchParams.pc.trim()) ||
          val.pc_num?.trim().includes(searchParams.pc.trim())) &&
        (searchParams.func === "" ||
          val.function_name
            ?.trim()
            .toLowerCase()
            .includes(searchParams.func.trim().toLowerCase())) &&
        (searchParams.cat === "" ||
          val.cat_name
            ?.trim()
            .toLowerCase()
            .includes(searchParams.cat.trim().toLowerCase())) &&
        (searchParams.sal === "" ||
          val.min_salary?.trim().includes(searchParams.sal.trim()))
      );
    });

    setFunctions(filteredFunctions);
    setCurrentPage(0);
  };

  // Correctly map the placeholders to match state keys
  const handleInputChange = (key) => (e) => {
    setSearchParams((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  };

  const handleReset = () => {
    setFunctions(functionsTemp);
    setSearchParams({ pc: "", func: "", cat: "", sal: "" });
    setCurrentPage(0); // Reset to first page
  };

  const pageCount = useMemo(
    () => Math.ceil(functions.length / PER_PAGE),
    [functions.length]
  );

  const paginatedFunctions = useMemo(() => {
    const offset = currentPage * PER_PAGE;
    return functions.slice(offset, offset + PER_PAGE);
  }, [currentPage, functions]);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber - 1); // Adjust for zero-based index
  };

  const IconProps = {
    isIcon: true,
    width: "1vw",
    height: "1vw",
  };

  return (
    <>
      <form>
        <Header
          headerName={t("Manage functions")}
          headerClassName="myAccountCardTitle"
        />
        <div className="search-bar pb-md-2">
          <div className="row mb-2">
            <BackLink backLink="/" />
            <div className="col-8 align-self-center text-center d-block d-lg-none">
              <LabelField
                title={t("Manage functions")}
                className="myAccountCardTitle mb-0"
              />
            </div>
          </div>
          <div className="row">
            {[
              { label: "PC number", key: "pc" },
              { label: "Function name", key: "func" },
              { label: "Minimum salary", key: "sal" },
              { label: "Category name", key: "cat" },
            ].map(({ label, key }, index) => (
              <div className="col-lg-2 col-md-6 mb-3 mb-lg-0" key={index}>
                <Search
                  value={searchParams[key]}
                  placeholder={t(label)}
                  handleChange={handleInputChange(key)}
                />
              </div>
            ))}
            <div className="col-lg-3 col-12">
              <div className="d-flex align-items-center justify-content-lg-start justify-content-md-end">
                <ResetBtn handleResetClick={handleReset} />
                <SearchBtn handleSearchClick={handleSearch} />
              </div>
            </div>
          </div>
        </div>
        <div className="managepagesBg">
          <OverFlowWrapper className="table-responsive manageFunctionsTable">
            <table className="table pwa table-hover customTableProperties">
              <thead className="headSticky">
                <tr className="TableHeader">
                  <th style={{ width: "15%" }}>{t("PC number")}</th>
                  <th style={{ width: "25%" }}>{t("Function name")}</th>
                  <th style={{ width: "20%" }}>{t("Minimum salary")}</th>
                  <th style={{ width: "20%" }}>{t("Category")}</th>
                  <th style={{ width: "15%" }}>{t("Action")}</th>
                </tr>
                <TableHeaderSpace headerLength={5} />
              </thead>
              <tbody>
                {paginatedFunctions.length > 0 ? (
                  paginatedFunctions.map((result) => (
                    <tr key={result.funcn_id} className="border">
                      <td data-label={t("PC number")}>
                        {result.pc_number ? result.pc_number : result.pc_num}
                      </td>
                      <td data-label={t("Function name")}>
                        {result.function_name}
                      </td>
                      <td data-label={t("Minimum salary")}>
                        € {result.min_salary}
                      </td>
                      <td data-label={t("Category")}>{result.cat_name}</td>
                      <td className="px-2">
                        <EditIcon
                          href={`/editpc/${result.pc_unique_key || result.unique_key
                            }?fid=${result.funcn_id}`}
                        />
                        <CustomDelete
                          handleDelete={() => showPopup(result.funcn_id)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoRecords headerLength={5} />
                )}
              </tbody>
            </table>
          </OverFlowWrapper>
          {pageCount > 1 && (
            <div className="pagination justify-content-center mt-4 px-4">
              <Pagination
                currentPage={currentPage + 1}
                totalPages={pageCount}
                onPageChange={handlePageClick}
              />
            </div>
          )}
        </div>
      </form>
      {showDeletePopup && (
        <Popup
          show={showDeletePopup}
          popupActionNo={closePopup}
          popupActionYes={deleteFuncn}
          title={t("Delete function")}
          heading={t("Are you sure you want to delete this function?")}
        />
      )}
    </>
  );
};

export default ManageFunctions;
