import { AddAdministratorInterface } from "../../ManageAdministrator/AddAdministrator";
import { DefaultEmpLegal } from "../registration/Interfaces";
import { EmployeeInviteState } from "./Interfaces";

export const initialPCstate: DefaultEmpLegal = {
  id: null,
  paritaire_commitee_id: null,
  employee_type_id: null,
  function_id: null,
  minWage: "",
  actual_salary: "",
  is_actual_added: false,
  company_id: null,
};

export const initialstate: EmployeeInviteState = {
  formData: {
    user_id: 0,
    first_name: "",
    last_name: "",
    email: "",
    verify_email: "",
    phone_number: "",
    companies: [] as number[],
    default_emp_legal: [initialPCstate],
    date_of_birth: "",
    registry_number: "",
    function: "",
  },
  errors: {},
  missing_companies: [],
  not_found: false,
  fetched_companies: [],
  all_employer_companies: [],
  isDisableState: {
    "first_name": true,
    "last_name": true,
    "phone_number": true,
    "email": true,
    "date_of_birth": true,
    "function": false,
    "registry_number": true,
  },
  prev_invited_companies_count: null,
};


export const initialAdminState: AddAdministratorInterface = {
  employeeInviteState: initialstate,
  searched: false,
  checkedItems: new Set(),
  companySelectedError: '',
};