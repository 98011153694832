// components/TableHeader.tsx
import React from 'react';
import { t } from "../CentralDataMangement/translation/Translation";

export const PlanningTableHeader: React.FC = () => (
  <tr className="align-middle">
    <th></th>
    <th>{t("Employee")}</th>
    <th>{t("Statute")}</th>
    <th>{t("Function")}</th>
    <th>{t("Paritair committee")}</th>
    <th>{t("Hourly wage")}</th>
    <th>{t("Start shift")}</th>
    <th>{t("End shift")}</th>
    <th>{t("Start shift")}</th>
    <th>{t("End shift")}</th>
    <th>{t("Break Total")}</th>
    <th>{t("Total work done")}</th>
    <th>{t("Status")}</th>
    <th>{t("Action")}</th>
  </tr>
);