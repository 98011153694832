import React from "react";
import RegisterEmployeeTemplate from "../../components/Employee/registration/templates/RegisterEmployeeTemplate";
import AccessControl from "../../services/RolesAndPermissions/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "../../features/auth/AuthSlice";

const RegisterEmployee: React.FC = () => {
  const user = useSelector(selectAuth);
  const url = new URL(window.location.href);
  const updateId = url.pathname.split("/").pop()?.trim();
  const shouldRenderAccessControl =
    user.token && updateId !== "employee-my-account";

  return shouldRenderAccessControl ? (
    <AccessControl
      requiredPermissions={[
        {
          permission: "register_employee",
          create: true,
          read: true,
          update: true,
        },
      ]}
      renderNoAccess={true}
    >
      <RegisterEmployeeTemplate />
    </AccessControl>
  ) : (<RegisterEmployeeTemplate />);
};

export default RegisterEmployee;
