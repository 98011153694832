import React, { useEffect, useState } from "react";
import Header from "../common/layout/Header";
import { t } from "../CentralDataMangement/translation/Translation";
import BackLink from "../common/atoms/BackLink";
import { ApiCall } from "../../services/ApiServices";
import { useSelector } from "react-redux";
import {
  ADMIN_DETAILS_BY_COMPANY,
  EMPLOYEE_REMOVE_ADMIN,
} from "../../routes/ApiEndpoints";
import OverFlowWrapper from "../common/OverFlowWrapper";
import EmployeeDetailsCard from "../common/molecules/EmployeeDetailsCard";
import Button from "../common/atoms/Button";
import { useNavigate } from "react-router-dom";
import CustomNotify from "../common/atoms/CustomNotify";
import LabelField from "../common/atoms/LabelField";
import NoRecords from "../common/atoms/NoRecords";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import AutoCompleteWithSearch from "../common/molecules/AutoCompleteWithSearch";
import { selectEmployerCompanies } from "../../features/auth/AuthSlice";
import RemoveAdminModal from "./RemoveAdminModal";
import { ReduxState } from "../Dashboard/services/CompanyInterface";
import { Company } from "./AddAdministrator";
import AccessControl from "../../services/RolesAndPermissions/AccessControl";

const ManageAdministrator: React.FC = () => {
  const navigate = useNavigate();
  const companyId: number | undefined = useSelector(
    (state: ReduxState) => state?.auth?.currentCompany?.id
  );

  const microService = CENTRAL_DATA_MANAGEMENT_MICROSERVICE;

  // interface Company {
  //   id: number;
  //   name: string;
  //   official_name: string;
  //   profile_picture_url: string;
  //   locations: [];
  //   projects: [];
  // }

  interface Employee {
    id: number;
    first_name: string;
    last_name: string;
    locations: [];
    name: string;
    official_name: string;
    profile_picture_url: string | null;
    projects: [];
    email: string;
    companies: Company[];
  }

  interface State {
    data: Employee[];
    filteredData: Employee[];
    searchTerm: string;
    dataLength: number;
    showModal: boolean;
    employee_id: number;
    selectedCompanies: number[];
  }

  const [state, setState] = useState<State>({
    data: [],
    filteredData: [],
    searchTerm: "",
    dataLength: 0,
    showModal: false,
    employee_id: 0,
    selectedCompanies: [],
  });

  const employerCompanies: Company[] | undefined = useSelector(selectEmployerCompanies);

  const fetchInfo = async (searchTerm: string = "") => {
    const url = `${ADMIN_DETAILS_BY_COMPANY}?company_id=${companyId}&q=${searchTerm}`;
    try {
      const response = await ApiCall?.getService(url, "GET", microService, true);
      if (response?.status === "success") {
        setState((prevState) => ({
          ...prevState,
          data: response?.data as Employee[],
          dataLength: response?.data?.length || 0,
        }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSearchChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      searchTerm: value,
    }));
  };

  const filteredData: Employee[] = state?.data?.filter((employee: Employee) => {
    const searchTerm = state?.searchTerm?.toLowerCase() || "";
    const firstName = employee?.first_name?.toLowerCase() || "";
    const lastName = employee?.last_name?.toLowerCase() || "";
    const email = employee?.email?.toLowerCase() || "";
    const fullName = `${firstName} ${lastName}`;

    return (
      firstName?.includes(searchTerm) ||
      lastName?.includes(searchTerm) ||
      email?.includes(searchTerm) ||
      fullName?.includes(searchTerm)
    );
  });

  useEffect(() => {
    if (companyId !== null) {
      fetchInfo();
    }
  }, [companyId]);

  const close = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
    }));
  };

  const updateAdminCompanies = async () => {
    const url = `${EMPLOYEE_REMOVE_ADMIN}`;
    const uncheckedCompanies = Array?.from(employerCompanies ?? [])?.filter(
      (company: Company) => !checkedItems?.has(company?.id)
    )?.map((company: Company) => company?.id);

    const dataObj = {
      company_id: uncheckedCompanies,
      user_id: state?.employee_id,
    };

    try {
      if (state?.dataLength > 0) {
        const response = await ApiCall?.service(
          url,
          "POST",
          dataObj,
          true,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );

        if (response?.status === "success") {
          const updatedData = state?.data?.map((employee) => {
            if (employee?.id === state?.employee_id) {
              return {
                ...employee,
                companies: employee?.companies?.filter(
                  (company) => !uncheckedCompanies?.includes(company?.id)
                ),
              };
            }
            return employee;
          });

          setState((prevState) => ({
            ...prevState,
            data: updatedData,
            selectedCompanies: uncheckedCompanies,
          }));

          CustomNotify({
            message: t("Administrator updated successfully"),
            type: "success",
            autoClose: 3500,
          });
          close();
        } else {
          CustomNotify({
            message: t("Failed to update admin companies"),
            type: "error",
            autoClose: 3500,
          });
        }
      } else {
        CustomNotify({
          message: t("At least one administrator should be present"),
          type: "warning",
          autoClose: 3500,
        });
      }
    } catch (err) {
      console.error(err);
      CustomNotify({
        message: t("Failed to update admin companies"),
        type: "error",
        autoClose: 3500,
      });
    }
  };

  const [initialCompanies, setInitialCompanies] = useState<Company[]>([]);

  const clickEdit = (employee_id: number) => {
    const employeeCompanies: number[] =
      state?.data?.find((emp) => emp?.id === employee_id)?.companies?.map(
        (company) => company?.id
      ) || [];

    setInitialCompanies(
      (employerCompanies ?? [])?.filter((company: Company) =>
        employeeCompanies?.includes(company?.id)
      )
    );

    setState((prevState) => ({
      ...prevState,
      showModal: true,
      employee_id,
      selectedCompanies: employeeCompanies,
    }));
    handleEditClick(employee_id);
  };

  const [checkedItems, setCheckedItems] = useState<Set<number>>(new Set());

  const toggleCompanySelection = (company: Company, isChecked: boolean) => {
    setCheckedItems((prev) => {
      const updatedCheckedItems = new Set(prev);
      if (isChecked) {
        updatedCheckedItems?.add(company?.id);
      } else {
        updatedCheckedItems?.delete(company?.id);
      }
      return updatedCheckedItems;
    });
  };

  const handleEditClick = (userId: number) => {
    const user = state?.data?.find((user) => user?.id === userId);
    if (user) {
      const companyIds = new Set(
        user?.companies?.map((company) => company?.id)
      );
      setCheckedItems(companyIds);
    }
  };
  return (

    <div>
      <Header
        headerName={t("Manage administrators")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-md-2">
        <div className="row">
          <div className="col-8 align-self-center text-center d-block d-lg-none">
            <LabelField
              title={t("Manage administrators")}
              className="myAccountCardTitle mb-0"
            />
          </div>
          <div className="mb-3 col-6 align-self-center ms-auto">
            <div className="col-12 text-end">
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "administrators",
                    create: true,
                  },
                ]}
                renderNoAccess={true}
              >
                <Button
                  title={t("Add administrator")}
                  className="searchBtn px-3"
                  handleClick={() => navigate("/add-administrator")}
                />
              </AccessControl>
            </div>
          </div>
        </div>
      </div>
      <AccessControl
        requiredPermissions={[
          {
            permission: "administrators",
            read: true,
          },
        ]}
        renderNoAccess={true}
      >
        <div className="createpagesBg manageAdministratorPage">
          <LabelField title={t("Administrators:")} />
          {/* Search Input and Button */}
          <div className="row mb-4">
            <div className="col-lg-6 col-md-8">
              <AutoCompleteWithSearch
                placeholder={t("Search employee")}
                value={state?.searchTerm}
                handleChange={handleSearchChange}
              />
            </div>
          </div>

          <OverFlowWrapper className={`manageAdministratorWrapper pt-1 ${filteredData?.length > 0 ? "" : "container-fluid"}`}>
            <div className={`${filteredData?.length > 0 ? "d-grid grid-4 grid-md-3" : "row"}`} style={{ gap: "1vw" }}>
              {filteredData?.length > 0 ? (
                filteredData
                  ?.filter((item) => item?.companies?.length > 0 &&
                    item?.companies?.some(company => company?.id === companyId)
                  )
                  ?.map((item) => (
                    <React.Fragment key={item?.id}>
                      <div>
                        {item?.companies?.length > 0 &&
                          <EmployeeDetailsCard
                            employee_data={item}
                            cardConfig={{
                              isRelatedCompany: true,
                              cardClassName:
                                "text-break text-start d-flex align-items-center justify-content-start",
                              employeeInformationClass: "planningEmployeeDetails",
                              cardMainClassName:
                                "employeeDetailsCardPlanning colorPrimary position-relative h-100",
                              iconClassName:
                                "text-end cardAdministratorEditIcon cardEditIcon position-absolute top-0 end-0 cursor-pointer",
                              isCardIcon: true,
                              iconName: "EditIcon",
                              favouriteIconName: "starIcon",
                              noFavouriteIconName: "whiteStarIcon",
                            }}
                            handleClick={() => clickEdit(item?.id)}
                            permission_name = "administrators"
                          />
                        }
                      </div>
                    </React.Fragment>
                  ))
              ) : (
                <NoRecords
                  message={t("No administrator available")}
                  className="col-12"
                  isDiv={true}
                />
              )}

            </div>
          </OverFlowWrapper>
        </div>
      </AccessControl>
      {state?.showModal && (
        <RemoveAdminModal
          close={close}
          Company={state?.data?.find((emp) => emp?.id === state?.employee_id)?.companies || []}
          updateAdminCompanies={updateAdminCompanies}
          companies={initialCompanies}
          checkedItems={checkedItems}
          handleCheckBoxClick={toggleCompanySelection}
        />
      )}
    </div>

  );
};

export default ManageAdministrator;