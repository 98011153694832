import React, { useEffect, useState } from "react";
import Image from "../common/atoms/Image";
import Icon from "../../Icon";
import "./css/weekCalender.css";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { ApiCall } from "../../services/ApiServices";
import { PLANNING_OVERVIEW } from "../../routes/ApiEndpoints";
import UserImage from "../../static/images/User.svg";
import {
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
  PLANNING_MICROSERVICE,
} from "../../Constants";
import { formatDateTime, formatDateTimeAny } from "../common/utlis/dateHelper";
import { getWeekDays } from "./Services/PlanningTimingHelper";
import NoRecords from "../common/atoms/NoRecords";
import CancelPlanningPopup from "./CancelPlanningPopup";
import CustomNotify from "../common/atoms/CustomNotify";
import EditPlanningPopup from "./EditPlanningPopup";
import StatusCircle from "../common/atoms/StatusCircle";
import TextEllipsis from "../common/molecules/TextEllipsis";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";

interface Timing {
  shift_id: number;
  shift_starttime: string;
  shift_endtime: string;
  shift_sequence: number;
}

interface EventData {
  length: number;
  map(arg0: (employee: EventData) => React.JSX.Element): React.ReactNode;
  id: number;
  employee_id: number;
  name: string;
  employee_profile_pic: string;
  employee_type: string;
  employee_type_id: number;
  function_name: string;
  function_id: number;
  pc_id: number;
  pc_name: string;
  salary: string;
  pdate: string;
  planning_status: string | null;
  failed_reason: string | null;
  location_name: string;
  location_id: number;
  total_hrs: number;
  schedule: Timing[];
}

interface WeeklyCalendarProps {
  companyId: string;
  selectedLocation?: {
    // Add prop for selected location
    address_id: number;
    id: number;
    location_image_id: number;
    name: string;
  };
  startDate?: Date;
  data?: { [key: string]: EventData[] }; // Updated data format to an array of EventData
}

const WeeklyCalendar: React.FC<WeeklyCalendarProps> = ({
  startDate,
  companyId,
  selectedLocation,
  data,
}) => {
  const [weekData, setWeekData] = useState<EventData[]>([]);
  const [cancel, setCancel] = useState<EventData | null>(null);
  const [edit, setEdit] = useState<EventData | null>(null);

  const weekDays = getWeekDays(startDate || new Date());
  /**Auto refresh */
  const [autorefresh, setAutorefresh] = useState(false);

  setTimeout(() => {
    setAutorefresh(!autorefresh);
  }, 60000);
  const fetchData = async () => {
    try {
      let dataObj = {
        company_id: companyId,
        location_id: selectedLocation?.id,
        start_date: formatDateTime(weekDays[0]),
        end_date: formatDateTime(weekDays[weekDays.length - 1]),
      };
      let result = await ApiCall.service(
        PLANNING_OVERVIEW,
        "POST",
        dataObj,
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (result.status === "success") {
        setWeekData(result.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [startDate, selectedLocation, autorefresh]);

  // Get today's date
  const today = new Date();
  const currentDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const textWidth = useResponsiveValue("100%", "100%", "6vw");
  const iconStyle = useResponsiveValue("5vw", "2.5vw", "0.8vw");
  const IconProps = {
    isIcon: true,
    width: iconStyle,
    height: iconStyle,
    cursor: "pointer",
  };
  return (
    <div className="d-grid overflow-auto">
      <div className="weekHeader">
        <div className="weekHeaderRow d-grid">
          {weekDays.map((day, index) => {
            const isToday = day.toDateString() === currentDay.toDateString();
            return (
              <div
                key={index}
                className={`weekDay text-center ${isToday ? "currentDay" : ""}`}
              >
                <div className="dayName">
                  {day.toLocaleDateString("en-US", { weekday: "long" })}
                </div>
                <div className="dayDate">
                  {day.getDate()}{" "}
                  {day.toLocaleDateString("en-US", { month: "long" })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="weekData d-grid">
        {weekDays.map((dateKey: any, index) => {
          const date = formatDateTimeAny(dateKey);
          const dayData = weekData[date]; // Get the data for the specific day
          const isToday =
            weekDays[index].toDateString() === currentDay.toDateString(); // Check if it's today

          return (
            <div key={index}>
              {dayData && dayData.length > 0 ? (
                dayData.map((employee: EventData) => (
                  <div
                    key={employee.id}
                    className="dayDataContent dayDataItem p-0 pb-2 p-3"
                  >
                    <div className="row rowOne">
                      <div className="col-lg-10 pe-0">
                        <div className="row">
                          <div className="col-lg-3 pe-0">
                            <Image
                              src={employee.employee_profile_pic ?? UserImage}
                              imageBorderRadius="50%"
                              className="weeklyCalendarImage"
                            />
                          </div>
                          <div className="col-lg-9">
                            <div className="planningDetails colorPrimary">
                              <div
                                className="eventName textEllipsis textEllipsisNameWeely"
                                title={employee.name}
                              >
                                {employee.name}
                              </div>
                              <div className="align-self-end text-white poppins-medium weekStatusColor">
                                <StatusCircle
                                  status={employee.planning_status}
                                  failedReason={employee.failed_reason}
                                />
                              </div>
                              <div className="eventStatus textEllipsis textEllipsisNameWeely">
                                <TextEllipsis title={employee.employee_type} label={employee.employee_type} width={textWidth} />
                              </div>
                              <div className="eventFunction textEllipsis textEllipsisNameWeely">
                                <TextEllipsis title={employee.function_name} label={employee.function_name} width={textWidth} />
                              </div>
                              <div className="eventPC textEllipsis textEllipsisNameWeely">
                                <TextEllipsis title={`${employee.pc_name} | ${employee.salary}`} label={`${employee.pc_name} | ${employee.salary}`} width={textWidth} />
                              </div>
                              <div className="eventLocation textEllipsis textEllipsisNameWeely" >
                                <TextEllipsis title={employee.location_name} label={employee.location_name} width={textWidth} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {employee.pdate >= formatDateTime(new Date()) && ( // Check if emp.pdate is not less than the current date
                        <div className="col-lg-2 align-self-center d-flex align-items-center justify-content-center d-lg-block my-2 my-lg-0">
                          <div
                            data-bs-toggle="tooltip"
                            title={t("Clone")}
                            onClick={() => {
                              CustomNotify({
                                type: "warning",
                                message: t(
                                  "Clone planning functionality not yet done. Please dont check this."
                                ),
                              });
                            }}
                            className="actionWrapper me-3 me-lg-0"
                          >
                            <Icon name="CloneIcon" {...IconProps} />
                          </div>
                          <div
                            data-bs-toggle="tooltip"
                            title={t("Edit")}
                            onClick={() => setEdit(employee)}
                            className="actionWrapper me-3 me-lg-0"
                          >
                            <Icon name="EditIcon" {...IconProps} />
                          </div>
                          <div
                            data-bs-toggle="tooltip"
                            title={t("Delete")}
                            onClick={() => setCancel(employee)}
                            className="removeUploadedFile actionWrapper"
                          >
                            <Icon name="RemoveIcon" {...IconProps} />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row weeklyPlannedTimingWrapper">
                      <div className="col-8">
                        {employee.schedule.map((timing) => (
                          <div className="row" key={timing.shift_id}>
                            <div className="col-6 pe-0">
                              <div className="plannedTiming">
                                {timing.shift_starttime}
                              </div>
                            </div>
                            <div className="col-6 pe-0">
                              <div className="plannedTiming">
                                {timing.shift_endtime}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="col-4">
                        <div className="plannedOverallTiming">
                          {employee.total_hrs} hrs
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <NoRecords
                  isDiv={true}
                  message={t("No data for this day")}
                  className="dayDataContent noRecordWeek"
                />
              )}
            </div>
          );
        })}
      </div>
      {cancel != null && (
        <CancelPlanningPopup
          employeeData={cancel}
          clearPopup={() => {
            setCancel(null);
            fetchData();
          }}
        />
      )}
      {edit != null && (
        <EditPlanningPopup
          employeeData={edit}
          clearPopup={() => {
            setEdit(null);
            fetchData(); // Refresh the overview after canceling
          }}
        />
      )}
    </div>
  );
};

export default translate(WeeklyCalendar);
