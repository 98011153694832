import React, { useState } from "react";
import Image from "../common/atoms/Image";
import { t } from "../common/atoms/translation";
import UserImage from "../../static/images/User.svg";
import "./css/contracts.css";
import TableHeaderSpace from "../common/atoms/TableHeaderSpace";
import { format } from "date-fns";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";
import useWindowWidth from "../common/atoms/WindowWidth";
import CustomActionIcon from "../common/molecules/Actions/CustomActionIcon";

interface ContractTableProps {
  contract_id?: number;
  user_first_name?: string;
  user_last_name?: string;
  user_profile_picture_url?: string;
  from_date?: Date | string;
  to_date?: Date | string;
  file_url?: string;
  viewContract?: (contractId: number) => void;
  downloadContract?: (contractId: number) => void;
}

const ContractTable: React.FC<ContractTableProps> = ({
  contract_id,
  user_first_name,
  user_last_name,
  user_profile_picture_url,
  from_date,
  to_date,
  viewContract,
  downloadContract
}) => {

  const imageStyle = useResponsiveValue("8vw", "4vw", "2vw");
  const isDesktop = useWindowWidth(1024);
  return (
    <>

      <tr className="spaced-row">
        <td className="p-0 align-middle text-center">
          <Image
            src={user_profile_picture_url ?? UserImage}
            imageWidth={imageStyle}
            imageHeight={imageStyle}
            imageBorderRadius="50%"
          />
        </td>
        <td className="align-middle p-2 text-center text-lg-start">{user_first_name} {user_last_name}</td>
        <td className="align-middle p-2 text-center text-lg-start">
          {from_date && to_date
            ? `${format(new Date(from_date), "dd/MM/yyyy")} to ${format(new Date(to_date), "dd/MM/yyyy")}`
            : "N/A"}
        </td>
        <td className="align-middle p-2 text-center text-lg-start">
          <div className="d-lg-flex">
            <CustomActionIcon handleClick={contract_id ? () => viewContract?.(contract_id) : () => { }} title={t("View")} iconName="viewIcon" className="me-2 secondaryColorHoverEffect" />
            <CustomActionIcon handleClick={contract_id ? () => downloadContract?.(contract_id) : () => { }} title={t("Download")} iconName="downloadIcon" />
          </div>
        </td>
      </tr>
      {isDesktop &&
        <TableHeaderSpace headerLength={4} />
      }
    </>
  );
};

export default ContractTable;
