import { t } from "../../components/CentralDataMangement/translation/Translation";
import CustomNotify, { NotifyType } from "../../components/common/atoms/CustomNotify";
import { AxiosCall } from "../AxiosService";


const CommonServices = {
    downloadFile: async (fileUrl: string, fileNumber: string | number | null, fileType: string | null) => {
        try {
            const response = await fetch(fileUrl);
            const blob = await response.blob();

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${fileType}_${fileNumber}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error('Error downloading the document:', error);
        }
    },

    printQRcode: async (fileUrl: string) => {
        try {
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
    
            // Create a hidden iframe element
            const iframe = document.createElement('iframe');
            iframe.style.position = 'absolute';
            iframe.style.width = '0px';
            iframe.style.height = '0px';
            iframe.style.border = 'none';
    
            // Add a container for styling the iframe content
            const iframeContainer = `
                <html>
                    <head>
                        <style>
                            body {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 100vh;
                                margin: 0;
                            }
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        </style>
                    </head>
                    <body>
                        <img src="${blobUrl}" alt="QR Code" />
                    </body>
                </html>
            `;
    
            iframe.srcdoc = iframeContainer;
            document.body.appendChild(iframe);
    
            // Wait for the iframe to load and then trigger print
            iframe.onload = () => {
                iframe.contentWindow?.focus();
                iframe.contentWindow?.print();
    
                // Clean up the iframe after the print dialog interaction
                const handleAfterPrint = () => {
                    document.body.removeChild(iframe);
                    URL.revokeObjectURL(blobUrl);
                    window.removeEventListener('afterprint', handleAfterPrint);
                };
                window.addEventListener('afterprint', handleAfterPrint);
            };
        } catch (error) {
            console.error('Error fetching PDF for print:', error);
        }
    },
    
    printFile: async (fileUrl: string) => {
        try {
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);

            // Create a hidden iframe element
            const iframe = document.createElement('iframe');
            iframe.style.position = 'absolute';
            iframe.style.width = '0px';
            iframe.style.height = '0px';
            iframe.style.border = 'none';
            iframe.style.visibility = 'hidden';
            iframe.src = blobUrl;
            document.body.appendChild(iframe);

            // Wait for the iframe to load and then trigger print
            iframe.onload = () => {
                iframe.contentWindow?.focus();
                iframe.contentWindow?.print();

                // Clean up the iframe after the print dialog interaction
                const handleAfterPrint = () => {
                    document.body.removeChild(iframe);
                    URL.revokeObjectURL(blobUrl);
                    window.removeEventListener('afterprint', handleAfterPrint);
                };

                window.addEventListener('afterprint', handleAfterPrint);
            };
        } catch (error) {
            console.error('Error fetching PDF for print:', error);
        }
    }
};
export default CommonServices;

export const formatRegistryNumber = (ssn: string) => {
    if (!ssn || !/^\d{11}$/.test(ssn)) {
        // Return the original input if ssn is null, empty, or doesn't match the required 11-digit pattern
        return ssn;
    }
    const formattedNumber: string = ssn.replace(
        /(\d{2})(\d{2})(\d{2})(\d{3})(\d{2})/,
        "$1.$2.$3-$4.$5"
    );
    return formattedNumber;
};

export const idMask = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.maxLength = 15; // Maximum length according to the mask pattern
    let value = e.target.value;

    // Remove all non-digit characters
    value = value.replace(/\D/g, "");

    // Apply the formatting: XX.XX.XX-XXX.XX
    if (value.length > 2) value = value.slice(0, 2) + "." + value.slice(2);
    if (value.length > 5) value = value.slice(0, 5) + "." + value.slice(5);
    if (value.length > 8) value = value.slice(0, 8) + "-" + value.slice(8);
    if (value.length > 12) value = value.slice(0, 12) + "." + value.slice(12);

    // Set the masked value back to the input field
    e.target.value = value;
};

export const formatDate = (date: any) => {
    if (!date) return null;
    const formattedDate = date
        ? new Date(
            new Date(date).getTime() -
            new Date(date).getTimezoneOffset() * 60 * 1000
        )
            .toISOString()
            .slice(0, 10)
        : null;
    return formattedDate;
};

export const uploadFile = async (
    file: File,
    name: string,
    fileType: number,
    path: string
) => {
    const fileData = new FormData();
    fileData.append("file", file);
    fileData.append("name", name);
    fileData.append("file_type", fileType.toString());

    try {
        const response = await AxiosCall.fileUploadService(fileData, path);
        return response;
    } catch (error) {
        console.error("Error uploading file:", error);
        return null;
    }
};

export const showNotification = (status: NotifyType, message: string) => {
    CustomNotify({
        type: status,
        message: t(message),
    });
};