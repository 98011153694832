import React from "react";
import { BsPrinter } from "react-icons/bs";
import Button from "../atoms/Button";
import CommonServices from "../../../services/common/CommonServices";
import CustomActionIcon from "./Actions/CustomActionIcon";
import { t } from "../../CentralDataMangement/translation/Translation";

interface PrintButtonProps {
  fileUrl: string;
  isQRcode?: boolean;
}

const PrintFile: React.FC<PrintButtonProps> = ({
  fileUrl,
  isQRcode = false,
}) => {
  const handlePrint = () => {
    isQRcode
      ? CommonServices.printQRcode(fileUrl)
      : CommonServices.printFile(fileUrl);
  };
  return (
    // <CustomActionIcon handleClick={} title={t("Print")} iconName="printIcon" />
    <Button
      handleClick={handlePrint}
      className="icon-btn btn btn-link text-white p-1 fs-6"
      title={<BsPrinter />}
    />
  );
};

export default PrintFile;
