import React from "react";
import { t } from "../../../common/atoms/translation";
import ToggleButton from "../../../common/atoms/ToggleButton";
import LabelField from "../../../common/atoms/LabelField";
import { EmployeeAgreementsProps } from "../../registration/Interfaces";

const EmployeeAgreements: React.FC<EmployeeAgreementsProps> = ({
  state,
  handleToggle,
}) => {
  return (
    <div className="row">
      <div className="mb-3">
        <div className="col-12 d-flex align-items-center">
          <LabelField
            title={
              <>
                {t("I agree to the ")}
                <a
                  href="https://www.absoluteyou.be/nl/privacybeleid"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="colorPrimary linkText"
                >
                  {t("GDPR data")}
                </a>
              </>
            }
            className="mb-0 me-3 colorPrimary poppins-medium"
            isMandatory
          />
          <ToggleButton
            value={state.user_terms.is_gdpr}
            onToggle={handleToggle("user_terms", "is_gdpr")}
          />
        </div>
        {state.errors["user_terms.is_gdpr"] && (
          <div className="text-danger">
            {state.errors["user_terms.is_gdpr"]}
          </div>
        )}
      </div>
      <div className="mb-3">
        <div className="col-12 d-flex align-items-center">
          <LabelField
            title={
              <>
                {t(
                  "The temporary employment agency and the candidate temporary worker confirm the intention to enter into one or more employment contract(s) for temporary work. Letter of intent "
                )}
                <a
                  href="https://www.absoluteyou.be/nl/wat-uitzendarbeid"
                  className="colorPrimary linkText"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t(
                    "to conclude one or more employment contracts for temporary work."
                  )}
                </a>
              </>
            }
            className="mb-0 me-3 colorPrimary poppins-medium"
            isMandatory
          />
          <ToggleButton
            value={state.user_terms.is_temp_employement}
            onToggle={handleToggle("user_terms", "is_temp_employement")}
            toggleClassName="d-inline-block ms-3"
          />
        </div>
        {state.errors["user_terms.is_temp_employement"] && (
          <div className="text-danger">
            {state.errors["user_terms.is_temp_employement"]}
          </div>
        )}
      </div>
      <div className="mb-3">
        <div className="col-12 d-flex align-items-center">
          <LabelField
            title={t("I agree to digitally sign contracts")}
            className="mb-0 me-3 colorPrimary poppins-medium"
            isMandatory
          />
          <ToggleButton
            value={state.user_terms.is_contract_sign}
            onToggle={handleToggle("user_terms", "is_contract_sign")}
          />
        </div>
        {state.errors["user_terms.is_contract_sign"] && (
          <div className="text-danger">
            {state.errors["user_terms.is_contract_sign"]}
          </div>
        )}
      </div>
      <div>
        <div className="col-12 d-flex align-items-center">
          <LabelField
            title={
              <>
                {t("I agree to the ")}
                <a
                  href="https://www.absoluteyou.be/nl/disclaimer-nl"
                  className="colorPrimary linkText"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("terms and conditions")}
                </a>
              </>
            }
            className="mb-0 me-3 colorPrimary poppins-medium"
            isMandatory
          />
          <ToggleButton
            value={state.user_terms.is_term_condition}
            onToggle={handleToggle("user_terms", "is_term_condition")}
          />
        </div>
        {state.errors["user_terms.is_term_condition"] && (
          <div className="text-danger">
            {state.errors["user_terms.is_term_condition"]}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeAgreements;
