import React, { ReactNode } from "react";

interface AccordionCustomProps {
  accordionTitle?: string;
  children?: ReactNode;
  accordionTitleClassName?: string;
  accordionButton?: string;
  isOpen?: boolean;
  accordionId?: string;
}

const AccordionCustom: React.FC<AccordionCustomProps> = ({
  accordionTitle,
  children,
  accordionTitleClassName,
  accordionButton,
  isOpen = false,
  accordionId = "flush-collapseOne"
}) => {
  return (
    <>
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id={`flush-heading-${accordionId}`}>
            <button
              className={`${accordionButton} accordion-button ${isOpen ? "" : "collapsed"}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#${accordionId}`}
              aria-expanded={isOpen ? "true" : "false"}
              aria-controls={accordionId}
            >
              <div className={accordionTitleClassName}>{accordionTitle}</div>
            </button>
          </h2>
          <div
            id={accordionId}
            className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
            aria-labelledby={`flush-heading-${accordionId}`}
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccordionCustom;
