import React from "react";
import LabelField from "./LabelField";
import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import Image from "./Image";
import defaultUserImage from "../../../static/images/User.svg";
import Icon from "../../../Icon";
import "../../ManageLocationManager/css/locationManager.css";
import OverFlowWrapper from "../OverFlowWrapper";
import { useResponsiveValue } from "./ResponsivWidth";

interface Manager {
  id: number;
  name: string;
  email: string;
  profile_pic: string | null;
  since: string;
}

interface LocationManagersListProps {
  managers: Manager[];
  onRemove: (id: number) => void;
}

const LocationManagersList: React.FC<LocationManagersListProps> = ({
  managers,
  onRemove,
}) => {
  const IconStyle = useResponsiveValue("5vw", "3vw", "1vw")
  
  const IconProps = {
    isIcon: true,
    width: IconStyle,
    height: IconStyle,
  };
  return (
    <div>
      <LabelField title={t("Location managers:")} />
      <OverFlowWrapper className="grid-4  d-grid grid-md-3 locationUsersHeight">
        {managers?.map((manager, index) => (
          <div className="employeeDetailsCardPlanning locationManagerCards">
            <div title={t("Remove")} onClick={() => onRemove(manager.id)} className="removeUploadedFile sec text-end cursor-pointer">
              <Icon name="RemoveIcon" {...IconProps} />
            </div>
            <div className="d-flex align-items-center" style={{ gap: "1vw" }}>
              <div>
                {manager.profile_pic ? (
                  <Image
                    src={manager.profile_pic}
                    title={`${manager.name}'s profile`}
                    className="locationManagerProfile"
                  />
                ) : (
                  <Image src={defaultUserImage} title="Default profile" className="locationManagerProfile"
                  />
                )}
              </div>
              <div>
                <div> {manager.name}</div>
                <div> {manager.email}</div>
                <div> {t("Location manager since")}:
                  {new Date(manager.since).toLocaleDateString()}</div>
              </div>
            </div>
          </div>
        ))}
      </OverFlowWrapper>
    </div>
  );
};

export default translate(LocationManagersList);
