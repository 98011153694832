import React, { MouseEventHandler } from "react";
import Icon from "../../../Icon";
import { t } from "../../CentralDataMangement/translation/Translation";
import Button from "./Button";
import { useResponsiveValue } from "./ResponsivWidth";

interface props {
  handleResetClick?: MouseEventHandler<HTMLButtonElement>;
}
const ResetBtn: React.FC<props> = ({
  handleResetClick
}) => {
  const iconWidth = useResponsiveValue("5vw", "3vw", "1vw");
  return (
    <Button
      title=""
      type="reset"
      handleClick={handleResetClick}
      className="resetBtn me-3"
      toolTipName={t("Reset")}
    >
      <span>
        <Icon isIcon={true} width={iconWidth} height={iconWidth} name="refreshIcon" />
      </span>
    </Button>
  );
};

export default ResetBtn;
