import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"; // Import useSelector and useDispatch
import ModalPopup from "../common/atoms/ModalPopup";
import { ApiCall } from "../../services/ApiServices";
import { CANCEL_PLANNING, EDIT_PLANNING } from "../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE, SUCCESS } from "../../Constants";
import CustomNotify from "../common/atoms/CustomNotify";
import { selectPCData } from "../../features/planning/employeeSlice";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import InputTextFieldWithLabel from "../common/molecules/InputTextFieldWithLabel";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { fetchPcInfo } from "./Services/PlanningServices";
import { RootState } from "../../store";
import Icon from "../../Icon";
import { formatTimeInput } from "./Services/PlanningTimingHelper";
import { fetchSalary, formatEuroSalary, formatFloatSalary, updatesalaryFormat } from "./Services/PlanningSalaryHelper";
import { validateSalary } from "./Validations/PlanningScheduleValidation";
import CustomAlert from "../common/atoms/CustomAlert";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";
import { selectAuth } from "../../features/auth/AuthSlice";
import { formatDateDMY } from "../common/utlis/dateHelper";

interface Timing {
  shift_id: number;
  shift_starttime: string;
  shift_endtime: string;
  shift_sequence: number;
}

interface EventData {
  length: number;
  id: number;
  employee_id: number;
  name: string;
  employee_profile_pic: string;
  employee_type: string;
  employee_type_id: number;
  function_name: string;
  planning_id: number;
  function_id: number;
  pc_id: number;
  pc_name: string;
  salary: string;
  pdate: string;
  planning_status: string | null;
  failed_reason: string | null;
  location_name: string;
  location_id: number;
  total_break: number;
  total_hrs: number;
  new_salary?: string;
  schedule: Timing[];
  time_registration: TimeRegistration[];
}
interface TimeRegistration {
  end_time: string;
  sequence: string;
  start_time: string;
  time_registration_id: number;
  total_break: string;
  total_count: string;
}

interface FunctionOptions {
  label: string;
  value: number;
  salary?: string;
}

interface PcAges {
  id: number;
  pc_id: number;
  age: number;
  min_sal_percent: string;
  status: boolean;
  max_hour: string;
}

interface CategoryOptions {
  category_name: string;
  category_id: number;
  min_salary: string;
  label: string;
  value: number;
  functions: FunctionOptions[];
}

interface PcOptions {
  id: number;
  pc_number: number;
  pc_name: string;
  student_cat: string;
  flex_salary: string;
  pc_max_age: number;
  label: string;
  value: number;
  employee_types: FunctionOptions[];
  pc_ages: PcAges[];
  categories: CategoryOptions[];
  functions: FunctionOptions[];
}
interface ValidationErrors {
  timings: {
    [key: number]: {
      shift_starttime?: string;
      shift_endtime?: string;
    };
    total?: string;
  };
  functionError?: string;
  salaryError?: string;
  salaryWarning?: string;
}

interface EditPlanningPopupProps extends EventData {
  employeeData: EventData; // Include 'employeeData' as a prop
  clearPopup: () => void;
}

const EditPlanningPopup: React.FC<EditPlanningPopupProps> = ({
  employeeData: initialEmployeeData,
  clearPopup,
}) => {
  // Add new state for employee data
  const [employeeData, setEmployeeData] =
    useState<EventData>(initialEmployeeData); // Redux selectors
  const state = useSelector((state: RootState) => state);
  const companyId = useSelector((state: any) => state.auth.currentCompany?.id);
  const pcData = useSelector(selectPCData);
  const dispatch = useDispatch();
  const iconStyle = useResponsiveValue("5vw", "3vw", "1vw")

  // States
  const [showModal, setShowModal] = useState(true);
  const [selectedPc, setSelectedPc] = useState<PcOptions | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [new_salary, setNewSalary] = useState<string>(
    employeeData.new_salary ?? ""
  );
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({
    timings: {},
    salaryError: "",
    functionError: "",
    salaryWarning: "",
  });

  useEffect(() => {
    fetchPcInfo(companyId, dispatch, state);
  }, [companyId]);

  useEffect(() => {
    if (pcData) {
      const defaultPc = pcData.find(
        (pc: PcOptions) => pc.id === employeeData.pc_id
      );
      setSelectedPc(defaultPc || null);
    }
  }, [pcData, employeeData]);

  const handleCloseModal = () => {
    clearPopup();
    setShowModal(false);
  };
  // Update handleAddShift to use state
  const handleAddShift = (date: string) => {
    const newTiming: Timing = {
      shift_id: -999,
      shift_starttime: "",
      shift_endtime: "",
      shift_sequence: employeeData.schedule.length + 1,
    };

    setEmployeeData((prev) => ({
      ...prev,
      schedule: [...prev.schedule, newTiming],
    }));
  };

  // Helper function to convert time string to minutes
  const timeToMinutes = (timeString: string): number => {
    if (!timeString) return 0;
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
  };

  // Calculate duration between two times in hours
  const calculateDuration = (startTime: string, endTime: string): number => {
    const startMinutes = timeToMinutes(startTime);
    const endMinutes = timeToMinutes(endTime);
    let duration = endMinutes - startMinutes;
    if (duration < 0) duration += 24 * 60; // Handle overnight shifts
    return duration / 60; // Convert to hours
  };

  // Replace the existing validateTimings function with this updated version:
  const validateTimings = (): boolean => {
    const errors: ValidationErrors = {
      functionError: validationErrors.functionError,
      salaryError: validationErrors.salaryError,
      timings: {}
    };
    let isValid = true;
    if (errors.salaryError != '') {
      isValid = false;
    }
    if (employeeData.new_salary != undefined && employeeData.new_salary && formatFloatSalary(employeeData.new_salary) > 100) {
      errors.salaryError = t("Salary cannot exceed 100 euros");
      isValid = false;
    }

    // First validate that no individual fields are empty
    employeeData.schedule.forEach((timing, index) => {
      errors.timings[index] = {};

      // Check for empty fields
      if (!timing.shift_starttime) {
        errors.timings[index].shift_starttime = t("Start time is required");
        isValid = false;
      }

      if (!timing.shift_endtime) {
        errors.timings[index].shift_endtime = t("End time is required");
        isValid = false;
      }
    });

    // If all fields are filled, check total duration
    if (isValid) {
      let totalHours = 0;

      // Calculate total hours across all shifts
      employeeData.schedule.forEach((timing) => {
        const shiftDuration = calculateDuration(
          timing.shift_starttime,
          timing.shift_endtime
        );
        totalHours += shiftDuration;
      });

      // Check if total hours exceed 16
      if (totalHours > 16) {
        // Add error to all shifts
        employeeData.schedule.forEach((_, index) => {
          errors.timings.total =
            t("Total shift duration cannot exceed 16 hours (Current: ") +
            totalHours.toFixed(2) +
            t(" hours)");
        });
        isValid = false;
      }
    }

    setValidationErrors(errors);
    return isValid;
  };

  console.log(employeeData.time_registration);
  // Update handleShiftChange to use state
  const handleShiftChange = (
    date: string,
    index: number,
    field: string,
    value: string
  ) => {
    const formattedValue = formatTimeInput(value);
    setEmployeeData((prev) => {
      const updatedTimings = [...prev.schedule];

      // Update start time or end time based on the field
      if (field === "start_time") {
        updatedTimings[index].shift_starttime = formattedValue;

        // Clear start time error for this shift
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          schedule: {
            ...prevErrors.timings,
            [index]: {
              ...prevErrors.timings[index],
              shift_starttime: "", // Reset the error
            },
            total: "", // Also reset any total error
          },
        }));
      } else if (field === "end_time") {
        updatedTimings[index].shift_endtime = formattedValue;

        // Clear end time error for this shift
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          timings: {
            ...prevErrors.timings,
            [index]: {
              ...prevErrors.timings[index],
              shift_endtime: "", // Reset the error
            },
            total: "", // Also reset any total error
          },
        }));
      }

      return {
        ...prev,
        schedule: updatedTimings,
      };
    });
  };

  const handleChange = async (field: string, value: any) => {
    // Set the new employee data based on field and value
    setEmployeeData((prev) => ({
      ...prev,
      employee_type_id:
        field === "employee_type" ? value.value : prev.employee_type_id,
      function_id: field === "function" ? value.value : prev.function_id,
    }));

    // After updating employee type or function, fetch the salary
    if (field === "employee_type" || field === "function") {
      const functionID =
        field === "function" ? value.value : employeeData.function_id;
      const employeeType =
        field === "employee_type" ? value.value : employeeData.employee_type_id;

      if (selectedPc != null) {
        // Fetch the salary based on the updated values
        const newFetchedSalary = await fetchSalary(
          selectedPc,
          functionID,
          employeeType,
          employeeData
        );

        // Update the salary in the state
        setEmployeeData((prev) => ({
          ...prev,
          salary: newFetchedSalary || prev.salary, // Update with the new salary or keep the previous if not fetched
        }));
      }
      // Update the new salary state as well
      setNewSalary("");
    }
  };
  const handleSave = async () => {
    if (!validateTimings()) {
      CustomNotify({
        type: "error",
        message: t("Please correct the errors before saving"),
        autoClose: 3000,
      });
      return false; // Early return with false if validation fails
    }

    try {
      // Create a copy of employeeData to modify
      const dataToSend = {
        ...employeeData,
        // Convert salary string to float, replacing comma with dot and removing spaces
        salary: parseFloat(employeeData.salary.replace(",", ".").replace(/\s/g, "")),
      };

      // Only include new_salary if it exists and is not empty
      if (employeeData.new_salary && employeeData.new_salary.trim() !== "") {
        dataToSend.new_salary = employeeData.new_salary.replace(",", ".").replace(/\s/g, "");
      }
      // setLoading(true);
      const result = await ApiCall.service(
        EDIT_PLANNING,
        "POST",
        dataToSend,
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (result.status === SUCCESS) {
        setLoading(false);
        setShowModal(false);
        clearPopup();
        CustomNotify({
          type: "success",
          message: t("Planning updated successfully"),
          autoClose: 2500,
        });
        return true; // Return true if save is successful
      }
    } catch (error) {
      // setLoading(false);
      console.error("Error updating planning:", error);
      return false; // Return false if there is an error
    }
  };

  const handlePcChange = (value: any) => {
    const pc = pcData.find((pc: PcOptions) => pc.id === value.value);
    setSelectedPc(pc || null);
  };

  const handleSalaryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const salaryInput = e.target.value;

    // Validate the salary input
    const { error, warning } = validateSalary(
      salaryInput,
      employeeData.salary,
      employeeData.employee_type_id,
      pcData.flex_salary
    );

    // Update the validation errors and warnings
    setValidationErrors((prev) => ({
      ...prev,
      salaryError: error,
      salaryWarning: warning,
    }));
    setEmployeeData((prev) => ({
      ...prev,
      new_salary: salaryInput, // Update with the new salary or keep the previous if not fetched
    }));
    setNewSalary(salaryInput);
  };

  const handleRemoveShift = (date: string) => {
    setEmployeeData((prev) => ({
      ...prev,
      schedule: prev.schedule.slice(0, -1), // Remove the last timing entry
    }));
  };
  const shiftCells = (daySchedule: Timing[], date: string, timeRegistration: TimeRegistration[]) => {
    // Helper function to check if a shift has time registration
    const hasTimeRegistration = (sequence: number, key: string) => {
      if (!timeRegistration || timeRegistration.length === 0) return false;

      return timeRegistration.some(tr => {
        // Convert sequence to match the data structure
        const sequenceMatch = parseInt(tr.sequence) === sequence;

        // Check specific time field based on key
        const timeField = key === 'start_time' ? tr.start_time : tr.end_time;
        const hasTime = timeField && timeField !== "" && timeField !== null;

        return sequenceMatch && hasTime;
      });
    };

    // Helper to check if add/remove buttons should be disabled
    const shouldDisableControls = (sequence: number) => {
      if (!timeRegistration || timeRegistration.length === 0) return false;

      return timeRegistration.some(tr =>
        parseInt(tr.sequence) === sequence &&
        ((tr.start_time && tr.start_time !== "") ||
          (tr.end_time && tr.end_time !== ""))
      );
    };

    return (
      <div className="col-12 mt-1 mt-lg-0">
        <div className="shift-cells-container row">
          {daySchedule?.map((shift, index) => (
            <>
              <div className="col-lg-4 col-md-5 shift-cell d-flex" key={index}>
                <span className="align-middle me-auto" style={{ width: "48%" }}>
                  <InputTextFieldWithLabel
                    value={shift.shift_starttime}
                    handleChange={(e) =>
                      handleShiftChange(
                        date,
                        index,
                        "start_time",
                        e.target.value
                      )
                    }
                    name={"start time"}
                    error={validationErrors.timings[index]?.shift_starttime}
                    isDisabled={hasTimeRegistration(shift.shift_sequence, 'start_time')}
                  />
                </span>

                <span className="align-middle ms-auto" style={{ width: "48%" }}>
                  <InputTextFieldWithLabel
                    value={shift.shift_endtime}
                    handleChange={(e) =>
                      handleShiftChange(date, index, "end_time", e.target.value)
                    }
                    name={"end time"}
                    error={validationErrors.timings[index]?.shift_endtime}
                    isDisabled={hasTimeRegistration(shift.shift_sequence, 'end_time')}
                  />
                </span>
              </div>
            </>
          ))}
          {validationErrors.timings?.total && (
            <div className="text-danger mt-1 small">
              {validationErrors.timings?.total}
            </div>
          )}
          <div className="col-lg-1 col-md-2 align-self-center">
            {daySchedule.length < 2 && !shouldDisableControls(daySchedule[0].shift_sequence) && (
              <div
                className="shift-cell"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span
                  data-bs-toggle="tooltip"
                  title={t("Add")}
                  onClick={() => handleAddShift(date)}
                  className="cursor-pointer secondaryColorHoverEffect"
                  style={{ width: "100%" }}
                >
                  <Icon name="add" width={iconStyle} height={iconStyle} isIcon={true} />
                </span>
              </div>
            )}
            {daySchedule.length >= 2 && !shouldDisableControls(daySchedule[1].shift_sequence) && (
              <div
                className="shift-cell"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span
                  data-bs-toggle="tooltip"
                  title={t("Remove")}
                  onClick={() => handleRemoveShift(date)}
                  className="cursor-pointer secondaryColorHoverEffect removeUploadedFile"
                  style={{ width: "100%" }}
                >
                  <Icon
                    name="RemoveIcon"
                    width={iconStyle}
                    height={iconStyle}
                    isIcon={true}
                  />
                </span>
              </div>
            )}
          </div>
          <div className="col-lg-3 col-md-12 mt-3 mt-lg-0 text-end text-lg-start align-self-center">
            <span className="small">{t("Total Hrs: ")}</span>
            <span className="totalHours text-white poppins-medium ms-2">
              {daySchedule.length >= 2 &&
                daySchedule[1].shift_starttime != "" &&
                daySchedule[1].shift_endtime != ""
                ? calculateDuration(
                  daySchedule[0].shift_starttime,
                  daySchedule[0].shift_endtime
                ) +
                calculateDuration(
                  daySchedule[1].shift_starttime,
                  daySchedule[1].shift_endtime
                )
                : calculateDuration(
                  daySchedule[0].shift_starttime,
                  daySchedule[0].shift_endtime
                )}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ModalPopup
      show={showModal}
      handleClose={handleCloseModal}
      modalTitle={t("Edit planning") + " - " + formatDateDMY(employeeData.pdate)}
      className="editPlanning"
      modalBackgroundColor="#8571b9"
      buttonText={t("Update")}
      onNextClick={handleSave} // Attach the save handler to the button
      iconFillColor="#8571b9"
      disableNext={loading}
    >
      <div className="row">
        <div className="col-12 mb-2">
          <InputTextFieldWithLabel
            value={employeeData.name}
            readOnly={true} // Make it read-only if you don't want to edit it
            name={t("Employee Name")}
            className="inputFieldColor"
            isDisabled
          />
        </div>
      </div>
      <div className="multiSelectWrapper">
        <div className="row">
          <div className="col-5">
            {/* PC Selection */}
            <SelectWithSearch
              search={true}
              options={pcData.map((pc: PcOptions) => ({
                label: `(${pc.pc_number}) ${pc.pc_name}`,
                value: pc.id,
              }))}
              value={
                selectedPc
                  ? {
                    label: `(${selectedPc.pc_number}) ${selectedPc.pc_name}`,
                    value: selectedPc.id,
                  }
                  : null
              }
              onChange={handlePcChange}
              placeHolder={t("Select PC")}
              name="pc"
              selectWrapperClassName="mb-2"
              isDisabled={true}
            />
          </div>
          <div className="col-4">
            {/* Employee Type Selection */}
            {selectedPc && (
              <SelectWithSearch
                search={true}
                options={selectedPc.employee_types || []}
                value={
                  selectedPc.employee_types.find(
                    (type) => type.value === employeeData.employee_type_id
                  ) || {}
                }
                onChange={(value: any) => handleChange("employee_type", value)}
                placeHolder={t("Select Employee Type")}
                name="employee_type"
                selectWrapperClassName="mb-2"
                isDisabled={true}
              />
            )}
          </div>
          <div className="col-3">
            {selectedPc && (
              <input
                type="text"
                className="form-control border mb-2 selectPcHeight"
                value={employeeData?.salary?.replace(".", ",").replace(/\s/g, "")}
                disabled
              />
            )}
          </div>
          <div className="col-9">
            {/* Function Selection */}
            {selectedPc && (
              <SelectWithSearch
                search={true}
                options={[
                  ...(selectedPc?.categories?.flatMap(
                    (category) => category?.functions
                  ) || []),
                  ...(selectedPc?.functions || [])
                ]}
                value={
                  selectedPc.categories
                    ?.flatMap((category) => category.functions)
                    .find((func) => func.value === employeeData.function_id) ||
                  {}
                }
                onChange={(value: any) => handleChange("function", value)}
                placeHolder={t("Select Function")}
                name="function"
                selectWrapperClassName="mb-2"
              />
            )}
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control inputFieldColor"
              value={new_salary.replace(".", ",").replace(/\s/g, "")}
              onChange={handleSalaryChange}
              placeholder={t("New Salary")}
            />
          </div>
          <div className="row">
            <div className="col-12">
              {validationErrors.salaryError != undefined &&
                validationErrors.salaryError != "" && (
                  <CustomAlert
                    variant="danger"
                    iconName="warningIcon"
                    className="warningAndErrorAlert"
                  >
                    {validationErrors.salaryError}
                  </CustomAlert>
                )}
              {validationErrors.salaryWarning != undefined &&
                validationErrors.salaryWarning != "" && (
                  <CustomAlert
                    variant="warning"
                    iconName="warningIcon"
                    className="warningAndErrorAlert"
                  >
                    {validationErrors.salaryWarning}
                  </CustomAlert>
                )}
              {shiftCells(employeeData.schedule, employeeData.pdate, employeeData.time_registration)}
            </div>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};

export default translate(EditPlanningPopup);
