import React, { useState } from "react";

interface Props {
  title?: string;
  label?: string;
  width?: string; // Allow passing a custom width as a string (e.g., '200px', '50%')
  textClassName?: string;
}

const TextEllipsis: React.FC<Props> = ({ title, label, width, textClassName }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  // const handleTouch = () => {
  //   setIsTooltipVisible((prev) => !prev);
  // };

  return (
    <div
      className={`${textClassName} textEllipsis`}
      style={{ width, position: "relative", cursor: "pointer" }}
      // onClick={handleTouch}
      title={!isTooltipVisible ? title : undefined} // Fallback for desktop
      aria-expanded={isTooltipVisible}
    >
      <span className="text">{label}</span>
      {isTooltipVisible && (
        <div
          className="tooltipCustom"
          style={{
            position: "relative",
            top: "100%",
            left: "0%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            padding: "0.5vw",
            borderRadius: "0.5vw",
            whiteSpace: "pre-wrap",
            zIndex: 9999,
          }}
        >
          {title}
        </div>
      )}
    </div>
  );
};

export default TextEllipsis;
