import React, { useState } from 'react';
import ManageLocationUsers from './ManageLocationUsers';
// import ManageLocationManagers from './ManageLocationManagers';
import { Tabs, Tab, Dropdown } from "react-bootstrap";
import { t, translate } from '../CentralDataMangement/translation/Translation';
import ManageLocationManagersUsers from './ManageLocationManagersUsers';
import Header from '../common/layout/Header';
import BackLink from '../common/atoms/BackLink';
import "./css/locationManager.css";
import LabelField from '../common/atoms/LabelField';
import useWindowWidth from '../common/atoms/WindowWidth';

const ManageLocationTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState('users');

  const handleTabChange = (tab: any) => {
    setActiveTab(tab);
  };

  const isDesktop = useWindowWidth(1024);
  return (
    <>
      <Header
        headerName={`${activeTab === "managers" ? t("Manage location managers") : t("Manage location users")}`}
        headerClassName="myAccountCardTitle"
      />
      <div className='locationManagerBg'>
        <div className="search-bar pb-md-2">
          <div className="row">
            <BackLink backLink="/manage-location" />
            <div className="col-10 align-self-center text-center d-block d-lg-none">
              <LabelField
                title={`${activeTab === "managers" ? t("Manage location managers") : t("Manage location users")}`}
                className="myAccountCardTitle mb-0"
              />
            </div>
          </div>
        </div>
        <div className='createpagesBg'>
          {isDesktop ? (
            <Tabs activeKey={activeTab} onSelect={(k) => handleTabChange(k)} className="create-role-tabs border-0 location-manage-tabs">
              <Tab eventKey="managers" title={t("Manage location managers")}>
                {activeTab === 'managers' && <ManageLocationManagersUsers role={'location_manager'} />}
              </Tab>
              <Tab eventKey="users" title={t("Manage location users")}>
                {activeTab === 'users' && <ManageLocationManagersUsers role={'employee'} />}
              </Tab>
            </Tabs>
          ) : (
            <Dropdown className='location-manage-tabs-dropdown'>
              <Dropdown.Toggle className="create-role-tabs border-0">
                {activeTab === "managers"
                  ? t("Manage location managers")
                  : t("Manage location users")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  active={activeTab === "managers"}
                  onClick={() => handleTabChange("managers")}
                >
                  {t("Manage location managers")}
                </Dropdown.Item>
                <Dropdown.Item
                  active={activeTab === "users"}
                  onClick={() => handleTabChange("users")}
                >
                  {t("Manage location users")}
                </Dropdown.Item>
              </Dropdown.Menu>
              {activeTab === "managers" && (
                <ManageLocationManagersUsers role={"location_manager"} />
              )}
              {activeTab === "users" && (
                <ManageLocationManagersUsers role={"employee"} />
              )}
            </Dropdown>
          )}
        </div>
      </div>
    </>
  );
};

export default translate(ManageLocationTabs);
