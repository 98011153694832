import React from 'react';
import LabelField from './LabelField';
import { t, translate } from '../../CentralDataMangement/translation/Translation';
import Image from './Image';
import defaultUserImage from '../../../static/images/User.svg';
import Icon from '../../../Icon';
import OverFlowWrapper from '../OverFlowWrapper';
import { useResponsiveValue } from './ResponsivWidth';

interface User {
  id: number
  name: string;
  email: string;
  profile_pic: string | null;
  since: string;
}

interface LocationUserssListProps {
  users: User[];
  onRemove: (id: number, role: string) => void;
}

const LocationuUerssList: React.FC<LocationUserssListProps> = ({ users, onRemove }) => {
  const IconStyle = useResponsiveValue("5vw", "3vw", "1vw")
  const IconProps = {
    isIcon: true,
    width: IconStyle,
    height: IconStyle,
  };

  return (
    <div>
      <LabelField title={t('Location users:')} />
      <OverFlowWrapper className="grid-4 d-grid grid-md-3 locationUsersHeight">
        {users.map((user, index) => (
          <div className="employeeDetailsCardPlanning locationManagerCards">
            <div title={t("Remove")} onClick={() => onRemove(user.id, 'employee')}
              className="removeUploadedFile sec text-end cursor-pointer">
              <Icon name="RemoveIcon" {...IconProps} />
            </div>
            <div className="d-flex align-items-center" style={{ gap: "1vw" }}>
              <div>
                {user.profile_pic ? (
                  <Image
                    src={user.profile_pic}
                    title={`${user.name}'s profile`}
                    className="locationManagerProfile"
                  />
                ) : (
                  <Image src={defaultUserImage} title="Default profile" className="locationManagerProfile"
                  />
                )}
              </div>
              <div>
                <div> {user.name}</div>
                <div> {user.email}</div>
                <div> {t("Location user since")}:
                  {new Date(user.since).toLocaleDateString()}</div>
              </div>
            </div>
          </div>
        ))}
      </OverFlowWrapper>

    </div>
  );
};

export default translate(LocationuUerssList);
