import React from "react";
import CustomAlert from "../atoms/CustomAlert";
import { t } from "../atoms/translation";

interface CoopMessageDisplayProps {
  coopStatus: boolean;
  blockStatus: boolean;
}

const CoopMessageDisplay: React.FC<CoopMessageDisplayProps> = ({ coopStatus, blockStatus }) => {
  return (
    <>
      {!coopStatus && (
        <CustomAlert className="alert alert-warning" iconName="warningIcon">
          {t(
            `Dear Customer, At this moment, we are unable to create a schedule for you in the system, as your partnership agreement has not been signed yet. You do have access to the platform to consult information, but in order to have a definitive schedule made, it is necessary that you sign the partnership agreement. If it is urgent, a schedule can always be requested via `
          )}
          <a className="text-decoration-underline linkColor" href="mailto:payroll@absoluteyou.be">
            {t("payroll@absoluteyou.be")}
          </a>
          {t(
            ` We kindly request you to address this as soon as possible, so that we can fully assist you.`
          )}
        </CustomAlert>
      )}
      {coopStatus && blockStatus && (
        <CustomAlert className="alert alert-warning" iconName="warningIcon">
          {t(`This company is blocked. Please contact your sales agent.`)}
        </CustomAlert>
      )}
    </>
  );
};

export default CoopMessageDisplay;
