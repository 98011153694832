import React, { useEffect, useState, useContext } from "react";
import ListView from "./ListView";
import AddCategory from "./AddCategory";
import styles from "./Pc.module.css";
import { PcContext } from "./PcContext";
import AddFunction from "./AddFunction";
import AddPc from "./AddPc";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import { ApiCall } from "../../../services/ApiServices";
import AddAge from "./AddAge";
import AgeTimeLimit from "./AgeTimeLimit";

// import AddAge from "./AddAge";
import EmployeeType from "./EmployeeType";
import { AiFillPlusSquare, AiFillMinusSquare } from "react-icons/ai";
import { GET_ALL_PC_DETAILS_BY_UNIQUE_ID } from "../../../routes/ApiEndpoints";
import Header from "../../common/layout/Header";
import Button from "../../common/atoms/Button";
import BackLink from "../../common/atoms/BackLink";
import OverFlowWrapper from "../../common/OverFlowWrapper";
import "./multiSelect.css";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import LabelField from "../../common/atoms/LabelField";

// import AgeTimeLimit from "./AgeTimeLimit";
const PcOverview = (params) => {
  const {
    pc_unique_key,
    setPc_unique_key,
    current_sec,
    cat_rightsec,
    setCat_rightsec,
    cat_leftsec,
    setCat_leftsec,
    cat_subsec_type,
    setCat_subsec_type,
    cat_fun_updated,
    setCat_fun_updated,
    sec_completed,
    setSec_completed,
    cat_subsec_id,
    setCat_subsec_id,
    setCurrent_sec,
    pc_view_type,
    setPc_view_type,
  } = useContext(PcContext);

  const [pc, setPc] = useState([]);
  const [count, setCount] = useState(1);
  const [secid, setSecid] = useState("");
  const [pc_number, setPc_number] = useState("");
  const [type, setType] = useState("");

  const { uid } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const fid = searchParams.get("fid");
  const cid = searchParams.get("cid");

  if (uid && uid !== undefined) {
    setPc_unique_key(uid);
  }
  /**
   * Fetch data from backend on page load
   */
  useEffect(() => {
    setCat_fun_updated("");
    if (
      current_sec === 2 ||
      params.type === "editpc" ||
      params.type === "viewpc"
    ) {
      var res1 = sec_completed;
      res1["pc"] = true;
      setSec_completed(res1);

      if (pc_unique_key) {
        ApiCall.getService(
          GET_ALL_PC_DETAILS_BY_UNIQUE_ID + "/" + pc_unique_key,
          "GET",
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        )
          .then((result) => {
            setPc(result.data);
            console.log(pc);
            setPc_number(result.data["pc_number"]);
            setType(params.type);
            setPc_view_type(params.type);
            window.scrollTo(0, 0);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }, [current_sec, pc_unique_key, cat_fun_updated, params]);

  useEffect(() => {
    const cid = searchParams.get("cid");
    const fid = searchParams.get("fid");
    if (cid != null && cid !== undefined && cid !== "") {
      setCat_subsec_type(1);
      setCat_leftsec("col-xl-8 col-lg-12 col-md-12");
      setCat_rightsec("d-block col-xl-4 col-lg-12 col-md-12");
      setCat_subsec_id(cid);
    } else if (fid !== null && fid !== undefined && fid !== "") {
      setCat_subsec_type(2);
      setCat_leftsec("col-xl-8 col-lg-12 col-md-12");
      setCat_rightsec("d-block col-xl-4 col-lg-12 col-md-12");
      setCat_subsec_id(fid);
    }
  }, []);

  let next_redirection = () => {
    setCurrent_sec(3);
    var res1 = sec_completed;
    res1["cat"] = true;
    setSec_completed(res1);
  };

  let updateCollapseSec = (level, id, val, val2) => {
    var pc1 = pc;
    if (level === 1) {
      setPc((current) => {
        return {
          ...current,
          collapseOpen: !val,
        };
      });
    } else if (level === 2) {
      pc1["childObj"][id]["collapseOpen"] = !val;
      setPc((prev) => ({ ...prev, pc: pc1 }));
    }
    // else{
    // 	pc1['childObj'][val][
    // 		'childObj'
    // 	][val2]['collapseOpen'] = !val;
    // 	setPc((prev) => ({ ...prev, pc: pc1 }));
    // }
  };

  const getBackLink = () => {
    if (fid) {
      return "/manage-functions";
    } else if (cid) {
      return "/manage-categories";
    } else {
      return "/manage-pcs";
    }
  };

  return (
    <>
      <Header
        headerName={
          params.type == "editpc"
            ? t("Edit paritair committee")
            : params.type == "viewpc"
            ? t("View paritair committee")
            : ""
        }
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar">
        {pc_view_type != "addpc" && (
          <div className="row">
            {/* <BackLink
              backLink={() => {
                if (fid) {
                  navigate("/manage-functions");
                } else if (cid) {
                  navigate("/manage-categories");
                } else {
                  navigate("/manage-pcs");
                }
              }}
            /> */}

            <BackLink backLink={getBackLink()} />
            <div className="col-8 align-self-center text-center d-block d-lg-none">
              <LabelField
                title={
                  params.type == "editpc"
                    ? t("Edit paritair committee")
                    : params.type == "viewpc"
                    ? t("View paritair committee")
                    : ""
                }
                className="myAccountCardTitle mb-0"
              />
            </div>

            <div className="col-6 ms-auto text-end">
              {pc && params.type != "viewpc" && (
                <div
                  className={cat_subsec_type == 0 ? "text-end mb-3" : "d-none"}
                  style={
                    pc_view_type == "addpc" ? { marginBottom: "1.5rem" } : {}
                  }
                >
                  <Button
                    handleClick={() => {
                      setCat_leftsec("col-lg-12 col-xl-7 col-xxl-8 col-md-12");
                      setCat_rightsec(
                        "d-block col-xl-5 col-xxl-4 col-lg-12 col-md-12"
                      );
                      setCat_subsec_type(1);
                    }}
                    className="searchBtn px-3 me-3"
                  >
                    + {t("Add category")}
                  </Button>
                  <Button
                    handleClick={() => {
                      setCat_leftsec("col-lg-12 col-xxl-8 col-xl-7 col-md-12");
                      setCat_rightsec(
                        "d-block col-xxl-4 col-xl-5 col-lg-12 col-md-12"
                      );
                      setCat_subsec_type(2);
                    }}
                    className="searchBtn px-3"
                  >
                    + {t("Add function")}
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="managepagesBg">
        <OverFlowWrapper>
          <div className="row">
            <div className={`${cat_leftsec}`}>
              {pc && (
                <OverFlowWrapper className=" editPcWrapeer">
                  <div>
                    <ul
                      className={`list-unstyled shadow-none view_pc_accordion ${styles.tree}`}
                    >
                      <li className={styles.sectioncolor}>
                        <ul
                          className={`list-inline my-2 list-unstyled  pc ${styles.tree} d-flex `}
                        >
                          <li
                            className="list-inline-item section-plus-icon align-top  mx-0 px-2 shadow-none d-flex align-items-center"
                            style={{ backgroundColor: "#eeecf3" }}
                          >
                            <span>
                              {pc["collapseOpen"] === true ||
                              pc["childObj"] === undefined ? (
                                <AiFillMinusSquare
                                  className="colorSkyBlue cursor-pointer"
                                  onClick={() =>
                                    updateCollapseSec(
                                      1,
                                      pc["id"],
                                      pc["collapseOpen"]
                                    )
                                  }
                                />
                              ) : (
                                <AiFillPlusSquare
                                  className="colorPrimary cursor-pointer"
                                  onClick={() =>
                                    updateCollapseSec(
                                      1,
                                      pc["id"],
                                      pc["collapseOpen"]
                                    )
                                  }
                                />
                              )}
                            </span>
                          </li>

                          <ListView
                            pcid={pc_unique_key}
                            pc_number={pc_number}
                            index={count + 1}
                            title={
                              pc["pc_alias_name"] != "" &&
                              pc["pc_alias_name"] != undefined
                                ? pc["pc_alias_name"]
                                : pc["pc_name"]
                            }
                            theader={pc["header"]}
                            tvalue={
                              pc["pc_alias_name"] != "" &&
                              pc["pc_alias_name"] != undefined
                                ? [pc["pc_number"], pc["pc_alias_name"]]
                                : [pc["pc_number"], pc["pc_name"]]
                            }
                            actiontype={["edit"]}
                            sectype="pc"
                            secId={pc["id"]}
                            type={type}
                            level="1"
                          />
                        </ul>
                      </li>
                      <li>
                        {pc["childObj"] &&
                          pc["collapseOpen"] === true &&
                          Object.keys(pc["childObj"]).map((val, key) => (
                            <ul
                              id={"collapsepc" + pc_unique_key}
                              className={`collapse show list-unstyled ms-5 my-0 ${styles.lev1} ${styles.tree}`}
                              key={val}
                            >
                              {pc["childObj"][val]["type"] === 2 ? (
                                <li>
                                  <ul className="list-inline my-2 ">
                                    <li className="sectioncolor">
                                      <ul className="d-flex ps-0">
                                        <li
                                          className="list-inline-item section-plus-icon mx-0 px-3 d-flex align-items-center"
                                          style={{ backgroundColor: "#eeecf3" }}
                                        >
                                          <span>
                                            {pc["childObj"] &&
                                              console.log(pc["childObj"])}
                                            {pc["childObj"][val][
                                              "collapseOpen"
                                            ] === true ||
                                            pc["childObj"][val]["childObj"] ===
                                              undefined ? (
                                              <AiFillMinusSquare
                                                className="colorSkyBlue cursor-pointer"
                                                onClick={(prev) =>
                                                  updateCollapseSec(
                                                    2,
                                                    val,
                                                    pc["childObj"][val][
                                                      "collapseOpen"
                                                    ]
                                                  )
                                                }
                                              />
                                            ) : (
                                              <AiFillPlusSquare
                                                className="colorPrimary cursor-pointer"
                                                onClick={() =>
                                                  updateCollapseSec(
                                                    2,
                                                    val,
                                                    pc["childObj"][val][
                                                      "collapseOpen"
                                                    ]
                                                  )
                                                }
                                              />
                                            )}
                                          </span>
                                          {/* <FaMinusSquare /> */}
                                        </li>
                                        <ListView
                                          pcid={pc_unique_key}
                                          pc_number={pc_number}
                                          index={"cat1-" + val}
                                          title={
                                            pc["childObj"][val]["category_name"]
                                          }
                                          theader={
                                            pc["childObj"][val]["header"]
                                          }
                                          tvalue={[
                                            pc["childObj"][val][
                                              "category_name"
                                            ],
                                            "€ " +
                                              pc["childObj"][val]["min_salary"],
                                          ]}
                                          className="ms-2"
                                          sectype="cat"
                                          secId={pc["childObj"][val]["id"]}
                                          type={type}
                                          level="2"
                                        />
                                      </ul>
                                    </li>
                                    <li>
                                      <ul
                                        className={`list-unstyled ms-5 ${styles.tree}`}
                                      >
                                        {pc["childObj"][val]["childObj"] &&
                                          pc["childObj"][val][
                                            "collapseOpen"
                                          ] === true &&
                                          Object.keys(
                                            pc["childObj"][val]["childObj"]
                                          ).map((val2, key2) => (
                                            <li
                                              key={key2}
                                              className="position-relative innerLine"
                                            >
                                              <ul className="list-inline">
                                                <ListView
                                                  pcid={pc_unique_key}
                                                  key={val2}
                                                  pc_number={pc_number}
                                                  index={
                                                    "fun1-cat" + val + val2
                                                  }
                                                  title={
                                                    pc["childObj"][val][
                                                      "childObj"
                                                    ][val2]["function_name"]
                                                  }
                                                  theader={
                                                    pc["childObj"][val][
                                                      "childObj"
                                                    ][val2]["header"]
                                                  }
                                                  tvalue={[
                                                    pc["childObj"][val][
                                                      "childObj"
                                                    ][val2]["function_name"],
                                                    "€ " +
                                                      pc["childObj"][val][
                                                        "childObj"
                                                      ][val2]["min_salary"],
                                                  ]}
                                                  className="ms-2"
                                                  secId={
                                                    pc["childObj"][val][
                                                      "childObj"
                                                    ][val2]["id"]
                                                  }
                                                  sectype="funct"
                                                  type={type}
                                                  level="3"
                                                />
                                              </ul>
                                            </li>
                                          ))}
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                              ) : (
                                <li className="sectioncolor">
                                  <ul className="d-flex ps-0">
                                    <li
                                      className="list-inline-item section-plus-icon mx-0 px-3 d-flex align-items-center"
                                      style={{ backgroundColor: "#eeecf3" }}
                                    >
                                      {console.log(
                                        pc["childObj"][val]["childObj"]
                                      )}
                                      {pc["childObj"][val]["collapseOpen"] ===
                                        true ||
                                      pc["childObj"][val]["childObj"] ===
                                        undefined ? (
                                        <AiFillMinusSquare
                                          className="colorSkyBlue cursor-pointer"
                                          onClick={() =>
                                            updateCollapseSec(
                                              2,
                                              val,
                                              pc["childObj"][val][
                                                "collapseOpen"
                                              ]
                                            )
                                          }
                                        />
                                      ) : (
                                        <AiFillPlusSquare
                                          onClick={() =>
                                            updateCollapseSec(
                                              2,
                                              val,
                                              pc["childObj"][val][
                                                "collapseOpen"
                                              ]
                                            )
                                          }
                                          className="colorPrimary cursor-pointer"
                                        />
                                      )}
                                    </li>
                                    <ListView
                                      pcid={pc_unique_key}
                                      pc_number={pc_number}
                                      index={"fun2-" + val}
                                      title={
                                        pc["childObj"][val]["function_name"]
                                      }
                                      theader={pc["childObj"][val]["header"]}
                                      tvalue={[
                                        pc["childObj"][val]["function_name"],
                                        "€ " +
                                          pc["childObj"][val]["min_salary"],
                                      ]}
                                      secId={pc["childObj"][val]["id"]}
                                      sectype="funct"
                                      type={type}
                                      level="2"
                                    />
                                  </ul>
                                </li>
                              )}
                            </ul>
                          ))}

                        {/* Handling childObjFun */}
                        {pc["childObjFun"] &&
                          pc["collapseOpen"] === true &&
                          Object.values(pc["childObjFun"]).map(
                            (funObj, funKey) => (
                              <ul
                                id={"collapsepcFun" + pc_unique_key}
                                className={`collapse show list-unstyled ms-5 my-0 ${styles.lev1} ${styles.tree}`}
                                key={funKey}
                              >
                                <li className="sectioncolor">
                                  <ul className="d-flex ps-0">
                                    <li
                                      className="list-inline-item section-plus-icon mx-0 px-3 d-flex align-items-center"
                                      style={{ backgroundColor: "#eeecf3" }}
                                    >
                                      {/* Icon for collapsing childObjFun if needed */}
                                      {funObj["collapseOpen"] === true ||
                                      funObj["childObj"] === undefined ? (
                                        <AiFillMinusSquare
                                          className="colorSkyBlue cursor-pointer"
                                          onClick={() =>
                                            updateCollapseSec(
                                              3,
                                              funObj["id"],
                                              funObj["collapseOpen"]
                                            )
                                          }
                                        />
                                      ) : (
                                        <AiFillPlusSquare
                                          onClick={() =>
                                            updateCollapseSec(
                                              3,
                                              funObj["id"],
                                              funObj["collapseOpen"]
                                            )
                                          }
                                          className="colorPrimary cursor-pointer"
                                        />
                                      )}
                                    </li>
                                    <ListView
                                      pcid={pc_unique_key}
                                      pc_number={pc_number}
                                      index={"fun3-" + funObj["id"]}
                                      title={funObj["function_name"]}
                                      theader={funObj["header"]}
                                      tvalue={[
                                        funObj["function_name"],
                                        "€ " + funObj["min_salary"],
                                      ]}
                                      secId={funObj["id"]}
                                      sectype="funct"
                                      type={type}
                                      level="3"
                                    />
                                  </ul>
                                </li>
                              </ul>
                            )
                          )}
                      </li>
                    </ul>
                  </div>
                </OverFlowWrapper>
              )}
            </div>
            <div
              className={`col ${cat_rightsec} ${
                pc_view_type === "editpc"
                  ? ""
                  : pc_view_type === "viewpc"
                  ? ""
                  : ""
              }`}
            >
              <OverFlowWrapper className="addBtn">
                {pc_view_type !== "viewpc" && (
                  <div className="row pb-2">
                    <div className="col-md-6">
                      <Button
                        handleClick={() => {
                          setCat_subsec_type(1);
                          setCat_subsec_id("");
                        }}
                        className="searchBtn w-100"
                      >
                        + {t("Add category")}
                      </Button>
                    </div>
                    <div className="col-md-6">
                      <Button
                        handleClick={() => {
                          setCat_subsec_type(2);
                          setCat_subsec_id("");
                        }}
                        className="searchBtn w-100"
                      >
                        + {t("Add function")}
                      </Button>
                    </div>
                  </div>
                )}
                {cat_subsec_type === 1 && (
                  <AddCategory
                    id={secid}
                    categorylist={pc["childObj"] ? pc["childObj"] : []}
                  />
                )}
                {cat_subsec_type === 2 && (
                  <AddFunction
                    id={secid}
                    categorylist={pc["childObj"] ? pc["childObj"] : []}
                  />
                )}
                {cat_subsec_type === 3 && <AddPc />}
                {cat_subsec_type === 4 && <AddAge />}
                {cat_subsec_type === 5 && <EmployeeType />}
              </OverFlowWrapper>

              {/* {(cat_subsec_type == 6 && pc_view_type == 'editpc') && router.push('/view-salary-benfits/viewSalaryBenfits?k='+pc_unique_key)}
					{(cat_subsec_type == 6 && pc_view_type == 'viewpc') && router.push('/salary-benefits/view?k='+pc_unique_key)} */}
              {/* {(cat_subsec_type == 6 && pc_view_type == 'viewpc') && <ViewSalaryBenefits/>} */}
              {cat_subsec_type == 7 && <AgeTimeLimit />}
            </div>
          </div>
        </OverFlowWrapper>
      </div>
    </>
  );
};
export default translate(PcOverview);
