import React from "react";
import Image from "./Image";
import Age15 from "../../../static/images/Age15.svg";
import Age16 from "../../../static/images/Age16.svg";
import Age17 from "../../../static/images/Age17.svg";
import Age18 from "../../../static/images/Age18.svg";
import Age19 from "../../../static/images/Age19.svg";
import Age20 from "../../../static/images/Age20.svg";
import Age21 from "../../../static/images/Age21.svg";
import Age22 from "../../../static/images/Age22.svg";
import Age23 from "../../../static/images/Age23.svg";
import Age24 from "../../../static/images/Age24.svg";
import Age25 from "../../../static/images/Age25.svg";
import { useResponsiveValue } from "./ResponsivWidth";

interface AgeIconProps {
  age: number;
  pc_max_age: number;
  type: number;
}

const ageIcons: Record<number, string> = {
  15: Age15,
  16: Age16,
  17: Age17,
  18: Age18,
  19: Age19,
  20: Age20,
  21: Age21,
  22: Age22,
  23: Age23,
  24: Age24,
  25: Age25,
};

// AgeIcon component that takes props
const AgeIcon: React.FC<AgeIconProps> = ({ age, pc_max_age = 0, type }) => {
  const ageIconStyle = useResponsiveValue("4vw", "3vw", "1.5vw")
  // Return nothing if age is 0 (edge case)
  if (age === 0) return null;

  // Check if the age is within the valid range
  if (age < 15 || age > 25) return null;

  // Condition for type 1 (under max age)
  if (type === 1 && age < pc_max_age) {
    return (
      <Image
        altName={`Age ${age}`}
        src={ageIcons[age]} // Use the path from ageIcons directly
        title={`Age ${age}`}
        imageWidth={ageIconStyle}
        imageHeight={ageIconStyle}
      />
    );
  }

  // Condition for type 2 (exact max age)
  if (type === 2 && age === pc_max_age && age <= 21) {
    return (
      <Image
        altName={`Age ${age}`}
        src={ageIcons[age]} // Use the path from ageIcons directly
        title={`${age}`}
        imageWidth="2vw"
        imageHeight="2vw"
      />
    );
  }
  console.log(pc_max_age);
  if (pc_max_age && age <= 21) {
    return (
      <Image
        altName={`Age ${age}`}
        src={ageIcons[age]} // Use the path from ageIcons directly
        title={`${age}`}
        imageWidth="2vw"
        imageHeight="2vw"
      />
    );
  }
  return (
    <Image
      altName={`Age ${age}`}
      src={ageIcons[age]} // Use the path from ageIcons directly
      title={`Age ${age}`}
      imageWidth={ageIconStyle}
      imageHeight={ageIconStyle}
    />
  );
};

export default AgeIcon;
