import React from "react";
import ModalPopup from "../common/atoms/ModalPopup";
import { t } from "../CentralDataMangement/translation/Translation";
import LabelField from "../common/atoms/LabelField";
import CompanyList from "../Employee/registration/organisms/CompanyList";
import { Company } from "./AddAdministrator";


interface RemoveAdminProps {
    close: () => void;
    Company?: Company[];
    updateAdminCompanies: () => void;
    handleCheckBoxClick: (company: Company, isChecked: boolean) => void;
    companies?: Company[];
    checkedItems?: Set<number>;
}

const RemoveAdminModal: React.FC<RemoveAdminProps> = ({
    close,
    Company,
    updateAdminCompanies,
    handleCheckBoxClick,
    companies,
    checkedItems,
}) => {
    return (
        <ModalPopup
            show={true}
            handleClose={close}
            modalTitle={t("Manage admin")}
            modalBackgroundColor="#8571b9"
            className="addCompanyModal"
            buttonText={t("Update")}
            onNextClick={updateAdminCompanies}
            modalBodyClassName="scrollBar"
        >
            <p>{t("Select companies to associate with the admin")}</p>

            {Company && Company?.length > 0 && (
                <div>
                    <div className="adminCardDetails mt-2">
                        <LabelField title={t("Administrator of") + ":"} />
                    </div>
                    <CompanyList
                        companies={companies || []}
                        checkedItems={checkedItems ?? new Set<number>()}
                        handleCheckBoxClick={handleCheckBoxClick}
                    />
                </div>
            )}
        </ModalPopup>
    );
};

export default RemoveAdminModal;
