import React, { useContext, useEffect, useState } from "react";
import LinkCoeffEmpContext from "./LinkCoeffEmp/LinkCoeffEmpContext";
import { t, translate } from "../../../CentralDataMangement/translation/Translation";

const ErrorValidation = () => {
    const { state } = useContext(LinkCoeffEmpContext);
    const [currentError, setCurrentError] = useState(null);

    useEffect(() => {
        validateErrors();
    }, [state.lowHighValidation, state.valueErrorArray, state.defaultValueError]);

    const validateErrors = () => {
        clearAllHighlights();

        if (state.lowHighValidation.length > 0) {
            highlightLowHighError(state.lowHighValidation[0]);
            setCurrentError({
                type: 'lowHigh',
                message: t("Please change the highlighted low and high values, low value should be less than high value.")
            });
        } else if (state.valueErrorArray.length > 0) {
            highlightValueError(state.valueErrorArray[0]);
            setCurrentError({
                type: 'value',
                message: t("Value should be in between 0 to 10.")
            });
        } else if (state.defaultValueError.length > 0) {
            highlightDefaultError(state.defaultValueError[0]);
            setCurrentError({
                type: 'default',
                message: t("Default value should be in between low and high values.")
            });
        } else {
            setCurrentError(null);
        }
    };

    const clearAllHighlights = () => {
        const inputElements = document.querySelectorAll('.form-control');
        inputElements.forEach(element => {
            element.classList.remove('warning', 'value-error', 'default-error');
        });
    };

    const highlightLowHighError = (errorKey) => {
        const lowRef = state.inputRef.current[`${errorKey}_1`];
        const highRef = state.inputRef.current[`${errorKey}_3`];
        if (lowRef && highRef) {
            lowRef.classList.add('warning');
            highRef.classList.add('warning');
        }
    };

    const highlightValueError = (errorKey) => {
        const element = state.inputRef.current[errorKey];
        if (element) {
            element.classList.add('value-error');
        }
    };

    const highlightDefaultError = (errorKey) => {
        const element = state.inputRef.current[errorKey];
        if (element) {
            element.classList.add('default-error');
        }
    };

    if (!currentError) return null;

    return (
        <div className="error-message text-danger">
            {currentError.message}
        </div>
    );
};

export default translate(ErrorValidation);


