import React, { useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../common/atoms/Pagination";
import { ApiCall } from "../../../services/ApiServices";
import CustomNotify from "../../common/atoms/CustomNotify";
import { DELETE_ROLE, ROLES } from "../../../routes/ApiEndpoints";
import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import AccessControl from "../../../services/RolesAndPermissions/AccessControl";
import Header from "../../common/layout/Header";
import BackLink from "../../common/atoms/BackLink";
import ConfirmPopup from "../../common/atoms/ConfirmPopup";
import SearchModel from "../../common/atoms/Search";
import OverFlowWrapper from "../../common/OverFlowWrapper";
import TableHeaderSpace from "../../common/atoms/TableHeaderSpace";
import NoRecords from "../../common/atoms/NoRecords";
import CustomDelete from "../../common/molecules/Actions/CustomDelete";
import LabelField from "../../common/atoms/LabelField";
import EditIcon from "../../common/molecules/Actions/CustomEdit";

interface RolesType {
  id: number;
  name?: string;
}

interface Props {
  rolesObj: RolesType[];
}

const ManageRolesPage: React.FC<Props> = ({ rolesObj }) => {
  const [roles, setRoles] = useState(rolesObj);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModelBody] = useState(
    t("Are you sure want to delete") + "?"
  );
  const itemsPerPage = 10;
  // const [isFilter, setIsFilter] = useState<boolean>(true); // Example boolean state

  // Filter the data based on the search query
  const filteredData = roles.filter((item: RolesType) =>
    item.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Get the current items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page when the search query changes
  };

  const deleteRole = (id: number | undefined) => {
    setShowModal(true);
    setDeleteId(id);
  };

  // Handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Modal popup for delete confirmation
  const handleModalClose = () => {
    setShowModal(!showModal);
    setModelBody(t("Are you sure want to delete") + "?");
  };
  const handleSaveChanges = async () => {
    setShowModal(false);
    let body = {
      roleId: deleteID,
      force: false,
    };
    if (modalBody !== t("Are you sure want to delete") + "?") {
      body.force = true;
    }
    const response = await ApiCall.service(
      `${ROLES}/${deleteID}`,
      "DELETE",
      body
    );
    if (response.status === 403) {
      setModelBody(response.msg);
      setShowModal(true);
    } else if (response.status === 200) {
      const newRolesObj = roles.filter(
        (item: RolesType) => item.id !== deleteID
      );
      setRoles(newRolesObj);
      CustomNotify({ type: "success", message: t(response.message) });
    } else {
      CustomNotify({ type: "error", message: t(response.message) });
    }
  };
  return (
    <>
      <div>
        <Header headerName={t("Roles")} headerClassName="myAccountCardTitle" />
        {/* <Title title={t("Roles")} className="px-4" /> */}
        <div className="bg-white" style={{ borderRadius: "2vw" }}>
          <div className="search-bar pb-md-2">
            <div className="row mb-2">
              <BackLink backLink="/"
              />
              <div className="col-8 align-self-center text-center d-block d-lg-none">
                <LabelField
                  title={t("Roles")}
                  className="myAccountCardTitle mb-0"
                />
              </div>
              <div className="col-6 ms-auto text-end">
                <Link to="/role/create">
                  <div className="searchBtn d-inline-flex align-items-center justify-content-center text-decoration-none w-auto px-3">
                    {t("Create role")}
                  </div>
                </Link>
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-md-12 col-lg-8 col-xl-9">
                <SearchModel
                  className="form-control"
                  placeholder={t("Search by name")}
                  handleChange={handleSearchChange}
                  value={searchQuery}
                />
              </div>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Roles",
                    create: true,
                  },
                ]}
              ></AccessControl>
            </div>
          </div>
          <div className="managepagesBg" style={{ borderRadius: "2vw" }}>
            <OverFlowWrapper className="table-responsive manageRoles">
              <table className="table table-hover customTableProperties pwa">
                <thead>
                  <tr className="TableHeader">
                    <th className="border-0">{t("Name")}</th>
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: "Roles",
                          update: true,
                          delete: true,
                        },
                      ]}
                      actions={true}
                      strict={false}
                    >
                      <th className="border-0">{t("Actions")}</th>
                    </AccessControl>
                  </tr>
                  <TableHeaderSpace headerLength={3} />
                </thead>
                <tbody>
                  {currentItems && currentItems.length > 0 ? (
                    currentItems.map((role, index) => (
                      <tr key={role.id} className="border">
                        <td data-label="Name">{t(`${role.name}`)}</td>

                        <AccessControl
                          requiredPermissions={[
                            {
                              permission: "Roles",
                              update: true,
                              delete: true,
                            },
                          ]}
                          actions={true}
                          strict={false}
                        >
                          <td className="table-action-icons px-2">
                            <AccessControl
                              requiredPermissions={[
                                {
                                  permission: "Roles",
                                  update: true,
                                },
                              ]}
                            >
                              <EditIcon href={`/role/edit/${role.id}`} />
                            </AccessControl>

                            <AccessControl
                              requiredPermissions={[
                                {
                                  permission: "Roles",
                                  delete: true,
                                },
                              ]}
                            >
                              {role.id > 9 && role.id !== 22 && (
                                <CustomDelete handleDelete={() => deleteRole(role.id)} />
                              )}
                            </AccessControl>
                          </td>
                        </AccessControl>
                      </tr>
                    ))
                  ) : (
                    <NoRecords headerLength={5} />
                  )}
                </tbody>
              </table>
            </OverFlowWrapper>
            {totalPages > 1 && (
              <div className="pagination justify-content-center align-items-center px-4">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <ConfirmPopup
        show={showModal}
        onHide={handleModalClose}
        title={t("Delete confirmation")}
        body={modalBody}
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
    </>
  );
};

export default translate(ManageRolesPage);
