import React, { ChangeEvent, useState, useRef } from 'react'
import Icon from '../../Icon'
import { t } from '../CentralDataMangement/translation/Translation'
import BackLink from '../common/atoms/BackLink'
import Title from '../common/atoms/Title'
import Header from '../common/layout/Header'
import FileUpload from '../common/molecules/FileUpload'
import { ApiCall } from '../../services/ApiServices'
import CustomNotify from '../common/atoms/CustomNotify'
import { useSelector } from 'react-redux'
import { selectAuth } from '../../features/auth/AuthSlice'
import LabelWithCKEditorField from '../common/molecules/LabelWithCKEditorField'
import LinkCustom from '../common/atoms/Link'
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from '../../Constants'
import LabelField from '../common/atoms/LabelField'
import { REPORT_ISSUE } from '../../routes/ApiEndpoints'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AccessControl from '../../services/RolesAndPermissions/AccessControl'


const ReportIssue = () => {
  interface fileData {
    fileId: number | null
    filePath: string
    fileName: string
    fileAbsoluteUrl: string
  }

  const intialFileData: fileData = {
    fileId: 0,
    filePath: '',
    fileName: '',
    fileAbsoluteUrl: '',
  }


  const ckEditorRef = useRef<HTMLDivElement | null>(null);
  const editorInstanceRef = useRef<{
    getData: () => string
    setData: (data: string) => void
  } | null>(null)

  const companyId = useSelector((state: { auth: { currentCompany: { id: number } } }) => state?.auth?.currentCompany?.id)

  const [description, setDescription] = useState('')
  const [fileDetails, setFileDetails] = useState<fileData>(intialFileData)
  const [descriptionError, setDescriptionError] = useState('');
  //const [fileError, setFileError] = useState<any>('');
  const [fileError, setFileError] = useState<string>('')

  const currentUser = useSelector(selectAuth)

  const handleFileUploadResponse = (uploadResponse: any, index: number) => {
    if (uploadResponse?.response) {
      setFileDetails({
        fileId: uploadResponse?.response?.data?.fileId,
        fileName: uploadResponse?.response?.data?.fileName,
        filePath: uploadResponse?.response?.data?.filePath,
        fileAbsoluteUrl: uploadResponse?.response?.data?.fileUrl,
      });
      setFileError('');
    }
    else {
      setFileError(uploadResponse);
    }
  }

  const handleCKEditorChange = (e: React.ChangeEvent<HTMLInputElement>, editor: ClassicEditor) => {
    editorInstanceRef.current = editor;
    const data = editor?.getData()
    setDescription(data)
  }

  const clearForm = () => {
    setDescription('');
    setFileDetails(intialFileData);
    if (editorInstanceRef?.current) {
      editorInstanceRef?.current?.setData(''); // Clear the editor content using stored instance
    }
  }

  const handleSubmit = async () => {
    let hasError = false;
    setDescriptionError('');

    if (!description?.trim()) {
      setDescriptionError(t('This field is required'));
      hasError = true;
    }

    if (!fileDetails?.fileId) {
      setFileError(t('This field is required'));
      hasError = true;
    }

    if (hasError) {
      return;
    }

    try {
      const dataObj = {
        company_id: companyId ?? 1,
        report_issue_category_id: 1,
        issue: description,
        attachment: fileDetails?.fileId,
        reporter_role_id: currentUser?.roleId ?? 1,
      }

      const response = await ApiCall?.service(
        `${REPORT_ISSUE}`,
        'POST',
        dataObj,
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
      )

      if (response?.status == 'success') {
        CustomNotify({
          message: t('Issue reported successfully!'),
          type: 'success',
          autoClose: 3500,
        })
        clearForm(); // Use the new clearForm function
      } else {
        CustomNotify({
          message: t('Failed to report the issue. Please try again.'),
          type: 'error',
          autoClose: 3500,
        })
      }
    } catch (error) {
      console.error('Error submitting report:', error)
      CustomNotify({
        message: t('An error occurred while reporting the issue.'),
        type: 'error',
        autoClose: 3500,
      })
    }
  }

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "report_issues",
          read: true,
          create: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Header
        headerName={t('Report a problem')}
        headerClassName="myAccountCardTitle"
      />

      <div className="search-bar pb-md-2">
        <div className="row">
          <div className="col-8 align-self-center text-center d-block d-lg-none">
            <LabelField
              title={t("Report a problem")}
              className="myAccountCardTitle mb-0"
            />
          </div>
        </div>
      </div>
      <div className="createpagesBg">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <Title
              className="mt-md-5 myAccountCardTitle mobileFont colorPrimary"
              title={t('Reporting a technical problem')}
            />
            <div className="mt-3 colorPrimary">
              {t(
                'If a feature or product is not working properly, you can provide feedback so we can improve AbsoluteCORE',
              )}
              .
            </div>
            <div className="mt-3 colorPrimary">
              <span className="poppins-medium">{t('PLEASE NOTE')}: &nbsp;</span>
              {t(
                'This is not live assistance. If you need urgent assistance please email')}&nbsp;
              <LinkCustom
                href='mailto:payroll@absoluteyou.be'
                className="colorPrimary"
                title="payroll@absoluteyou.be"
              ></LinkCustom>
            </div>
            <div
              className="mt-3 rounded-3 position-relative"
              style={{
                backgroundColor: '#eeecf3',
                padding: '0.5vw 0.5vw 0.5vw',
              }}
            >
              <div className="bg-white rounded-3 reportTextArea">
                <div style={{ padding: "1vw 1vw 0" }}>
                  <LabelWithCKEditorField
                    label={t("Give a brief explanation of what happened or what is not working")}
                    name="description"
                    placeholder=""
                    handleChange={handleCKEditorChange}
                    ref={ckEditorRef}
                    isMandatory={true}
                  />
                  {descriptionError && <div className='text-danger'>{descriptionError}</div>}
                </div>
                <div
                  className="reportIssue col-12"
                  style={{ paddingBottom: '0.5vw', paddingLeft: '1vw' }}
                >
                  <FileUpload
                    id="report_issue"
                    name="Report_issue"
                    placeholder={t("Upload file")}
                    label="Upload"
                    file_type={14}
                    isMandatory={true}
                    edit={true}
                    fileId={fileDetails?.fileId}
                    fileName={fileDetails?.fileName}
                    filePath={fileDetails?.filePath}
                    fileUrl={fileDetails?.fileAbsoluteUrl}
                    multiple={false}
                    uploadPath="Report_Issues"
                    formats=".pdf,.docx,.doc,.jpg,.jpeg,.png,.xlsx,.xls,.csv,.html,.odt,.pptx,.txt"
                    maxFileSize={2}
                    returnFileUploadErrors={(errorInfo) => {
                      console.error('File upload error:', errorInfo)
                    }}
                    fileUploadResponse={handleFileUploadResponse}
                  />
                </div>
                {fileError && <div className='ms-3 pb-3 text-danger'>{fileError}</div>}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 text-end">
                <div
                  className="d-inline-flex align-items-center cursor-pointer secondaryColorHoverEffect sendText"
                  onClick={handleSubmit}
                >
                  <span className='mobileFont'>{t('Send')}</span>
                  <span className="ms-lg-1 ms-3">
                    <Icon
                      name="paperPlaneIcon"
                      isIcon={true}
                      width={window?.innerWidth <= 767
                        ? "3.5vw"
                        : window?.innerWidth <= 1023
                          ? "1.95vw"
                          : "0.8vw"}
                      height={window?.innerWidth <= 767
                        ? "3.5vw"
                        : window?.innerWidth <= 1023
                          ? "1.95vw"
                          : "0.8vw"}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AccessControl>
  )
}

export default ReportIssue