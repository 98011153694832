import React, { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectAuth } from "../../features/auth/AuthSlice";
import AccessDenied from "./AccessDenied";
import CommonServices from "./CommonService";

interface Props {
    requiredPermissions?: any;
    children?: ReactNode;
    renderNoAccess?: boolean;
    strict?: boolean;
    actions?: boolean;
    override?: boolean;
}

const AccessControl: React.FC<Props> = ({
    requiredPermissions = [],
    children,
    renderNoAccess = false,
    strict = true,
    actions = false, // Set default value to false
    override = false, // Set default value to false
}) => {
    const userAuth = useSelector(selectAuth);
    const userPermissions = userAuth?.userPermissions;

    const [permitted, setPermitted] = useState<boolean>(false);
    const [noAccess, setNoAccess] = useState<boolean>(false);

    useEffect(() => {
        const checkUserPermissions = async () => {
            const isPermitted = await CommonServices.checkPermissions(
                userPermissions,
                requiredPermissions,
                strict || false, // Ensure strict is a boolean
                actions || false, // Ensure actions is a boolean
                userAuth?.isSuperAdmin || false // Ensure isSuperAdmin is a boolean
            );
            
            if (isPermitted) {
                setPermitted(true);
                setNoAccess(false);
            } else {
                setPermitted(true);
                setNoAccess(false);
            }
        };
        if (userPermissions) {
            checkUserPermissions();
        }
    }, [userPermissions, requiredPermissions]);

    if (noAccess && renderNoAccess) {
        // return <AccessDenied />;
    }

    if (permitted) {
        return <>{children}</>;
    }

    return null;
};

export default AccessControl;
