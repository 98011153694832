import React, { useState, useEffect } from "react";
import Icon from "../../Icon";
import DateAndTime from "../common/atoms/DateWithTime";
import TimePicker from "../common/atoms/TimePicker";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import InputTextfield from "../common/atoms/InputTextField";
import { addConvertDecimalToTime } from "../common/utlis/dateHelper";
import CheckBoxField from "../common/atoms/CheckBoxField";
import { formatTimeInput, calculateShiftsTotalTime } from "./Services/PlanningTimingHelper";
import TableHeaderSpace from "../common/atoms/TableHeaderSpace";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";
import TimeRegistrationOverview from "../TimeRegistration/TimeRegistrationOverview";

interface Props {
  parentIndex: number;
  showLastAction?: boolean;
  isTimeRegistration?: boolean;
  category?: "open" | "closed";
  activeEmployee?: any;
  schedule?: any;
  date?: string;
  shiftIndex: number;
  onEncodageChange: (updatedEncodage: any) => void;
  onCheckboxChange: (employee_id: number, date: string) => void;
  selectedDates: string[];
}

interface ErrorState {
  shift1Start: string;
  shift1End: string;
  shift2Start: string;
  shift2End: string;
  totalHours: string;
  overlap: string;
}

const ShiftTiming: React.FC<Props> = ({
  parentIndex,
  showLastAction = false,
  isTimeRegistration = false,
  category = "open",
  activeEmployee,
  schedule,
  date,
  shiftIndex,
  onEncodageChange,
  onCheckboxChange,
  selectedDates = []
}) => {
  const iconStyle = useResponsiveValue("3vw", "2.5vw", "0.8vw");
  const IconProps = {
    width: iconStyle,
    height: iconStyle,
    isIcon: true,
  };

  const [errors, setErrors] = useState<ErrorState>({
    shift1Start: "",
    shift1End: "",
    shift2Start: "",
    shift2End: "",
    totalHours: "",
    overlap: "",
  });

  const timeToMinutes = (timeStr: string): number => {
    if (!timeStr) return 0;
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 + minutes;
  };

  // Check if two time ranges overlap
  const checkOverlap = (encodage: any[]) => {
    // If shift 2 timings are not provided, no overlap error

    if (!encodage[1]?.start_time && !encodage[1]?.end_time) {
      setErrors(prev => ({
        ...prev,
        overlap: ""
      }));
      return;
    }


    const shift1Start = timeToMinutes(encodage[0].start_time);
    const shift1End = timeToMinutes(encodage[0].end_time);
    const shift2Start = timeToMinutes(encodage[1].start_time);
    const shift2End = timeToMinutes(encodage[1].end_time);

    // Normalized times for overnight shifts
    let normalizedShift1End = shift1End;
    let normalizedShift2End = shift2End;

    // If end time is less than start time, add 24 hours (1440 minutes)
    if (shift1End < shift1Start) normalizedShift1End += 1440;
    if (shift2End < shift2Start) normalizedShift2End += 1440;

    // Check for overlap
    if ((shift2Start < normalizedShift1End && normalizedShift2End > shift1Start) ||
      (shift1Start < normalizedShift2End && normalizedShift1End > shift2Start)) {
      setErrors(prev => ({
        ...prev,
        overlap: "Shift 1 and Shift 2 timings overlap."
      }));
    } else {
      setErrors(prev => ({
        ...prev,
        overlap: ""
      }));
    }

  };


  const validateTotalHours = (encodage: any[]): boolean => {
    let totalMinutes = 0;

    encodage.forEach(shift => {
      if (shift.start_time && shift.end_time) {
        const [startHour, startMin] = shift.start_time.split(':').map(Number);
        const [endHour, endMin] = shift.end_time.split(':').map(Number);

        let minutes = (endHour * 60 + endMin) - (startHour * 60 + startMin);
        if (minutes < 0) {
          minutes += 24 * 60;
        }
        totalMinutes += minutes;
      }
    });

    const totalHours = totalMinutes / 60;

    if (totalHours >= 16) {
      setErrors(prev => ({
        ...prev,
        totalHours: t("Total working hours cannot exceed or equals to 16 hours per day")
      }));
      return false;
    }

    setErrors(prev => ({ ...prev, totalHours: "" }));
    return true;
  };

  const validateTimeFormat = (value: string): boolean => {
    return /^([01]?\d|2[0-3])?:?([0-5]?\d)?$/.test(value);
  };

  const handleChange = (field: string, value: string, shiftIndex: number, parentIndex: number) => {
    const formattedValue = formatTimeInput(value);

    const errorField = `shift${shiftIndex + 1}${field.includes('start') ? 'Start' : 'End'}` as keyof ErrorState;

    if (!validateTimeFormat(value)) {
      setErrors(prev => ({
        ...prev,
        [errorField]: t("Invalid time format. Please use HH:MM format.")
      }));
      return;
    }

    setErrors(prev => ({
      ...prev,
      [errorField]: ""
    }));

    const updatedEncodage = schedule.encodage.map((enc: any, encIndex: number) =>
      encIndex === shiftIndex ? { ...enc, [field]: formattedValue } : enc
    );

    checkOverlap(updatedEncodage);
    validateTotalHours(updatedEncodage);


    onEncodageChange(updatedEncodage);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (date && !errors.totalHours && !errors.overlap) {
      onCheckboxChange(activeEmployee.employee_id, date);
    }
  };

  const shiftCells = (parentIndex: number, index: number, typeIndex: number, type: string, data: any) => {
    const categories = ["Planned hours", "Clocked-in hours", "Effective hours worked"];
    return (
      <>
        {window.innerWidth <= 1023 &&
          <tr className="mt-3">
            <td
              key={category}
              colSpan={3}
              className="text-center categorySection categorySectionPwa px-0"
            >
              <div className="d-flex align-items-center justify-content-center">
                <Icon name="lockIcon" {...IconProps} fillColor="#87abb8" />
                <span className="ms-2 mobileFont">{t(categories[typeIndex])}</span>
              </div>
            </td>
          </tr>
        }
        <td
          key={`shift1-${0}`}
          className="bodyBg align-middle"
          data-label={t("Shift") + " 1"}
        >
          <div className="d-flex align-items-center justify-content-center">
            <div className="d-flex me-2">
              <InputTextfield
                value={data?.[0]?.start_time}
                className={`form-control employeeDailyOverviewTable ${typeIndex === 2 && errors.shift1Start ? "border border-danger" : ""}`}
                handleChange={(e) => handleChange("start_time", e.target.value, 0, parentIndex)}
                isDisabled={typeIndex === 0 || typeIndex === 1 || typeIndex === 2 || category === 'closed'}
              />
              {typeIndex === 2 && errors.shift1Start && (
                <div className="text-danger ms-2 helpDangerIcon" title={errors.shift1Start}>
                  <div className="d-none d-lg-block">
                    <Icon isIcon={true} width={"0.8vw"} height={"0.8vw"} name="infoIcon" />
                  </div>
                </div>
              )}
            </div>

            <div className="d-flex">
              <InputTextfield
                value={data?.[0]?.end_time}
                className={`form-control employeeDailyOverviewTable ${typeIndex === 2 && errors.shift1End ? "border border-danger" : ""}`}
                handleChange={(e) => handleChange("end_time", e.target.value, 0, parentIndex)}
                isDisabled={typeIndex === 0 || typeIndex === 1 || category === 'closed'}
              />
              {typeIndex === 2 && errors.shift1End && (
                <div className="text-danger ms-2 helpDangerIcon" title={errors.shift1End}>
                  <div className="d-none d-lg-block">
                    <Icon isIcon={true} width={"0.8vw"} height={"0.8vw"} name="infoIcon" />
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* {typeIndex === 2 && errors.shift1End && (
            <div className="d-block d-lg-none text-danger">
              {errors.shift1End}
            </div>
          )}
          {typeIndex === 2 && errors.shift1Start && (
            <div className="d-block d-lg-none text-danger">
              {errors.shift1Start}
            </div>
          )} */}
        </td>

        <td
          key={`shift2-${1}`}
          className="bodyBg align-middle"
          data-label={t("Shift") + " 2"}
        >
          <div className="d-flex align-items-center justify-content-center">
            <div className="d-flex me-2">
              <InputTextfield
                value={data?.[1]?.start_time}
                className={`form-control employeeDailyOverviewTable ${typeIndex === 2 && errors.shift2Start ? "border border-danger" : ""}`}
                handleChange={(e) => handleChange("start_time", e.target.value, 1, parentIndex)}
                isDisabled={typeIndex === 0 || typeIndex === 1 || data?.[1]?.start_time == null || category === 'closed'}
              />
              {typeIndex === 2 && errors.shift2Start && (
                <div className="text-danger ms-2 helpDangerIcon" title={errors.shift2Start}>
                  <div className="d-none d-lg-block">
                    <Icon isIcon={true} width={"0.8vw"} height={"0.8vw"} name="infoIcon" />
                  </div>
                </div>
              )}
            </div>

            <div className="d-lg-flex">
              <InputTextfield
                value={data?.[1]?.end_time}
                className={`form-control employeeDailyOverviewTable ${typeIndex === 2 && errors.shift2End ? "border border-danger" : ""}`}
                handleChange={(e) => handleChange("end_time", e.target.value, 1, parentIndex)}
                isDisabled={typeIndex === 0 || typeIndex === 1 || data?.[1]?.start_time == null || category === 'closed'}
              />
              {typeIndex === 2 && errors.shift2End && (
                <div className="text-danger ms-lg-2 helpDangerIcon" title={errors.shift2End}>
                  <div className="d-none d-lg-block">
                    <Icon isIcon={true} width={"0.8vw"} height={"0.8vw"} name="infoIcon" />
                  </div>
                </div>
              )}

              {typeIndex === 2 && errors.overlap && (
                <div className="text-danger ms-lg-2 helpDangerIcon" title={errors.overlap}>
                  <div className="d-none d-lg-block">
                    <Icon isIcon={true} width={"0.8vw"} height={"0.8vw"} name="infoIcon" />
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* {typeIndex === 2 && errors.shift2Start && (
            <div className="d-block d-lg-none text-danger">
              {errors.shift2Start}
            </div>
          )}
          {typeIndex === 2 && errors.shift2End && (
            <div className="d-block d-lg-none text-danger">
              {errors.shift2End}
            </div>
          )} */}

        </td>

        <td
          key={`total-${index}`}
          className="bodyBg align-middle"
          data-label={t("Total")}
        >
          <div className="totalHours text-white poppins-medium">
            {(typeIndex === 0 || typeIndex === 1) &&
              addConvertDecimalToTime(
                data?.[0]?.total_count || "0",
                data?.[1]?.total_count || "0"
              )}
            {typeIndex === 2 && calculateShiftsTotalTime(schedule.encodage)}

            {typeIndex === 2 && errors.totalHours && (
              <div className="text-danger ms-lg-2 helpDangerIcon" title={errors.totalHours}>
                <div className="d-none d-lg-block">
                  <Icon isIcon={true} width={"0.8vw"} height={"0.8vw"} name="infoIcon" />
                </div>
              </div>
            )}
          </div>
        </td>
      </>
    );
  };

  return (
    <table
      className={`${isTimeRegistration ? "timeRegistrationTable" : "shiftTimingTable"} table table-borderless mb-0 pwa`}
    >
      {shiftIndex == 0 && <thead>
        {isTimeRegistration ? (
          <>
            <tr>
              <th></th>
              {[...Array(3)].map((_, index) => (
                <React.Fragment key={index}>
                  <th className="text-center shiftNumber">
                    {t("Shift") + " 1"}
                  </th>
                  <th className="text-center shiftNumber">
                    {t("Shift") + " 2"}
                  </th>
                  <th className="text-start text-center shiftNumber">
                    {t("Total")}
                  </th>
                </React.Fragment>
              ))}
              <th></th>
            </tr>
          </>
        ) : (
          <tr>
            <th></th>
            <th>{t("Shift") + " 1"}</th>
            <th>{t("Shift") + " 2"}</th>
            <th className="text-start">{t("Total")}</th>
            <th></th>
          </tr>
        )}
      </thead>}
      <tbody>
        {isTimeRegistration &&
          <React.Fragment key={shiftIndex}>
            <tr className="spaced-row align-middle">
              <td className="timeRegistrationDate text-white text-center" style={{ whiteSpace: "nowrap" }}>
                {(() => {
                  if (!date) return "";
                  const dateObj = new Date(date);
                  const dayName = new Intl.DateTimeFormat("en-GB", { weekday: "short" }).format(dateObj);
                  const dayNumber = dateObj.getDate();
                  return (
                    <>
                      <div>{t(dayName)}</div>
                      <div>{dayNumber}</div>
                    </>
                  );
                })()}
              </td>

              {shiftCells(parentIndex, shiftIndex, 0, category, schedule?.schedule)}
              {shiftCells(parentIndex, shiftIndex, 1, category, schedule?.time_registraion)}
              {shiftCells(parentIndex, shiftIndex, 2, category, schedule?.encodage)}
              {category === 'open' && (
                <td className="px-2">
                  {window.innerWidth > 1023 ? (
                    <CheckBoxField
                      name="validate"
                      isChecked={date ? selectedDates.includes(date) : false}
                      onChangeHandler={handleCheckboxChange}
                      disable={!!errors.totalHours || !!errors.overlap}
                    />
                  ) : (
                    <div
                      className="searchBtn d-flex align-items-center justify-content-center w-75 mx-auto"
                      onClick={() => {
                        if (date) {
                          handleCheckboxChange({
                            target: { checked: !selectedDates.includes(date) }
                          } as React.ChangeEvent<HTMLInputElement>);
                        }
                      }}
                    >
                      {t("Validate")}
                    </div>
                  )}
                </td>
              )}
            </tr>
            {window.innerWidth > 1023 && <TableHeaderSpace headerLength={`${isTimeRegistration ? 11 : 5}`} />}
          </React.Fragment>
        }
      </tbody>
    </table>
  );
};

export default translate(ShiftTiming);