import { AES, enc } from "crypto-ts";

const key = 'H98zM6i/55yNJfkFsbu0HrzlFo17FtR9';
const iv = key.slice(0, 16);
const parsedKey = enc.Utf8.parse(key);
const parsedIv = enc.Utf8.parse(iv);

const initializeLocalStorage = () => {
    const persistRoot = localStorage?.getItem('persist:root');
    
    if (!persistRoot) {
        const initialState = {
            auth: JSON.stringify({
                userId: null,
                token: null,
                role: null,
                isAuthenticated: false
            })
        };
        localStorage?.setItem('persist:root', JSON.stringify(initialState));
    }
};

const EncryptDecryptService = {
    encryptData: (data: string): string => {
        initializeLocalStorage();
        const ciphertext = AES?.encrypt(data, parsedKey, { iv: parsedIv })?.toString();
        return ciphertext;
    },
    
    decryptData: (encryptedData: string): string => {
        try {
            initializeLocalStorage();
            const decrypted = AES?.decrypt(encryptedData, parsedKey, { iv: parsedIv });
            const decryptedData = decrypted?.toString(enc.Utf8);
            return decryptedData || "{}";
        } catch (error) {
            console.warn("Decryption failed, returning empty object");
            return "{}";
        }
    }
};

export default EncryptDecryptService;