import React from 'react'
import { t } from '../../../CentralDataMangement/translation/Translation'
import LoadImage from "../../../../static/images/LoginImage.png";
import Icon from '../../../../Icon';
import LabelField from '../../../common/atoms/LabelField';
import IconWithTitle from '../../../common/molecules/IconWithTitle';
import InvoicesEnquiry from '../../../Invoies/InvoicesEnquiry';
import { useResponsiveValue } from '../../../common/atoms/ResponsivWidth';
import LinkCustom from '../../../common/atoms/Link';

interface props {
  handleClick?: (actionType: "mail" | "contact") => void;
}
const ForgotPasswordInfo: React.FC<props> = ({
  handleClick,
}) => {
  const IconStyle = useResponsiveValue("3.5vw", "4.5vw", "1.8vw");
  const items = [
    { name: "Time", title: "SAVE TIME" },
    { name: "pathway", title: "FLEXIBLE" },
    { name: "protection", title: "WEEKLY PAY" },
    { name: "timeSlot", title: "24/7 CONTRACTING" },
    { name: "security", title: "SAFETY" },
    { name: "shakeHands", title: "SECURITY" },
    { name: "Headset", title: "ANSWERS TO ALL YOUR QUESTIONS" },
    { name: "editAgreement", title: "CONTRACTING TOOL" },
  ];
  const IconProps = {
    isIcon: true,
    width: IconStyle,
    height: IconStyle,
    className: "secondaryColorHoverEffect cursor-pointer",
  };
  const handleLoginClick = () => {
    return "/login"
  }
  return (
    <div className="container-fluid">
      <div className="loginWrapper">
        <div className="row gx-5">
          <div className="col-4">
            <div className="loginLeftSection text-center h-100 position-relative">
              <div className="loginLeftSectionContent text-white">
                {t("Completely")}
                <span className="colorYellow ms-1">{t("digital")},</span>
                <br />
                {t("but with a")}
                <br />
                {t("smile")}!
              </div>
              <div className="loginImageWrapper">
                <img src={LoadImage} alt={t("login")} className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="col-4 loginMiddleSection">
            <div className="card border-0 loginCard h-100 rounded-0 d-grid align-content-center">
              <div className="loginCardPadding">
                <div className="text-center">
                  <LinkCustom href={handleLoginClick()} className="headerLogo">
                    <Icon name="Logo" width="15vw" height="3vw" isIcon={true} className="img-fluid mx-auto" />
                  </LinkCustom>
                </div>
                <div className="loginMiddleSectionContent text-center">
                  <div className="colorSkyBlue loginInText py-3">
                    {t("Forgot password")}
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <LabelField title={t("Check your mailbox")} className="myAccountCardTitle mb-4" />
                    </div>
                    <div className="col-12">
                      <LabelField title={t("If your e-mail address is known in our system, we have sent an e-mail with a link to reset your password") + "."} className='mb-3' />
                    </div>
                    <div className="col-12">
                      <LabelField title={t("Not receiving email? Some TIPS:")} className="myAccountCardTitle mb-4" />
                    </div>
                    <div className="col-12 colorPrimary forgotPasswordHintText mb-3">
                      <div className='d-flex align-content-center text-start mb-2'>
                        <span className='forgotPasswordHints'>1</span>
                        <span>
                          {t("Check that your e-mail address is effectively the one used when creating your account") + "."}
                        </span>
                      </div>
                      <div className='d-flex align-items-center text-start mb-2'>
                        <span className='forgotPasswordHints'>2</span>
                        <span>
                          {t("Check that your e-mail address is complete") + "."}
                        </span>
                      </div>
                      <div className='d-flex align-items-center text-start mb-2'>
                        <span className='forgotPasswordHints'>3</span>
                        <span>
                          {t("If you fail to receive a link, you can contact AbsoluteYOU for support") + "."}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 forgotLinkWrapper pb-3">
                      <LinkCustom
                        href="/login"
                        className="linkColor mobileFon"
                        title={t("Back to login")}
                      />
                    </div>
                    <div className="col-12">
                      <LabelField title={t("Contact AbsoluteYOU") + ":"} className='myAccountCardTitle mb-3' />
                    </div>
                    <div className="col-12 mb-3">
                      <div className="row">
                        <div className="col-10 text-end">
                          <div className="text-center ps-4 ms-5">
                            <span
                              title={t("Send Mail")}
                              onClick={() => handleClick?.("mail")}
                              className="me-2"
                            >
                              <Icon {...IconProps} name="MailIcon" />
                            </span>
                            <span title={t("Conatct us")} onClick={() => handleClick?.("contact")}>
                              <Icon {...IconProps} name="callIcon" />
                            </span>
                          </div>
                        </div>
                        <div className="col-2 text-end align-self-center">
                          <span title={t("Conatct us")} onClick={() => handleClick?.("contact")}>
                            <Icon {...IconProps} name="circleQuestion" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="loginRightSection h-100">
              <div className="row">
                {items.map(item => (
                  <div className="col-6 text-center" key={item?.name}>
                    <IconWithTitle
                      name={item?.name}
                      isIcon={true}
                      isTitle={true}
                      width="10vw"
                      height="2vw"
                      className="img-fluid mx-auto"
                      title={t(`${item?.title}`)}
                      borderRadius={"10vw"}
                      labelClassName="text-white loginRightSectionTitle"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordInfo