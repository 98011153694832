import { validateDefEmpLegal, validateEmail, validateMultiChoiceAnswer, validatePhoneNumberWithRegex, validatesLinkedCompanies } from "../../../services/ValidationService";
import { FormErrors } from "../registration/Interfaces";
import { EmployeeInviteState } from "./Interfaces";

export const validate = (state: EmployeeInviteState) => {
    let errors: FormErrors = {};
    
    // Filter default_emp_legal to include only available companies
    const availableDefEmpLegal = state.formData.default_emp_legal.filter(defEmp =>
        state.formData.companies.includes(defEmp.company_id as number)
    );

    // Validate only for available companies
    errors = validateDefEmpLegal(availableDefEmpLegal, errors);
    errors['verify_email'] = validateEmail(state.formData.email);
    errors['phone_number'] = validatePhoneNumberWithRegex(state.formData.phone_number);
    errors['companies'] = validateMultiChoiceAnswer(state.formData.companies);
    
    if (errors['companies'] === undefined) {
        const missingCompanies = validatesLinkedCompanies(state.formData.companies, availableDefEmpLegal);
        errors['companies'] = missingCompanies?.length > 0
            ? `Please ensure the selected company has at least one 'Function' and 'Hourly Wage' details.`
            : null;
        state.missing_companies = missingCompanies;
    }

    return {
        validated: Object.values(errors).every(error => error === null || error === '' || error === undefined),
        errors: errors
    };
};
