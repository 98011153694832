import React, { useEffect, useState } from "react";
import { t } from "../common/atoms/Constants";
import Header from "../common/layout/Header";
import { Tab, Tabs } from "react-bootstrap";
import "./css/Agreement.css";
import AbsoluteyouAgent from "./AbsoluteyouAgent";
import CompanyInformation from "./CompanyInformation";
import ContactPerson from "./ContactPerson";
import OnlineDetails from "./OnlineDetails";
import SalaryBenifits from "./SalaryBenefits";
import Invoicing from "./Invoicing";
import { GET_AGREEMENT_DETAILS, GET_SALES_OPTIONS, REGISTER_COUNTRY_LIST, UPDATE_AGREEMENT_DETAILS } from "../../routes/ApiEndpoints";
import { ApiCall } from "../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import { CompanyContactPersonDetails, CooperationAgreementState, Formdata } from "./Interfaces";
import { CompanyInputFields, CompanyNonMandatoryFields, ContactinputFields, ContactNonMandatoryFields, initialState } from "./State";
import BackLink from "../common/atoms/BackLink";
import { showNotification } from "../../services/common/CommonServices";
import { validateForm, validateOnlineDetails, validateSection } from "./Service";

// Data array for tabs
const tabData = [
  {
    eventKey: "MainDetail",
    step: "Step 1",
    title: "AbsoluteYou Agent",
    component: AbsoluteyouAgent,
  },
  {
    eventKey: "CompanyConfig",
    step: "Step 2",
    title: "Company Information",
    component: CompanyInformation,
  },
  {
    eventKey: "CompanyContactPersonDetails",
    step: "Step 3",
    title: "Contact Persons",
    component: ContactPerson,
  },
  {
    eventKey: "CooperationAgreementOnlineDetails",
    step: "Step 4",
    title: "Online Details",
    component: OnlineDetails,
  },
  {
    eventKey: "salaryBenefits",
    step: "Step 5",
    title: "Salary Benefits",
    component: SalaryBenifits,
  },
  {
    eventKey: "CooperationAgreementInvoiceConfig",
    step: "Step 6",
    title: "Invoicing",
    component: Invoicing,
  },
];

interface Option {
  id?: number
  value?: number | string | null;
  label?: string;
}


const AgreementForm: React.FC = () => {
  const [formState, setFormState] = useState<CooperationAgreementState>(initialState);
  const [countries, setCountries] = useState<Option[]>([]);
  const [activeTab, setActiveTab] = useState("MainDetail");
  const handleNext = () => {
    const currentIndex = tabData?.findIndex((tab) => tab?.eventKey === activeTab);
    if (currentIndex === tabData?.length - 1) {
      handleSubmit();
      return;
    }
    setActiveTab(tabData[currentIndex + 1]?.eventKey);
  };


  const handlePrevious = () => {
    const currentIndex = tabData?.findIndex((tab) => tab?.eventKey === activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabData?.[currentIndex - 1]?.eventKey);
    }
  };

  const handleSubmit = () => {
    let hasErrors = false;
    const allErrors: { [key: string]: unknown } = {};

    // Define sections and their validation logic
    const sections = [
      { key: "CompanyConfig", validate: validateSection, data: formState?.formData?.CompanyConfig, fields: CompanyInputFields, nonMandatoryFields: CompanyNonMandatoryFields, isMultiple: false },
      { key: "CompanyContactPersonDetails", validate: validateSection, data: formState?.formData?.CompanyContactPersonDetails, fields: ContactinputFields, nonMandatoryFields: ContactNonMandatoryFields, isMultiple: true },
      { key: "CooperationAgreementInvoiceConfig", validate: validateForm, data: formState?.formData?.CooperationAgreementInvoiceConfig, fields: [], nonMandatoryFields: [], isMultiple: false },
      { key: "CooperationAgreementOnlineDetails", validate: validateOnlineDetails, data: formState?.formData?.CooperationAgreementOnlineDetails, fields: [], nonMandatoryFields: [], isMultiple: false },
    ];

    // Validate each section
    sections?.forEach(({ key, validate, data, fields, nonMandatoryFields, isMultiple }) => {
      const errors = isMultiple
        ? validateMultiple(data, key, validate, fields, nonMandatoryFields)
        : validateSingle(data, key, validate, fields, nonMandatoryFields);
      if (errors && Object.keys(errors)?.length > 0) {
        allErrors[key] = errors;
        hasErrors = true;
      }
    });

    // Update errors and handle submission if no errors
    if (hasErrors) {
      setFormState(prevState => ({
        ...prevState,
        errors: allErrors
      }));
      console.log("Form submission blocked due to validation errors");
      return;
    }

    console.log("Form submitted");
  };
  // Validate multiple entries (arrays)
  const validateMultiple = (data: {}, key: string, validateFn: Function, fields: unknown[], nonMandatoryFields: unknown[]) => {
    if (Array.isArray?.(data)) {
      const errors = data.map((item) => validateFn?.(key, item, fields, nonMandatoryFields));
      return errors?.filter?.((error: Error) => Object?.keys(error)?.length > 0);
    }
    return {};
  };

  // Validate a single entry
  const validateSingle = (data: unknown, key: string, validateFn: Function, fields: unknown[], nonMandatoryFields: unknown[]) => {
    if (key === "CooperationAgreementInvoiceConfig" || key === "CooperationAgreementOnlineDetails") {
      const errors = validateFn?.(data);
      return errors;
    }
    if (data) {
      return validateFn?.(key, data, fields, nonMandatoryFields);
    }
    return {};
  };

  const handleRadioChange = (name: string, value: boolean, section: keyof Formdata) => {
    setFormState((prevState) => {
      if (!prevState) return prevState;

      return {
        ...prevState,
        formData: {
          ...prevState?.formData,
          [section]: {
            ...prevState?.formData?.[section],
            [name]: value,
          },
        },
      };
    });
  };

  const handleSaveAsDraftClick = async () => {
    try {
      // Update the formState by changing the status to 2 before sending the request
      const updatedFormData = {
        ...formState?.formData,
        MainDetail: {
          ...formState?.formData?.MainDetail,
          status: 2, // Save as draft status is 2
        },
      };

      // Send the updated form data in the API request
      const response = await ApiCall.service(
        `${UPDATE_AGREEMENT_DETAILS}`,
        "PUT",
        updatedFormData,
        true
      );
      // Response handling logic
      if (response?.status === "success") {
        showNotification(
          "success",
          t("Details Saved in Draft successfully.")
        );
      } else if (response?.status === "success") {
        showNotification("success", response?.message);
      } else if (response?.status === "validation-error") {
        const { data } = response;

        // Combine error messages into a single string for notification
        const errorMessage = Object.values(data).join(", ");
        showNotification("error", errorMessage);

      } else {
        showNotification("error", response?.message);
      }
    } catch (error) {
      console.error("Error in Saving details:", error);
    }
  };

  // Fetch the agreement details from the API
  const fetchAgreement = async () => {
    const url = GET_AGREEMENT_DETAILS;
    const obj = {
      company_id: 2587,
    };
    try {
      const result = await ApiCall?.service?.(url, "POST", obj, true, CENTRAL_DATA_MANAGEMENT_MICROSERVICE);
      setFormState((prevState: CooperationAgreementState) => ({
        ...prevState,
        formData: result,
        errors: {},
      }));
    } catch (error) {
      console.error("Error fetching agreement data:", error);
    }
  };

  const fetchCountryOptions = async () => {
    const url = REGISTER_COUNTRY_LIST;
    try {
      const result = await ApiCall?.getService?.(url, "GET");
      setCountries((prev) => ({
        ...prev,
        countries: result?.data || [],
      }));
    } catch (error) {
      console?.error("Error fetching country data:", error);
    }
  };

  const fetchSalesOptions = async () => {
    try {
      const result = await ApiCall?.getService?.(GET_SALES_OPTIONS, "GET");
      setFormState((prevState) => ({
        ...prevState,
        offices: result?.data?.offices,
        sales: result?.data?.sales,
      }));
    } catch (error) {
      console?.error("Error fetching sales options data:", error);
    }
  }

  useEffect(() => {
    fetchAgreement();
    fetchCountryOptions();
    fetchSalesOptions();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | Option | any,
    section: keyof Formdata
  ) => {
    const { name, value } = e?.target;
    if (formState?.formData) {
      setFormState((prevState: CooperationAgreementState) => {
        if (prevState) {
          if (name.includes(".")) {
            const [parentKey, childKey] = name?.split(".");
            return {
              ...prevState,
              formData: {
                ...prevState?.formData,
                [section]: {
                  ...prevState?.formData[section],
                  [parentKey]: {
                    ...(prevState?.formData?.[section] as Record<string, any>)[parentKey],
                    [childKey]: value,
                  },
                },
              }
            };
          }
          // Handle simple fields (non-nested)
          return {
            ...prevState,
            formData: {
              ...prevState?.formData,
              [section]: {
                ...prevState?.formData?.[section],
                [name]: value,
              },
            }
          };
        }
        return prevState;
      });
    }
  };

  const handleLegalEntityMailChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | Option | any,
    section: keyof Formdata
  ) => {
    const { value } = e?.target;
    if (formState?.formData) {
      setFormState((prevState: CooperationAgreementState) => {
        if (prevState) {
          return {
            ...prevState,
            formData: {
              ...prevState?.formData,
              [section]: {
                ...prevState?.formData[section],
                legal_entity_mail: value,  // Update 'legal_entity_mail'
              },
              CooperationAgreementOnlineDetails: {
                ...prevState?.formData?.CooperationAgreementOnlineDetails,
                online_prestaties_mail: value,   // Set the same value for all the mail keys
                prestatieblad_mail: value,
                digital_mail: value,
                digital_contract_mail: value,
                invoice_reminder_mail: value,
              },
            },
          };
        }
        return prevState;
      });
    }
  };

  const handleContactChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | Option | any,
    section: keyof Formdata,
    index: number
  ) => {
    const { name, value } = e?.target;
    if (formState?.formData) {
      setFormState((prevState: CooperationAgreementState) => {
        if (prevState) {
          if (section === "CompanyContactPersonDetails" && Array.isArray(prevState?.formData?.[section])) {
            const updatedArray = [...prevState?.formData[section]];

            // Check if the index is within bounds
            if (updatedArray[index]) {
              if (name.includes(".")) {
                const keys = name.split(".");

                // Helper function to update deeply nested objects
                const updateNestedObject: any = (obj: any, keys: string[], value: any) => {
                  const [key, ...restKeys] = keys;

                  // If no more nested keys, update the value at this key
                  if (restKeys.length === 0) {
                    return { ...obj, [key]: value };
                  }

                  // Otherwise, recursively update the nested object
                  return {
                    ...obj,
                    [key]: updateNestedObject(obj[key] || {}, restKeys, value),
                  };
                };

                // Update the specific nested object field
                updatedArray[index] = updateNestedObject(updatedArray[index], keys, value);
              } else {
                updatedArray[index] = {
                  ...updatedArray[index],
                  [name]: value,
                };
              }
            }

            // Return the updated state
            return {
              ...prevState,
              formData: {
                ...prevState?.formData,
                [section]: updatedArray,
              },
            };
          }
        }

        return prevState;
      });
    }
  };

  const handleSelectChange = (
    selectedOption: Option | null,
    fieldPath: string,
    section: keyof Formdata
  ) => {
    const value = selectedOption ? selectedOption?.value : null;
    const pathParts = fieldPath?.split(".");


    if (formState?.formData) {
      setFormState((prevState: CooperationAgreementState) => {
        if (prevState) {
          // For nested fields
          if (pathParts.length > 1) {
            const [parentKey, childKey] = pathParts;
            return {
              ...prevState,
              formData: {
                ...prevState?.formData,
                [section]: {
                  ...prevState?.formData?.[section],
                  [parentKey]: {
                    ...(prevState?.formData?.[section] as any)[parentKey],
                    [childKey]: value,
                  },
                },
              }
            };
          }
          // For non-nested fields
          return {
            ...prevState,
            formData: {
              ...prevState?.formData,
              [section]: {
                ...prevState?.formData?.[section],
                [fieldPath]: value,
              },
            }
          };
        }
        return prevState;
      });
    }
  };

  const handleContactSelectChange = (
    selectedOption: Option | null,
    section: keyof Formdata,
    index: number
  ) => {
    const value = selectedOption ? selectedOption.label : null;
    const fieldPathLabel = "contact_person_duties.location.label";
    const fieldPathId = "contact_person_duties.location_id";

    if (formState?.formData) {
      setFormState((prevState: CooperationAgreementState) => {
        if (prevState) {
          //ensure that formData[section] is an array
          const sectionData = prevState?.formData?.[section];

          // Check if the section is an array and proceed
          if (Array.isArray(sectionData)) {
            const updatedArray = [...sectionData];

            // Check if the index is valid within the array
            if (updatedArray[index]) {
              // Update location.label
              updatedArray[index] = updateNestedField(updatedArray?.[index], fieldPathLabel, value);

              // Update location_id
              const locationId = selectedOption ? selectedOption?.id : null;
              updatedArray[index] = updateNestedField(updatedArray?.[index], fieldPathId, locationId);
            }

            // Return the updated state
            return {
              ...prevState,
              formData: {
                ...prevState?.formData,
                [section]: updatedArray,
              },
            };
          } else {
            console.error(`The section "${section}" is not an array. Cannot update.`);
          }
        }
        return prevState;
      });
    }
  };

  const handleDeleteContactPerson = (
    section: keyof Formdata,
    index: number
  ) => {
    if (formState?.formData) {
      setFormState((prevState: CooperationAgreementState) => {
        if (prevState) {
          if (section === "CompanyContactPersonDetails" && Array.isArray(prevState?.formData?.[section])) {
            const updatedArray = [...prevState?.formData[section]];

            // Check if the index is within bounds
            if (updatedArray[index]) {
              updatedArray[index] = {
                ...updatedArray[index],
                status: false,
              };
            }

            // Return the updated state with modified section
            return {
              ...prevState,
              formData: {
                ...prevState?.formData,
                [section]: updatedArray,
              },
            };
          }
        }

        // If no match, return the previous state
        return prevState;
      });
    }
  };

  // Helper function to update a nested field in the object
  const updateNestedField = (obj: any, fieldPath: string, value: any): any => {
    const keys = fieldPath.split(".");
    const update = (currentObj: any, keys: string[], value: any): any => {
      const [currentKey, ...remainingKeys] = keys;

      // If there are no more keys, set the value
      if (remainingKeys.length === 0) {
        return {
          ...currentObj,
          [currentKey]: value,
        };
      }

      // Otherwise, continue traversing recursively
      return {
        ...currentObj,
        [currentKey]: update(currentObj[currentKey] || {}, remainingKeys, value),
      };
    };
    return update(obj, keys, value);
  };

  // Helper function to return dynamic props based on the tab key
  const getTabProps = (tabKey: string) => {
    switch (tabKey) {
      case "MainDetail":
        return { formState, handleDateFormat };
      case "CompanyConfig":
        return { countries, handleLegalEntityMailChange };
      case "CompanyContactPersonDetails":
        return { handleDateFormat, handleContactChange, handleContactSelectChange, handleDeleteContactPerson };
      case "CooperationAgreementOnlineDetails":
        return { handleRadioChange: handleRadioChange };
      case "CooperationAgreementInvoiceConfig":
        return { handleRadioChange: handleRadioChange, countries };
      default:
        return {};
    }
  };
  const handleDateFormat = (
    dateValue: Date | null,
    fieldPath: string,
    section: keyof Formdata,
    index?: number
  ) => {
    const formattedDate = dateValue
      ? new Date(
        new Date(dateValue).getTime() - new Date(dateValue)?.getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .slice(0, 10)
      : null;

    if (formState?.formData) {
      setFormState((prevState: CooperationAgreementState) => {
        if (prevState) {
          const updatedFormData = { ...prevState.formData };

          if (fieldPath === "contact_person_duties.user.personal_details.date_of_birth") {
            const sectionData: any = updatedFormData?.[section] || [];
            sectionData[index!] = sectionData?.[index!] || {};
            sectionData[index!].contact_person_duties =
              sectionData?.[index!]?.contact_person_duties || {};
            sectionData[index!].contact_person_duties.user =
              sectionData?.[index!]?.contact_person_duties?.user || {};
            sectionData[index!].contact_person_duties.user.personal_details =
              sectionData?.[index!]?.contact_person_duties?.user?.personal_details || {};
            sectionData[index!].contact_person_duties.user.personal_details.date_of_birth =
              formattedDate;
            updatedFormData[section] = sectionData;
          }

          if (fieldPath === "start_date" && updatedFormData?.MainDetail) {
            updatedFormData.MainDetail.start_date = formattedDate ?? "";
          }

          return {
            ...prevState,
            formData: updatedFormData,
          };
        }
        return prevState;
      });
    }
  };

  const updateErrors = (section: string, errors: any) => {
    setFormState((prevState: CooperationAgreementState) => ({
      ...prevState,
      errors: {
        ...prevState?.errors,
        [section]: errors
      },
    }));
  }

  return (
    <div className="cooperationAgreementWrapper">
      <Header
        headerName={t("Edit cooperation agreement")}
        headerClassName="myAccountCardTitle"
      />
      <div className="bg-white" style={{ borderRadius: "2vw" }}>
        <div className="search-bar pb-md-2 cooperationAgreementBackLink">
          {activeTab === "absoluteyouAgent" && <BackLink backLink="/" />}
        </div>
        <Tabs
          activeKey={activeTab}
          onSelect={(eventKey) => {
            if (eventKey) setActiveTab(eventKey); // Ensure eventKey is not null
          }}                // defaultActiveKey="absoluteyouAgent"
          transition={false}
          id="fill-tab-example"
          className="cooperationAgreementTabs border-0 bg-white"
          fill
        >
          {tabData?.map((tab, index) => {
            const tabProps = getTabProps(tab?.eventKey);
            return (
              <Tab
                className="bg-white"
                key={index}
                eventKey={tab?.eventKey}
                style={{ minHeight: "calc(100vh - 16vw)" }}
                title={
                  <>
                    <div className="mb-2">{t(tab?.step)}</div>
                    {t(tab?.title)}
                  </>
                }
              >
                {formState?.formData && (
                  <tab.component
                    agreementData={formState?.formData?.[tab?.eventKey as keyof Formdata]}
                    handleChange={handleChange}
                    handleSelectChange={handleSelectChange}
                    errors={formState?.errors?.[tab?.eventKey as keyof Formdata]}
                    updateErrors={updateErrors}
                    section={tab?.eventKey as keyof Formdata}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                    handleSaveAsDraftClick={handleSaveAsDraftClick}
                    {...tabProps}
                  />
                )}
              </Tab>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default AgreementForm;
