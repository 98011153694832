import React from "react";
import Icon from "../../Icon";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";
import TableHeaderSpace from "../common/atoms/TableHeaderSpace";
import useWindowWidth from "../common/atoms/WindowWidth";
import CustomActionIcon from "../common/molecules/Actions/CustomActionIcon";

interface InvoicesDataCardProps {
  isOutstanding: boolean;
  invoice: any;
  company: any;
  viewInvoice: (invoiceId: number) => void;
  downloadInvoice: (invoiceId: number) => void;
}

const getWeekNumber = (date: Date): number => {
  const startDate = new Date(date?.getFullYear(), 0, 1);
  const days = Math.floor(
    (date.getTime() - startDate?.getTime()) / (24 * 60 * 60 * 1000)
  );
  return Math.ceil((days + 1) / 7);
};

const InvoicesDataCard: React.FC<InvoicesDataCardProps> = ({
  isOutstanding,
  invoice,
  company,
  viewInvoice,
  downloadInvoice,
}) => {
  const tableWidth = useResponsiveValue("100%", "100%", "50%")
  const tableWidthTwo = useResponsiveValue("100%", "100%", "35%")
  const isDesktop = useWindowWidth(1024)
  const IconProps = {
    isIcon: true,
  };

  const invoiceDate = new Date(invoice?.invoice_details?.invoiced_date);
  const weekNumber = getWeekNumber(invoiceDate);

  return (
    <>
      <table className="table table-borderless mb-0 pwa">
        <tbody>
          <tr className="position-relative">
            <td
              style={{
                width: tableWidth,
                borderTopLeftRadius: "0.8vw",
                borderBottomLeftRadius: "0.8vw",
              }}
              className="ps-4 p-2 text-break tableBg"
            >
              {isOutstanding ? (
                <div className="pendingInvoiceCircle"></div>
              ) : (
                ""
              )}
              <div className="invoiceData">
                {t("Week")} {weekNumber}
              </div>
              <div className="invoiceData">
                {t("Company")} "{company?.search_name}"
              </div>
              <div className="invoiceData">
                {t("Listing")}: {invoice?.invoice_number}
              </div>
            </td>
            <td
              style={{
                width: tableWidthTwo,
                borderTopRightRadius: "0.8vw",
                borderBottomRightRadius: "0.8vw",
              }}
              className="align-middle tableBg p-4"
            >
              <div className="d-lg-grid grid-invoice-2 align-items-center">
                <div className="colorPrimary ">
                  <span className="invoiceAmount">
                    €
                    {new Intl.NumberFormat("de-DE").format(
                      invoice?.invoice_details?.total
                    )}
                  </span>
                  <span
                    className="poppins-semibold ms-1 inClBtwFontSize"
                  >
                    incl btw
                  </span>
                </div>
                <div className=" mt-3 mt-lg-0">
                  <CustomActionIcon handleClick={() => isOutstanding && viewInvoice(invoice?.bright_id)} title={isOutstanding ? t("Pay here") : t("Paid")} iconName="TickIcon" className="me-2" />
                  <CustomActionIcon handleClick={() => viewInvoice(invoice?.bright_id)} title={t("View")} iconName="viewIcon" className="me-2 secondaryColorHoverEffect" />
                  <CustomActionIcon handleClick={() => downloadInvoice(invoice?.bright_id)} title={t("Download")} iconName="downloadIcon" />
                </div>
              </div>
            </td>
          </tr>
          {isDesktop &&
            <TableHeaderSpace headerLength={5} />
          }
        </tbody>
      </table>
    </>
  );
};

export default translate(InvoicesDataCard);
