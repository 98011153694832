import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import ReactDatePicker from "react-datepicker";
import Header from "../../common/layout/Header";
import LabelField from "../../common/atoms/LabelField";
import Button from "../../common/atoms/Button";
import CustomNotify from "../../common/atoms/CustomNotify";
import BackLink from "../../common/atoms/BackLink";
import { LabelWithInputField } from "../../common/molecules/LabelWithInputField";
import { t, translate } from "../../CentralDataMangement/translation/Translation";
import { ApiCall } from "../../../services/ApiServices";
import { formatUserData } from "../../IdentityManager/authentication/MyAccount/services/MyaccountUtils";
import { fareFormValidation } from "../services/FareValidation";
import { CREATE_TRADE, UPDATE_EMPLOYEE } from "../../../routes/ApiEndpoints";
import { initialState } from "../../IdentityManager/authentication/MyAccount/State";
import Calender from "../../common/molecules/Calender";
import TimeInput from "../../common/molecules/TimeInput";
import { useNavigate, useParams } from "react-router-dom";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import CheckBoxField from "../../common/atoms/CheckBoxField";

// Types
interface FormValues {
  fare_name: string;
  start_date?: string;
  status: number;
  end_date?: string;
  [key: string]: any;
}

interface FareFormState {
  formValues: FormValues;
  loading: boolean;
  editing: boolean;
  errors: {
    [key: string]: string | undefined;
  };
}

interface User {
  userId: number;
}

// Initial State
const initialFormState: FareFormState = {
  formValues: {
    ...initialState.formValues,
    fare_name: "",
    start_date: undefined,
    end_date: undefined,
    status: 0
  },
  loading: false,
  editing: false,
  errors: {},
};

const FareForm: React.FC = () => {
  // State and Selectors
  const user = useSelector((state: { auth: User }) => state.auth);
  const [formState, setFormState] = useState<FareFormState>(initialFormState);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { id } = useParams<{ id: string }>();
  const Navigate = useNavigate();
  const inputFieldProps = useMemo(
    () => ({
      labelWrapperClassName: "col-4 align-self-center pe-0",
      colClassName: "col-8",
      className: "inputFieldColor",
    }),
    []
  );

  useEffect(() => {
    if (id) {
      getFareDetails();
    }
  }, [id]);
  const getFareDetails = async () => {
    const response = await ApiCall.getService(
      `getFareDetails/${id}`,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status == 200) {
      setFormState((prevState) => ({
        ...prevState,
        formValues: {
          ...prevState.formValues,
          ...response.data,
        },
      }));
    }
  };

  // Checkbox handler function
  const handleCheckboxChange = (checked: boolean) => {
    const newValue = checked ? 1 : 0; // Set default to 1 when checked, 0 when unchecked

    setFormState((prevState) => ({
      ...prevState,
      formValues: {
        ...prevState.formValues,
        status: newValue,
      },
    }));
  };

  // Handlers
  const handleDateFormat = (dateValue: Date | null, field: string) => {
    const formattedDate = dateValue
      ? new Date(dateValue.getTime() - dateValue.getTimezoneOffset() * 60000) // Adjust for timezone
          .toISOString()
          .slice(0, 10) 
      : null;
  
    const fieldParts = field.split(".");
  
    const updateState = (state: any, value: any) => {
      let currentState = state;
      fieldParts.forEach((part, index) => {
        if (index === fieldParts.length - 1) {
          currentState[part] = value;
        } else {
          currentState[part] = currentState[part] || {};
          currentState = currentState[part];
        }
      });
      return state;
    };
  
    setFormState((prevState) => ({
      ...prevState,
      formValues: updateState({ ...prevState.formValues }, formattedDate),
    }));
  };
  

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setFormState((prevState) => ({
        ...prevState,
        formValues: {
          ...prevState.formValues,
          [name]: value,
        },
        errors: {
          ...prevState.errors,
          [name]: undefined,
        },
      }));
    },
    []
  );

  const resetForm = () => {
    setFormState(initialFormState);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const errors = fareFormValidation(formState.formValues);

    if (Object.keys(errors).length > 0) {
      setFormState((prev) => ({ ...prev, errors }));
      setIsSubmitting(false);
      CustomNotify({
        type: "error",
        message: "Please fill in all required fields correctly",
      });
      return;
    }
    try {
      const payload = {
        name: formState.formValues.fare_name,
        start_date: formState.formValues.start_date,
        end_date: formState.formValues.end_date,
        status: formState.formValues.status,
      };

      const url = id ? `${CREATE_TRADE}/${id}` : CREATE_TRADE;
      const method = id ? "PUT" : "POST";
      const response = await ApiCall?.service(
        url,
        method,
        payload,
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (response?.status === "success") {
        CustomNotify({
          type: "success",
          message: response?.message || "Fare saved successfully",
        });
        Navigate("/manage-fares");
      } else {
        CustomNotify({
          type: "error",
          message: response?.message || "Failed to save fare",
        });
      }
    } catch (error) {
      CustomNotify({
        type: "error",
        message: "An error occurred while saving",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fare-form-container">
      <Header headerName={t("")} headerClassName="myAccountCardTitle" />

      <div className="search-bar pb-md-2">
        <div className="row">
          <BackLink backLink="/manage-fares" />
        </div>
      </div>

      <div className="fareForm mb-3">
        <div className="row">
          <div className="col-12">
            <LabelField
              title={t("Trade Show Information")}
              className="myAccountCardTitle colorPrimary mb-3"
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-4 mb-lg-3">
            <div className="row multiSelectWrapper">
              <LabelWithInputField
                label={`${t("Fare name")}:`}
                type="text"
                value={formState.formValues.fare_name || ""}
                name="fare_name"
                handleChange={handleChange}
                id="fare_name"
                error={formState.errors?.fare_name}
                {...inputFieldProps}
                isTop={true}
                isMandatory={true}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4 mb-lg-3">
            <LabelField title={t("Start date") + ":"} isMandatory={true} />
            <div className="col-12 position-relative calendarSectionWrapper traderNextDate">
              <Calender
                onChange={(date) =>
                  date && handleDateFormat(date, "start_date")
                }
                selectedDate={
                  formState?.formValues?.start_date
                    ? new Date(formState?.formValues?.start_date)
                    : null
                }
                error={formState?.errors?.start_date}
                name="start_date"
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4 mb-lg-3">
            <LabelField title={t("End date") + ":"} isMandatory={true} />
            <div className="col-12 position-relative calendarSectionWrapper traderNextDate">
              <Calender
                onChange={(date) => date && handleDateFormat(date, "end_date")}
                selectedDate={
                  formState?.formValues?.end_date
                    ? new Date(formState?.formValues?.end_date)
                    : null
                }
                error={formState?.errors?.end_date}
                name="end_date"
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4 mb-lg-3">
            <div className="col-12 position-relative calendarSectionWrapper traderNextDate">
              <CheckBoxField
                name="status"
                value={formState.formValues.status}
                isChecked={formState.formValues.status === 1} 
                label="Default"
                checkBoxWrapper="ps-0"
                onChangeHandler={(e) => handleCheckboxChange(e.target.checked)} 
              />
            </div>
          </div>

        </div>

        <div className="row pb-4 pt-4 pt-lg-0">
          <div className="col-12 text-end">
            <Button
              type="submit"
              className="searchBtn px-3"
              title={t("Save")}
              style={{ width: "fit-content" }}
              handleClick={handleSubmit}
              disabled={isSubmitting}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default translate(FareForm);
