import React, { useContext, useEffect } from "react";
import LinkCoeffEmpContext from "./LinkCoeffEmp/LinkCoeffEmpContext";
import CoeffcientValuesFirstPart from "./CoeffcientValuesFirstPart";
import EmployeeTypeSecondPart from "./EmployeeTypeSecondPart";
import { helpers } from "./LinkCoeffEmpHelper";
import MultiSelect from "./SelectComponent";
import {
  t,
  translate,
} from "../../../CentralDataMangement/translation/Translation";
import { ApiCall } from "../../../../services/ApiServices";
import {
  FETCH_ALL_EMP_COEFF_VALUES,
  SAVE_PC_LINKING_DATA,
} from "../../../../routes/ApiEndpoints";
import Header from "../../../common/layout/Header";
import Button from "../../../common/atoms/Button";
import LabelField from "../../../common/atoms/LabelField";
import BackLink from "../../../common/atoms/BackLink";
import "./linkCoefficient.css";
import ErrorValidation from "./ErrorValidation";

var SERVER_SIDE_RENDERING = 1;

const LinkCoeffEmpComponent = (props) => {
  const { state, updateStateChanges } = useContext(LinkCoeffEmpContext);
  const { inputRef, isOverflow } = state;

  useEffect(() => {
    SERVER_SIDE_RENDERING
      ? fetchEmpCoeffValueTypesData()
      : (SERVER_SIDE_RENDERING += 1);
  }, []);

  const fetchEmpCoeffValueTypesData = async (
    url = `${FETCH_ALL_EMP_COEFF_VALUES}`,
    type = 0,
    pcid = props.pcid
  ) => {
    try {
      const response = await ApiCall.service(url, "POST", { pcid: pcid });
      if (response.success) {
        if (type === 1) {
          return response;
        } else {
          assignDataToStateVariables(response.data);
        }
      }
    } catch (error) {
      console.error("Error in fetchEmpCoeffValueTypesData:", error);
      return null;
    }
  };

  const assignDataToStateVariables = (data) => {
    const {
      employeeTypes,
      coefficientTypes,
      valueTypes,
      pcLinkedValueData,
      pcArray,
    } = data;
    updateStateChanges({
      employeeTypeArray: employeeTypes,
      coefficientTypeArray: coefficientTypes,
      valueTypeArray: valueTypes,
      pclinkingValueobj: pcLinkedValueData || {},
      pcArray: [{ value: false, label: t("--- Select ---") }, ...pcArray],
      selectedPc: parseInt(props.pcid),
    });
  };

  const handleSubmit = async () => {
    if (proceedToSubmit()) {
      const response = await ApiCall.service(
        SAVE_PC_LINKING_DATA,
        "POST",
        postdata()
      );
      if (response.success) window.location.reload();
    }
  };

  const proceedToSubmit = () => {
    let proceed = true;

    if (!state.selectedPc) {
      updateStateChanges({ pcWarning: true });
      return false;
    }

    if (state.selectedPc && state.pclinkingValueobj.length === 0) {
      updateStateChanges({ emptyDataWarning: true });
      return false;
    }

    const { status, regExpressionStatus } = helpers.checkCoefficientsFilledOrNot(
      state.coefficientTypeArray,
      state.employeeTypeArray,
      state.pclinkingValueobj
    );

    if (state.selectedPc && (!status || !regExpressionStatus)) {
      updateStateChanges({
        emptyDataWarning: !status,
        regExpWarning: !regExpressionStatus,
      });
      proceed = false;
    }

    if (state.lowHighValidation.length ||
      state.valueErrorArray.length ||
      state.defaultValueError.length) {
      proceed = false;
    }

    return proceed;
  };

  const postdata = () => ({
    pclinkingValueobj: state.pclinkingValueobj,
    selectedPc: state.selectedPc,
  });

  const onSelect = async (e) => {
    removeWarningClass();
    const response = await fetchEmpCoeffValueTypesData(
      FETCH_ALL_EMP_COEFF_VALUES,
      1,
      e.value
    );

    if (response) {
      const {
        employeeTypes,
        coefficientTypes,
        valueTypes,
        pcLinkedValueData,
      } = response.data;

      updateStateChanges({
        employeeTypeArray: employeeTypes,
        selectedPc: e.value,
        pcWarning: false,
        emptyDataWarning: false,
        regExpWarning: false,
        pclinkingValueobj: pcLinkedValueData,
        lowHighValidation: [],
        valueErrorArray: [],
        defaultValueError: [],
      });
    }
  };

  const removeWarningClass = () => {
    state.lowHighValidation.forEach((val) => {
      let lowRef = inputRef.current[`${val}_1`];
      let highRef = inputRef.current[`${val}_3`];
      if (lowRef) lowRef.classList.remove("warning");
      if (highRef) highRef.classList.remove("warning");
    });
  };

  const renderMultiSelect = () => (
    <>
      <LabelField title={t("Select paritair comite")} />
      <MultiSelect
        options={state.pcArray}
        standards={state.pcArray.filter(val => val.value === state.selectedPc)}
        disabled={!!(parseInt(props.pcid) && state.selectedPc)}
        handleChange={onSelect}
        isMulti={false}
        placeholder={t("Select paritair comite")}
      />
    </>
  );

  if (!SERVER_SIDE_RENDERING) return null;

  return (
    <>
      <Header
        headerName={t("Link coefficients to employee types")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-md-2">
        <div className="row mb-2">
          <BackLink backLink="/config/settings" />
          <div className="col-8 align-self-center text-center d-block d-lg-none">
            <LabelField
              title={t("Link coefficients to employee types")}
              className="myAccountCardTitle mb-0"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            {renderMultiSelect()}
            {state.pcWarning && (
              <div className="text-danger">{t("Choose paritair comite")}</div>
            )}
          </div>
          <div className="col-lg-8 align-self-center pt-1">
            <ErrorValidation />
          </div>
        </div>
      </div>
      <div className="bg-white" style={{
        paddingLeft: "2.8vw",
        paddingRight: "2.8vw",
        paddingBottom: "2vw",
        borderBottomLeftRadius: "2vw",
        borderBottomRightRadius: "2vw",
        position: "relative",
        top: "-0.5vw",
      }}>
        <div className="row defaultCoefficientHeight scrollBar">
          <div className="col-lg-4 px-0 firstPart col-md-7 col-fixed col-8">
            <CoeffcientValuesFirstPart />
          </div>
          <div className="col-lg-8 ps-0 col-scrollable col-md-5 col-4">
            <EmployeeTypeSecondPart />
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-6 ms-auto px-0">
            <Button
              handleClick={handleSubmit}
              title={t("Save")}
              type="button"
              className="searchBtn float-end"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default translate(LinkCoeffEmpComponent);
