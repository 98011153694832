import { Dispatch } from "redux";
import { addCoreErrors, selectEmployees } from "../../../features/planning/planningSlice";
import { RootState } from "../../../store";
import { t } from "../../CentralDataMangement/translation/Translation";
import { isBeforeLiveTime } from "./PlanningValidations";
import { pcMaxAndMinHrs } from "./PlanningTimingValidations";
import { calculateShiftsTotalTime } from "../Services/PlanningTimingHelper";
import { FLEX_EMPLOYEE_TYPES, HORECA_EMPLOYEE_TYPES } from "../../../Constants";
import { formatFloatSalary } from "../Services/PlanningSalaryHelper";
import { flexSalaryWarning, lesserSalaryWarning, newSalaryWarning } from "./PlanningSalaryValidations";
import { selectFlexSalaryByPCId, selectPCById } from "../../../features/planning/employeeSlice";

// Helper function to get the ISO week number for a given date
export const getISOWeek = (date: string): number => {
  const dt = new Date(date);

  // Ensure Monday is treated as the first day of the week
  const dayNum = dt.getUTCDay() || 7; // Treat Sunday (0) as 7

  // Adjust to the nearest Thursday to determine the week number
  dt.setUTCDate(dt.getUTCDate() + (4 - dayNum));

  const yearStart = new Date(Date.UTC(dt.getUTCFullYear(), 0, 1));

  // Calculate the ISO week number
  return Math.ceil((((dt.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
};


interface EmployeeError {
  date: string;
  error: string;
}

interface EmployeeErrors {
  employee_id: number;
  employee_type_error?: string;
  function_error?: string;
  pc_error?: string;
  shift_errors: EmployeeError[];
  employee_error?: string;
  employee_warnings?: string;
  salaryError?: string;
  salaryWarning?: string;
}

export const validatePlanningAllEmployeeFields = (state: RootState, dispatch: Dispatch): boolean => {
  const employees = selectEmployees(state);
  let hasErrors = false;
  let employeesErrors: EmployeeErrors[] = [];
  let allShiftsByEmployee: { [employeeId: number]: { date: string; shift: any }[] } = {};

  for (let employee of employees) {
    const flexSalary = selectFlexSalaryByPCId(state, employee.pc_id);

    const employeeErrors: EmployeeErrors = {
      employee_id: employee.employee_id,
      shift_errors: [],
      employee_type_error: "",
      function_error: "",
      pc_error: "",
      employee_error: "",
      employee_warnings: ""
    };
    console.error(employee,"I am here");
    
    if (employee.pc_id == null || employee.pc_id == "") {
      employeeErrors.pc_error = t("Paritair commitee is required");
      hasErrors = true;
    } else {
      employeeErrors.pc_error = "";
    }
    if (employee.employee_type_id == null || employee.employee_type_id == "") {
      employeeErrors.employee_type_error = t("Employee type is required");
      hasErrors = true;
    } else {
      employeeErrors.employee_type_error = "";
    }
    if (employee.function_id == null || employee.function_id == "") {
      employeeErrors.function_error = t("Function is required");
      hasErrors = true;
    } else {
      employeeErrors.function_error = "";
    }
    console.log(employeeErrors);

    if (!employee.schedule || Object.keys(employee.schedule).length === 0) {
      employeeErrors.employee_error = t('No schedule for current employee');
      hasErrors = true;
    }
    console.log(employee);

    // Group planned days by their week number
    const daysByWeek: { [week: number]: Set<string> } = {};

    for (let date in employee.schedule) {
      const weekNumber = getISOWeek(date);
      if (!daysByWeek[weekNumber]) daysByWeek[weekNumber] = new Set();
      daysByWeek[weekNumber].add(date);

      const shifts = employee.schedule[date]?.shifts;
      const totalHours = calculateShiftsTotalTime(shifts, 2);

      if (!shifts || shifts.length === 0) {
        employeeErrors.shift_errors.push({
          date,
          error: t('No shifts for employee on this date')
        });
        hasErrors = true;
        continue;
      }

      const sortedShifts = [...shifts].sort((a, b) =>
        new Date(`${date} ${a.start_time}`).getTime() - new Date(`${date} ${b.start_time}`).getTime()
      );

      for (let i = 0; i < sortedShifts.length; i++) {
        const shift = sortedShifts[i];

        if (!shift.start_time || !shift.end_time) {
          employeeErrors.shift_errors.push({
            date,
            error: t('Empty shift times for employee on this date')
          });
          hasErrors = true;
        }

        if (isBeforeLiveTime(date, shift.start_time)) {
          employeeErrors.shift_errors.push({
            date,
            error: t('Shift starts before the current live time')
          });
          hasErrors = true;
        }

        let maxAndMinValidations = pcMaxAndMinHrs(state, employee.pcId, totalHours);
        if (maxAndMinValidations !== "") {
          employeeErrors.shift_errors.push({
            date,
            error: maxAndMinValidations
          });
          hasErrors = true;
        }

        if (i < sortedShifts.length - 1) {
          const nextShift = sortedShifts[i + 1];
          const currentShiftEndTime = new Date(`${date} ${shift.end_time}`).getTime();
          const nextShiftStartTime = new Date(`${date} ${nextShift.start_time}`).getTime();

          if (currentShiftEndTime > nextShiftStartTime) {
            employeeErrors.shift_errors.push({
              date,
              error: t('Shift overlap detected on this date.')
            });
            hasErrors = true;
          }
        }

        if (!allShiftsByEmployee[employee.employee_id]) {
          allShiftsByEmployee[employee.employee_id] = [];
        }
        allShiftsByEmployee[employee.employee_id].push({ date, shift });
      }
    }

    // Validate each week's planned days
    for (let week in daysByWeek) {
      if (!FLEX_EMPLOYEE_TYPES.concat(HORECA_EMPLOYEE_TYPES).includes(employee.employee_type_id) && daysByWeek[week].size > 6) {
        employeeErrors.employee_error = 'Employee can only plan a maximum of 6 days in a week';
        hasErrors = true;
        break;
      }
    }
    if (HORECA_EMPLOYEE_TYPES.includes(employee.employee_type_id)) {
      let consecutiveDays = 1; // Start with 1 since the first date is counted
      let previousDate: Date | null = null;

      // Flatten all dates from the sets inside daysByWeek
      const allPlannedDates = Array.from(
        new Set<string>(
          Object.values(daysByWeek).flatMap((set) => Array.from(set))
        )
      ).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

      for (let date of allPlannedDates) {
        const currentDate = new Date(date);

        if (previousDate) {
          const nextDay = new Date(previousDate);
          nextDay.setDate(previousDate.getDate() + 1); // Calculate the next day

          if (currentDate.getTime() === nextDay.getTime()) {
            consecutiveDays++; // Increment if consecutive
          } else {
            consecutiveDays = 1; // Reset if not consecutive
          }
        }

        // Set the current date as the previous date for the next iteration
        previousDate = currentDate;

        // If more than 2 consecutive days are found, add the error and stop checking
        if (consecutiveDays > 2) {
          employeeErrors.employee_error =
            'Employee can only plan for 2 consecutive days. Please provide a gap of at least 1 day after planning 2 days';
          hasErrors = true;
          break;
        }
      }
    }
    if (employee.new_salary !== undefined) {
      const { error, warning } = validateSalary(employee.new_salary, employee.salary, employee.employee_type_id, flexSalary);
      employeeErrors.salaryError = error;
      employeeErrors.salaryWarning = warning;
    }
    if (
      employeeErrors.employee_error
      || employeeErrors.shift_errors.length > 0
      || employeeErrors.employee_type_error
      || employeeErrors.pc_error
      || employeeErrors.function_error
    ) {

      employeesErrors.push(employeeErrors);
    }
  }
  dispatch(addCoreErrors(employeesErrors));
  return hasErrors;
};

export const validateSalary = (value: string, currentSalary: string, employeeTypeId: number, flexSalary: string | null) => {
  let error = "";
  let warning = "";

  if (value !== undefined) {
    // Check if the value contains only numbers (allowing optional decimal point)
    const isValidNumber = /^[0-9]+([.,][0-9]{1,4})?$/.test(value);
    if (value && !isValidNumber) {
      error = t("Salary must be a valid number and not contain alphabets or special characters");
      return { error, warning };
    }

    // Parse the selected function salary and the employee's current salary
    const parsedCurrentSalary = formatFloatSalary(currentSalary);
    const newSalaryValue = formatFloatSalary(value);

    // New validation: Check if salary exceeds 100 euros
    if (newSalaryValue > 100) {
      error = t("Salary cannot exceed 100 euros");
      return { error, warning };
    }

    // Existing validation checks
    if (newSalaryValue > parsedCurrentSalary) {
      warning = newSalaryWarning(); // Set a warning for salary exceeding the limit
    } else if (newSalaryValue < parsedCurrentSalary) {
      error = lesserSalaryWarning(parsedCurrentSalary); // Set an error for salary less than the current salary
    }

    if (flexSalary != null && FLEX_EMPLOYEE_TYPES.includes(employeeTypeId)) {
      const minFlexSalary = formatFloatSalary(flexSalary);
      if (newSalaryValue > minFlexSalary * 1.5) {
        error = flexSalaryWarning();
      }
    }
  }

  return { error, warning };
};
