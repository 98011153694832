import React, { useEffect, useState } from "react";
import LabelField from "../common/atoms/LabelField";
import Header from "../common/layout/Header";
import "./css/invoices.css";
import InvoicesDataCard from "./InvoicesDataCard";
import InvoiceSettings from "./InvoiceSettings";
import InvoicesEnquiry from "./InvoicesEnquiry";
import OverFlowWrapper from "../common/OverFlowWrapper";
import PdfViewer from "../common/molecules/PdfViewer";
import { ApiCall } from "../../services/ApiServices";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { FETCH_ALL_INVOICES, GET_ALL_INVOICES, GET_INVOICE } from "../../routes/ApiEndpoints";
import CommonServices from "../../services/common/CommonServices";
import AccordionCustom from "../common/atoms/AccordionCustom";
import { useSelector } from "react-redux";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import Button from "../common/atoms/Button";
import { FaSyncAlt } from "react-icons/fa";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";


const InvoiceOverviewTemplate = () => {
  interface Data {
    paid_invoices: Array<any>;
    unpaid_invoices: Array<any>;
    outstanding_amount: string;
    company: object;
  }

  const [data, setData] = useState<Data>({
    paid_invoices: [],
    unpaid_invoices: [],
    outstanding_amount: "",
    company: {},
  });

  const [pdfUrls, setPdfUrls] = useState<Record<number, string | any>>({});
  const [showPdf, setShowPdf] = useState<string | null>(null);
  const [invoiceNumber, setInvoiceNumber] = useState<string | null>(null);
  const id = useSelector((state: any) => state?.auth?.currentCompany?.id);


  const fetchData = async () => {
    try {
      const url = `${GET_ALL_INVOICES}/${id}`;
      const response = await ApiCall.getService(
        url,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
        true
      );

      if (response.status === 'success') {
        setData({
          paid_invoices: Object.values(response?.data?.paid_invoices),
          unpaid_invoices: Object.values(response?.data?.unpaid_invoices),
          outstanding_amount: new Intl.NumberFormat("de-DE").format(
            response?.data?.outstanding_amount
          ),
          company: response?.data?.unpaid_invoices[0]?.company || {},
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const fetchInvoicePdf = async (invoiceId: number) => {
    if (pdfUrls[invoiceId]) {
      return pdfUrls[invoiceId];
    }
    try {
      const url = `${GET_INVOICE}/${invoiceId}`;
      const response = await ApiCall.getService(
        url,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
        true
      );
      if (response.status === 'success') {
        setPdfUrls((prevUrls) => ({
          ...prevUrls,
          [invoiceId]: response?.data?.url,
        }));
      }
      return response?.data?.url;
    } catch (error) {
      console.error("Error fetching invoice:", error);
      return null;
    }
  };

  const fetchInvoices = async () => {
    try {
      const url = `${FETCH_ALL_INVOICES}/${id}`;
      const response = await ApiCall.getService(
        url,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
        true
      );

      if (response.status === 'success') {
        fetchData();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const viewInvoice = async (invoiceId: number) => {
    const url = await fetchInvoicePdf(invoiceId);
    if (url) {
      const invoice = [...data.paid_invoices, ...data.unpaid_invoices].find(
        (invoice) => invoice?.bright_id === invoiceId
      );
      setInvoiceNumber(invoice?.invoice_number || "");
      setShowPdf(url);
    }
  };

  const downloadInvoice = async (invoiceId: number) => {
    const url = await fetchInvoicePdf(invoiceId);
    if (url) {
      try {
        const invoice = [...data?.paid_invoices, ...data?.unpaid_invoices].find(
          (invoice) => invoice?.bright_id === invoiceId
        );
        const invoiceNumber = invoice?.invoice_number || "Unknown";
        await CommonServices.downloadFile(url, invoiceNumber, "Invoice");
      } catch (error) {
        console.error("Error downloading the invoice:", error);
      }
    }
  };

  return (
    <>
      <Header headerName={t("Invoices")} headerClassName="myAccountCardTitle" />

      <div className="row equal-cols">
        <div className="col-lg-8 mb-4 mb-lg-0">
          {showPdf ? (
            <div className="contentBg">
              <OverFlowWrapper className="invoiceLeftWrapper">
                <PdfViewer
                  fileUrl={showPdf}
                  setShowPdf={setShowPdf}
                  fileNumber={invoiceNumber}
                  fileType={"Invoice"}
                />
              </OverFlowWrapper>
            </div>
          ) : (
            <>
              <div
                className="bg-white"
                style={{ borderRadius: "2vw", padding: "2vw 2vw 2vw 0vw" }}
              >
                <OverFlowWrapper className="invoiceLeftWrapper">
                  <div className="d-flex justify-content-between">
                    <LabelField
                      title={t("Outstanding invoices")}
                      className="inVoiceHeader mb-3"
                      customStyle={{ padding: "0vw 2.5vw" }}
                    />
                    <Button
                      title={<FaSyncAlt />}
                      type="submit"
                      className="syncSearchBtn searchBtn px-0 mb-3"
                      handleClick={fetchInvoices}
                      toolTipName="Sync Invoices"
                    />
                  </div>
                  <div
                    className="mb-3"
                    style={{ padding: "0vw 0vw 0vw 2.5vw" }}
                  >
                    {[...data?.unpaid_invoices].reverse().map((invoice: any) => (
                      <InvoicesDataCard
                        key={invoice.id}
                        isOutstanding={true}
                        invoice={invoice}
                        company={data?.company}
                        viewInvoice={() => viewInvoice(invoice?.bright_id)}
                        downloadInvoice={downloadInvoice}
                      />
                    ))}
                  </div>
                  <div style={{ padding: "0vw 0vw 0vw 2.5vw" }}>
                    <AccordionCustom
                      accordionTitle={t("Show paid invoices")}
                      accordionTitleClassName="colorPrimary poppins-semibold showPadinInvoicesText"
                      accordionButton="bg-transparent shadow-none align-items-center px-0"
                    >
                      <div className="mb-3">
                        {data.paid_invoices.map((invoice: any) => (
                          <InvoicesDataCard
                            key={invoice?.id}
                            isOutstanding={false}
                            invoice={invoice}
                            company={data?.company}
                            viewInvoice={() => viewInvoice(invoice?.bright_id)}
                            downloadInvoice={downloadInvoice}
                          />
                        ))}
                      </div>
                    </AccordionCustom>
                  </div>
                </OverFlowWrapper>
              </div>
            </>
          )}
        </div>
        <div className="col-lg-4">
          <div className="contentBg mb-lg-3">
            <LabelField
              title={t("Total outstanding")}
              className="inVoiceHeader colorPrimary poppins-semibold mb-3"
            />
            <div>
              <div className="colorPrimary me-4">
                <span className="totalInvoiceAmount">
                  €{data.outstanding_amount}
                </span>
                <span className="poppins-semibold ms-2 inclBtwFont">
                  incl btw
                </span>
              </div>
            </div>
          </div>
          <div className="contentBg mt-lg-5 mt-4">
            <LabelField
              title={t("Manage your invoices")}
              className="inVoiceHeader colorPrimary poppins-semibold mb-3"
            />
            <InvoiceSettings invoiceTitle={t("Manage invoice settings")} />
            <InvoicesEnquiry
              documentsTitle={
                t("Do you have questions about your invoice") + "?"
              }
            />
          </div>
        </div>
      </div >
    </>
  );
};

export default translate(InvoiceOverviewTemplate);
