import React, { useEffect, useRef, useState } from "react";
import Icon from "../../Icon";
import IconWithTitle from "../common/molecules/IconWithTitle";
import { useSelector } from "react-redux";
import { selectCurrentCompany } from "../../features/auth/AuthSlice";
import { ApiCall } from "../../services/ApiServices";
import LoadingIcon from "../common/utlis/LoadingIcon";
import { COMPANY_EMPLOYEES } from "../../routes/ApiEndpoints";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";
import userIcon from "../../static/images/User.svg";

interface Employee {
  id: number;
  first_name: string;
  last_name: string;
  is_fav: boolean;
  profile_picture_url: string | null;
  employee_type: string;
  employee_type_id: number;
  employee_type_category: string;
  employee_type_category_id: number;
  paritaire_commitee: string;
  paritaire_commitee_id: number;
  functional_salary: string;
  function_id: number;
  function_name: string;
}

interface Props {
  onEmployeeClick?: (employee: Employee) => void;
  selectedEmployee?: Employee[] | null;
}

const PAGE_SIZE = 12;

const EmployeesNameCard: React.FC<Props> = ({
  onEmployeeClick,
  selectedEmployee,
}) => {
  const currentCompanyId = useSelector(selectCurrentCompany)?.id;
  const [companyEmployees, setEmployees] = useState<Employee[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  const dynamicValue = useResponsiveValue("75%", "88%", "94%");
  const cardHeight = useResponsiveValue("40vw", "18vw", "8vw");
  const arrowWidth = useResponsiveValue("10%", "5%", "2%");
  const iconStyle = useResponsiveValue("4.5vw", "2.5vw", "1vw");
  const imageStyle = useResponsiveValue("10vw", "5vw", "2vw");
  const cardStyle = useResponsiveValue("50%", "18%", "7.5%");

  const fetchEmployees = async (pageNum: number) => {
    try {
      const isInitialLoad = pageNum === 1;
      isInitialLoad ? setLoading(true) : setLoadingMore(true);

      const res = await ApiCall.getService(
        `${COMPANY_EMPLOYEES}?company_id=${currentCompanyId}&bright=true&for=planning&page=${pageNum}&per_page=${PAGE_SIZE}`,
        "GET"
      );

      if (res.status === 'success') {
        if (isInitialLoad) {
          setEmployees(res.data);
        } else {
          setEmployees(prev => [...prev, ...res.data]);
        }
        setHasMore(res.data.length === PAGE_SIZE);
      } else {
        throw new Error(res.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    setPage(1);
    setEmployees([]);
    fetchEmployees(1);
  }, [currentCompanyId]);

  const scrollRef = useRef<HTMLDivElement>(null);

  // Function to check if we're near the end of the scroll
  const checkScrollPosition = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      const scrollEnd = scrollWidth - clientWidth;

      // If we're near the end (within 100px) and not currently loading more
      if (scrollLeft > scrollEnd - 100 && !loadingMore && hasMore) {
        setPage(prev => prev + 1);
        fetchEmployees(page + 1);
      }
    }
  };

  // Sort employees so that favorite employees are listed first
  const sortedEmployees = [...companyEmployees].sort((a, b) =>
    a.is_fav === b.is_fav ? 0 : a.is_fav ? -1 : 1
  );

  const handleScroll = (direction: "left" | "right") => {
    if (scrollRef.current) {
      const scrollAmount = direction === "right" ? 200 : -200;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  return (
    <div className="col-12">
      <div className="card border-0 px-2 mb-3 justify-content-center" style={{ height: cardHeight }}>
        {loading ? (
          <LoadingIcon width={"4vw"} height={"4vw"} />
        ) : (
          <div className="d-flex align-items-center">
            <div
              style={{ width: arrowWidth }}
              className="secondaryColorHoverEffect text-center"
              onClick={() => handleScroll("left")}
            >
              <Icon isIcon={true} width={iconStyle} height={iconStyle} name="backIcon" />
            </div>

            <div
              ref={scrollRef}
              className="mx-auto d-flex py-2"
              style={{
                overflowX: "auto",
                width: dynamicValue,
                scrollbarWidth: "none",
              }}
              onScroll={() => checkScrollPosition()}
            >
              {sortedEmployees.map((employee: Employee) => (
                <div
                  key={employee.id}
                  className="d-inline-flex align-items-center flex-column justify-content-center mx-2 text-center"
                  style={{ minWidth: cardStyle, width: cardStyle }}
                >
                  <div
                    style={{ width: "100%" }}
                    className={`text-center employeeNameCardHover cursor-pointer ${selectedEmployee?.some(selectedEmp => selectedEmp.id === employee.id)
                        ? "employeeNameCardActive"
                        : ""
                      }`}
                    onClick={() => onEmployeeClick?.(employee)}
                  >
                    <span className="mb-lg-1 mb-3">
                      <Icon
                        isIcon={true}
                        width={iconStyle}
                        height={iconStyle}
                        name={employee.is_fav ? "starIcon" : ""}
                        onClick={() => { }}
                      />
                    </span>
                    <IconWithTitle
                      src={employee.profile_picture_url ?? userIcon}
                      isImage={true}
                      imageWidth={imageStyle}
                      imageHeight={imageStyle}
                      imageBorderRadius="50%"
                      imageWrapperClassName="mt-2"
                      title={`${employee.first_name} ${employee.last_name.charAt(0)}.`}
                      hoverTitle={`${employee.first_name} ${employee.last_name.charAt(0)}.`}
                      isTitle={true}
                      labelClassName="colorPrimary poppins-medium employeeName cursor-pointer mobileFont"
                    />
                    <div className="mb-2 text-center">
                      <div className="plannedColor mx-auto" />
                    </div>
                  </div>
                </div>
              ))}
              {loadingMore && (
                <div className="d-flex align-items-center justify-content-center" style={{ minWidth: cardStyle }}>
                  <LoadingIcon width={"2vw"} height={"2vw"} />
                </div>
              )}
            </div>

            <div
              style={{ width: arrowWidth }}
              className="secondaryColorHoverEffect text-center"
              onClick={() => handleScroll("right")}
            >
              <Icon isIcon={true} width={iconStyle} height={iconStyle} name="nextIcon" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeesNameCard;