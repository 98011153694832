import React, { useState, useEffect } from "react";
import Icon from "../../Icon";
import { t } from "../CentralDataMangement/translation/Translation";
import AccordionCustom from "../common/atoms/AccordionCustom";
import LabelField from "../common/atoms/LabelField";
import RadioField from "../common/atoms/RadioField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import Calender from "../common/molecules/Calender";
import { LabelWithInputField } from "../common/molecules/LabelWithInputField";
import Navigation from "./Navigation";
import { CompanyContactPersonDetails, ContactPersonDuties, Formdata } from "./Interfaces";
import { Option } from "./CompanyInformation";
import { validateSection } from "./Service";
import { ContactinputFields, ContactNonMandatoryFields } from "./State";
import Popup from "../CentralDataMangement/manage-functions/Popup";
interface InputFieldProps {
  label: string;
  type: string;
  name: string;
  id: string;
  value: string;
  error?: string;
  isMandatory?: boolean;
  className?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, section: keyof Formdata, index: number) => void;
  section: keyof Formdata;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  type,
  name,
  id,
  value,
  error,
  className,
  isMandatory = false,
  handleChange,
  section,
}) => (
  <div className="col-3 mb-3">
    <LabelWithInputField
      type={type}
      label={t(label)}
      value={value}
      name={name}
      isMandatory={isMandatory}
      id={id}
      handleChange={(e) => handleChange(e, section, 0)}
      error={error}
      className={`ps-2 ${className}`}
    />
  </div>
);

interface ContactPersonProps {
  agreementData: any;
  handleDateFormat?: (dateValue: Date | null, fieldPath: string, section: keyof Formdata, index: number) => void;
  handleContactChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | Option, section: keyof Formdata, index: number) => void;
  section: keyof Formdata;
  errors: any;
  updateErrors: (section: keyof Formdata, allErrors: any[]) => void;
  handleContactSelectChange?: (selectedOption: Option, section: keyof Formdata, index: number) => void;
  handleNext: () => void;
  handleDeleteContactPerson?: (section: keyof Formdata, index: number) => void;
  handlePrevious: () => void;
  handleSaveAsDraftClick: () => void;
}

const ContactPerson: React.FC<ContactPersonProps> = ({
  agreementData,
  handleDateFormat,
  handleContactChange,
  section,
  errors,
  updateErrors,
  handleContactSelectChange,
  handleDeleteContactPerson,
  handleNext,
  handlePrevious,
  handleSaveAsDraftClick,
}) => {

  const [state, setState] = useState<{
    isLastTab: boolean;
    selectedContactPersonId: string | number | null | undefined;
    openAccordionIndex: number | null;
    showModal: boolean;
  }>({
    isLastTab: false,
    selectedContactPersonId: null,
    openAccordionIndex: null,
    showModal: false,
  });

  // Find the data for the selected contact person and its index
  const selectedDataWithIndex: any = agreementData?.reduce(
    (acc: any, data: CompanyContactPersonDetails, index: number) => {
      if (data?.contact_person_duties?.user?.id === state?.selectedContactPersonId) {
        acc = { data, index };
      }
      return acc;
    },
    { data: null, index: -1 }
  );
  const { data: selectedData, index: selectedIndex } = selectedDataWithIndex;

  const handleDeleteConfirm = () => {
    handleDeleteContactPerson?.(section, selectedIndex);
    setState(prevState => ({ ...prevState, showModal: false, selectedContactPersonId: null }));
  };

  const handleDeleteCancel = () => {
    setState(prevState => ({ ...prevState, showModal: false }));
  };

  const handlenext = () => {
    const allErrors: { [key: string]: any }[] = [];

    // Validate all accordion sections
    agreementData?.forEach((data: CompanyContactPersonDetails, index: number) => {
      const errors = validateSection(
        section,
        data,
        ContactinputFields,
        ContactNonMandatoryFields);
      allErrors?.push(errors);
      updateErrors(section, allErrors);
    });

    const allErrorsAreEmpty = allErrors?.every(errorObj => Object?.keys?.(errorObj)?.length === 0);
    if (allErrorsAreEmpty) {
      handleNext();
    } else {
      return;
    }
  };

  const options: Option[] = agreementData
    ?.filter((data: CompanyContactPersonDetails) => data?.status === true)
    ?.map((data: CompanyContactPersonDetails) => {
      const user = data?.contact_person_duties?.user;
      return {
        value: user?.id,
        label: `${user?.first_name} ${user?.last_name}`,
      };
    }) || [];

  const handleprevious = () => {
    handlePrevious();
  };

  const handleSelect = (selectedOption: Option) => {
    const selectedId = selectedOption?.value;
    const index = agreementData?.findIndex((data: CompanyContactPersonDetails) => data?.contact_person_duties?.user?.id === selectedId);

    if (index !== -1) {
      setState(prevState => ({
        ...prevState,
        selectedContactPersonId: selectedId,
        openAccordionIndex: index,
      }));
    }
  };
  return (
    <>
      <div>
        {/* Iterate over agreementData array */}
        {selectedData && selectedData?.status === true && (
          <div key={selectedData?.contact_person_duties?.user?.id} className="d-flex my-3">
            <AccordionCustom
              accordionTitle={`${selectedData?.contact_person_duties?.user?.first_name} ${selectedData?.contact_person_duties?.user?.last_name}`}
              accordionTitleClassName="w-100 poppins-medium dashboardAccordionTitle"
              accordionButton="dashboardAccordionHeader shadow-none"
              isOpen={state?.openAccordionIndex !== null}
            >
              <div className="row">
                {/* RadioGroup for Yes/No options */}
                <div className="col-3">
                  <div className="mb-2">
                    <LabelField title={t("Title")} isMandatory />
                  </div>
                  <RadioField
                    name="contact_person_duties.contact_title"
                    label={t("Yes")}
                    className="radioBtn"
                    ischecked={Number(selectedData?.contact_person_duties?.contact_title) === 1}
                    handleChange={(e) => handleContactChange?.(e, section, selectedIndex)}
                    id="yesLabourRegulations"
                    value={1}
                    containerClassName="companyLanguage d-inline-flex me-4"
                  />
                  <RadioField
                    name="contact_person_duties.contact_title"
                    label={t("No")}
                    className="radioBtn"
                    ischecked={Number(selectedData?.contact_person_duties?.contact_title) === 0}
                    handleChange={(e) => handleContactChange?.(e, section, selectedIndex)}
                    id="noLabourRegulations"
                    value={0}
                    containerClassName="companyLanguage d-inline-flex"
                  />
                  {errors?.[selectedIndex]?.[section]?.contact_person_duties?.contact_title && (
                    <div className="text-danger mt-1">{errors?.[selectedIndex]?.[section]?.contact_person_duties?.contact_title}</div>
                  )}
                </div>
                {/* Input Fields for contact person details */}
                <InputField
                  label="First name"
                  type="text"
                  name="contact_person_duties.user.first_name"
                  id="firstName"
                  isMandatory
                  value={selectedData?.contact_person_duties?.user?.first_name || ""}
                  error={errors?.[selectedIndex]?.[section]?.contact_person_duties?.user?.first_name || ""}
                  handleChange={(e) => handleContactChange?.(e, section, selectedIndex)}
                  section={section}
                />
                <InputField
                  label="Last name"
                  type="text"
                  name="contact_person_duties.user.last_name"
                  id="lastName"
                  isMandatory
                  value={selectedData?.contact_person_duties?.user?.last_name || ""}
                  error={errors?.[selectedIndex]?.[section]?.contact_person_duties?.user?.last_name || ""}
                  handleChange={(e) => handleContactChange?.(e, section, selectedIndex)}
                  section={section}
                />
                <InputField
                  label="Function title"
                  type="text"
                  name="contact_person_duties.func_title"
                  isMandatory
                  id="functionTitle"
                  value={selectedData?.contact_person_duties?.func_title || ""}
                  error={errors?.[selectedIndex]?.[section]?.contact_person_duties?.func_title || ""}
                  handleChange={(e) => handleContactChange?.(e, section, selectedIndex)}
                  section={section}
                />
                <InputField
                  label="Phone number"
                  type="tel"
                  name="contact_person_duties.user.phone_number"
                  isMandatory
                  id="phoneNo"
                  value={selectedData?.contact_person_duties?.user?.phone_number || ""}
                  error={errors?.[selectedIndex]?.[section]?.contact_person_duties?.user?.phone_number || ""}
                  handleChange={(e) => handleContactChange?.(e, section, selectedIndex)}
                  section={section}
                />
                <InputField
                  label="Mobile number"
                  type="tel"
                  name="contact_person_duties.user.phone_number"
                  isMandatory
                  id="mobileNo"
                  value={selectedData.contact_person_duties.user.phone_number || ""}
                  error={errors?.[selectedIndex]?.[section]?.contact_person_duties?.user?.phone_number || ""}
                  handleChange={(e) => handleContactChange?.(e, section, selectedIndex)}
                  section={section}
                />
                <InputField
                  label="Email address"
                  type="email"
                  name="contact_person_duties.user.email"
                  isMandatory
                  id="email"
                  value={selectedData?.contact_person_duties?.user?.email || ""}
                  error={errors?.[selectedIndex]?.[section]?.contact_person_duties?.user?.email || ""}
                  handleChange={(e) => handleContactChange?.(e, section, selectedIndex)}
                  section={section}
                />
                <div className="col-3">
                  <div className=" position-relative agreementStartDate">
                    <Calender
                      onChange={(date: Date | null) => {
                        if (date) {
                          handleDateFormat?.(date, "contact_person_duties.user.personal_details.date_of_birth", section, 0);
                        }
                      }}
                      label={t("Date of birth")}
                      selectedDate={selectedData?.contact_person_duties?.user?.personal_details?.date_of_birth ? new Date(selectedData?.contact_person_duties?.user?.personal_details?.date_of_birth) : null}
                      maxDate={new Date()}
                      name="contact_person_duties.user.personal_details.date_of_birth"
                    />
                  </div>
                </div>
                <div className="col-3 multiSelectWrapper">
                  <SelectWithSearch
                    fieldLabel={t("Location")}
                    title={t("Location")}
                    name="contact_person_duties.location.label"
                    search
                    options={selectedData?.contact_person_duties?.location ? Array(selectedData.contact_person_duties.location) : []}
                    value={
                      Array(selectedData?.contact_person_duties?.location)?.find(
                        (option: Option) => option?.label === selectedData?.contact_person_duties?.location?.label
                      ) || null
                    }
                    isSelectOption={false}
                    onChange={(e) => handleContactSelectChange?.(e, section, selectedIndex)}
                    isMulti={false}
                    className="select-field"
                    error={errors?.country || ""}
                    isTranslate
                    placeHolder={t("Select")}
                  />

                </div>
                <div className="col-3">
                  <div className="mb-2">
                    <LabelField title={t("Language")} isMandatory />
                  </div>
                  <RadioField
                    name="contact_person_duties.language"
                    label={t("Dutch")}
                    className="radioBtn"
                    ischecked={Number(selectedData?.contact_person_duties?.language) === 1}
                    handleChange={(e) => handleContactChange?.(e, section, selectedIndex)}
                    id="yesLabourRegulations"
                    value={1}
                    containerClassName="companyLanguage d-inline-flex me-4"
                  />
                  <RadioField
                    name="contact_person_duties.language"
                    label={t("French")}
                    className="radioBtn"
                    ischecked={Number(selectedData?.contact_person_duties?.language) === 2}
                    handleChange={(e) => handleContactChange?.(e, section, selectedIndex)}
                    id="noLabourRegulations"
                    value={2}
                    containerClassName="companyLanguage d-inline-flex"
                  />
                  {errors?.[selectedIndex]?.[section]?.contact_person_duties?.language && (
                    <div className="text-danger mt-1">{errors?.[selectedIndex]?.[section]?.contact_person_duties?.language}</div>
                  )}
                </div>
                <InputField
                  label="To be contacted for"
                  type="text"
                  name="contact_person_duties.contacted_for"
                  isMandatory
                  id="contactedFor"
                  value={selectedData?.contact_person_duties?.contacted_for || ""}
                  error={errors?.[selectedIndex]?.[section]?.contact_person_duties?.contacted_for || ""}
                  handleChange={(e) => handleContactChange?.(e, section, selectedIndex)}
                  section={section}
                />
                <div className="col-3">
                  <div className="mb-2">
                    <LabelField title={t("Decision maker")} isMandatory />
                  </div>
                  <RadioField
                    name="contact_person_duties.decision_maker"
                    label={t("Yes")}
                    className="radioBtn"
                    ischecked={Number(selectedData?.contact_person_duties?.decision_maker) === 1}
                    handleChange={(e) => handleContactChange?.(e, section, selectedIndex)}
                    id="yesLabourRegulations"
                    value={1}
                    containerClassName="companyLanguage d-inline-flex me-4"
                  />
                  <RadioField
                    name="contact_person_duties.decision_maker"
                    label={t("No")}
                    className="radioBtn"
                    ischecked={Number(selectedData?.contact_person_duties?.decision_maker) === 0}
                    handleChange={(e) => handleContactChange?.(e, section, selectedIndex)}
                    id="noLabourRegulations"
                    value={0}
                    containerClassName="companyLanguage d-inline-flex"
                  />
                  {errors?.[selectedIndex]?.[section]?.contact_person_duties?.decision_maker && (
                    <div className="text-danger mt-1">{errors?.[selectedIndex]?.[section]?.contact_person_duties?.decision_maker}</div>
                  )}
                </div>
                <div className="col-3">
                  <div className="mb-2">
                    <LabelField title={t("Influencer")} isMandatory />
                  </div>
                  <RadioField
                    name="contact_person_duties.influencer"
                    label={t("Yes")}
                    className="radioBtn"
                    ischecked={Number(selectedData?.contact_person_duties?.influencer) === 1}
                    handleChange={(e) => handleContactChange?.(e, section, selectedIndex)}
                    id="yesLabourRegulations"
                    value={1}
                    containerClassName="companyLanguage d-inline-flex me-4"
                  />

                  <RadioField
                    name="contact_person_duties.influencer"
                    label={t("No")}
                    className="radioBtn"
                    ischecked={Number(selectedData?.contact_person_duties?.influencer) === 0}
                    handleChange={(e) => handleContactChange?.(e, section, selectedIndex)}
                    id="noLabourRegulations"
                    value={0}
                    containerClassName="companyLanguage d-inline-flex"
                  />
                  {errors?.[selectedIndex]?.[section]?.contact_person_duties?.influencer && (
                    <div className="text-danger mt-1">{errors?.[selectedIndex]?.[section]?.contact_person_duties?.influencer}</div>
                  )}
                </div>
                <div className="col-3">
                  <div className="mb-2">
                    <LabelField title={t("Contact person can be called")} isMandatory />
                  </div>
                  <RadioField
                    name="contact_person_duties.contact_called"
                    label={t("Yes")}
                    className="radioBtn"
                    ischecked={Number(selectedData?.contact_person_duties?.contact_called) === 1}
                    handleChange={(e) => handleContactChange?.(e, section, selectedIndex)}
                    id="yesLabourRegulations"
                    value={1}
                    containerClassName="companyLanguage d-inline-flex me-4"
                  />
                  <RadioField
                    name="contact_person_duties.contact_called"
                    label={t("No")}
                    className="radioBtn"
                    ischecked={Number(selectedData?.contact_person_duties?.contact_called) === 0}
                    handleChange={(e) => handleContactChange?.(e, section, selectedIndex)}
                    id="noLabourRegulations"
                    value={0}
                    containerClassName="companyLanguage d-inline-flex"
                  />
                  {errors?.[selectedIndex]?.[section]?.contact_person_duties?.contact_called && (
                    <div className="text-danger mt-1">{errors?.[selectedIndex]?.[section]?.contact_person_duties?.contact_called}</div>
                  )}
                </div>
                <div className="col-3">
                  <div className="mb-2">
                    <LabelField title={t("Contact person can be mailed")} isMandatory />
                  </div>
                  <RadioField
                    name="contact_person_duties.contact_mailed"
                    label={t("Yes")}
                    className="radioBtn"
                    ischecked={Number(selectedData?.contact_person_duties?.contact_mailed) === 1}
                    handleChange={(e) => handleContactChange?.(e, section, selectedIndex)}
                    id="yesLabourRegulations"
                    value={1}
                    containerClassName="companyLanguage d-inline-flex me-4"
                  />
                  <RadioField
                    name="contact_person_duties.contact_mailed"
                    label={t("No")}
                    className="radioBtn"
                    ischecked={Number(selectedData?.contact_person_duties?.contact_mailed) === 0}
                    handleChange={(e) => handleContactChange?.(e, section, selectedIndex)}
                    id="noLabourRegulations"
                    value={0}
                    containerClassName="companyLanguage d-inline-flex"
                  />
                  {errors?.[selectedIndex]?.[section]?.contact_person_duties?.contact_mailed && (
                    <div className="text-danger mt-1">{errors?.[selectedIndex]?.[section]?.contact_person_duties?.contact_mailed}</div>
                  )}
                </div>
              </div>
            </AccordionCustom>
            <span
              onClick={() => setState((prevState: any) => ({
                ...prevState,
                showModal: true,
              }))}
              title={t("Delete contact person")}
              className="secondaryColorHoverEffect mt-2 pt-1"
            >
              <Icon isIcon={true} width="1vw" height="1vw" name="delete" />
            </span>
            <Popup
              show={state?.showModal}
              popupActionNo={handleDeleteCancel}
              popupActionYes={handleDeleteConfirm}
              title={t("Delete Contact Person")}
              heading={t("Do you want to delete contact persons?")}
            />

            {/* Add the Contact Person Selector */}

          </div>
        )}
        <div className="multiSelectWrapper mt-3">
          <SelectWithSearch
            fieldLabel={t("Select contact person")}
            title={t("Select contact person")}
            name="contactPerson"
            search
            options={options || []}
            value={state?.selectedContactPersonId
              ? options?.find((option: Option) => option.value === state?.selectedContactPersonId)
              : null}
            onChange={handleSelect}
            isMulti={false}
            className="select-field"
            error={errors?.contactPerson || ""}
            isTranslate
          />
        </div>
      </div>
      <Navigation
        isLastTab={state?.isLastTab}
        onPrevious={handleprevious}
        onNext={handlenext}
        handleSaveAsDraftClick={handleSaveAsDraftClick}
      />

    </>
  );
};

export default ContactPerson;
