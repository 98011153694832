import React from "react";
import LabelField from "../../../../common/atoms/LabelField";
import { t } from "../../../../CentralDataMangement/translation/Translation";
import ToggleButton from "../../../../common/atoms/ToggleButton";

interface PrivacyOrganismProps {
  formState: any;
  handleToggle: (field: string, value: boolean) => void;
}

const PrivacyOrganism: React.FC<PrivacyOrganismProps> = ({
  formState,
  handleToggle,
}) => {
  const cardTitleProps = {
    className: "myAccountCardTitle colorPrimary mb-3",
  };

  return (
    <div className="contentBg p-0">
      <LabelField title={t("Privacy")} {...cardTitleProps} />

      {/* Communication via Mail */}
      <div className="row mt-4 mb-3">
        <div className="col-lg-7">
          <LabelField
            title={<>
              {t("Commmunication via mail") + ":"} <ToggleButton
                value={formState?.formValues?.privacy?.via_email}
                onToggle={(value: boolean) => handleToggle("via_email", value)}
                toggleClassName="d-inline-block ms-3"
              />
            </>}
            className="colorPrimary poppins-medium"
          />
        </div>

      </div>

      {/* Communication via Phone */}
      <div className="row mb-5">
        <div className="col-lg-7">
          <LabelField
            title={<>
              {t("Commmunication via phone") + ":"}
              <ToggleButton
                value={formState?.formValues?.privacy?.via_phone}
                onToggle={(value: boolean) => handleToggle("via_phone", value)}
                toggleClassName="d-inline-block ms-3"
              />
            </>}
            className="colorPrimary poppins-medium"
          />
        </div>

      </div>

      {/* Privacy Text */}
      <div className="row">
        <div className="col-12">
          <div className="mb-3 privacyText">
            {t(
              "AbsoluteYOU! collects, stores and protects your personal data in order to offer you a better and more personalised service"
            ) + ":"}
          </div>
          <div className="mb-3 privacyText">
            {t(
              "You can decide at any time how our office may contact you to, for example, deliver your documents, ask your opinion as part of a survey or contact you regarding your file"
            ) + ":"}
          </div>
          <div className="privacyText">
            {t(
              "Simply select the means of communication you prefer above"
            ) + "."}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyOrganism;
