import React, { useEffect, useState } from "react";
import BackLink from "../../common/atoms/BackLink";
import Header from "../../common/layout/Header";
import OverFlowWrapper from "../../common/OverFlowWrapper";
import { t, translate } from "../translation/Translation";
import LocationCard from "./LocationCard";
import { useSelector } from "react-redux";
import { LOCATIONS, LOCATIONS_BY_COMPANY } from "../../../routes/ApiEndpoints";
import { ApiCall } from "../../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingIcon from "../../common/utlis/LoadingIcon";
import { MANAGE_LOCATION_MANAGERS } from "../../../routes/RouteConstants";
import CommonPopUp from "../../common/atoms/CommonPopUp";
import CustomNotify from "../../common/atoms/CustomNotify";
import NoRecords from "../../common/atoms/NoRecords";
import LabelField from "../../common/atoms/LabelField";

interface Location {
  id: number;
  name: string;
  location_image?: {
    file_url: string;
  } | null;
  is_active: boolean;
  address_id: number;
  company_id: number;
}

const ManageLocationNew: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const companyId = useSelector((state: any) => state.auth.currentCompany.id);
  const [locations, setLocations] = useState<Location[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null
  );
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  useEffect(() => {
    if (id) {
      getLocations(id);
    }
    else {
      getLocations(companyId);
    }
  }, [companyId]);

  const getLocations = async (companyId: number | string) => {
    setLoading(true);
    try {
      let payload = { company_id: companyId };
      const response = await ApiCall.service(
        LOCATIONS_BY_COMPANY,
        "POST",
        payload,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status == "success") {
        const locationsData = response.data.map((item: any) => ({
          id: item.id,
          name: item.name,
          location_image: item.location_image
            ? { file_url: item.location_image.file_url }
            : null,
          is_active: item.is_active,
          address_id: item.address_id,
          company_id: item.company_id,
        }));
        setLocations(locationsData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  console.log(locations, 111);

  const handleEditLocation = (locationId: number) => {
    navigate(`/location/edit/${locationId}`);
  };

  const handleManageAdministrator = (locationId: number) => {
    navigate(`/manage/location-managers/${locationId}`);
  };

  const handleIsActiveClick = (location: Location) => {
    setSelectedLocation(location);
    setShowConfirmPopup(true);
  };

  const handleConfirm = async () => {
    if (!selectedLocation) return;
    try {
      selectedLocation.is_active = !selectedLocation.is_active;

      const response = await ApiCall.service(
        `${LOCATIONS}/${selectedLocation.id}`,
        "PUT",
        selectedLocation,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (response.status == "success") {
        const message = response?.data?.active
          ? "Location successfully activated"
          : "Location successfully made inactive";

        CustomNotify({
          type: "success",
          message: t(message),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowConfirmPopup(false);
    }
  };

  return (
    <>
      <Header
        headerName={t("Manage locations")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-md-2">
        <div className="row">
          <BackLink backLink="/welcome-dashboard" />
          <div className="col-8 align-self-center text-center d-block d-lg-none">
            <LabelField
              title={t("Manage locations")}
              className="myAccountCardTitle mb-0"
            />
          </div>
          <div className="col-6 text-end ms-auto button-underline">
            <Link to="/location/add">
              <div className="searchBtn d-inline-flex align-items-center justify-content-center text-decoration-none w-auto px-3">
                {t("+ Add location")}
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="createpagesBg managelocationBg ">
        <OverFlowWrapper className="yourPoolWrapper">
          <div>
            {loading ? (
              <div className="text-center py-5">
                <LoadingIcon />
              </div>
            ) : (
              <>
                {locations.length > 0 ? (
                  <div
                    className="d-grid grid-4"
                    style={{
                      gap: "1vw",
                    }}
                  >
                    {locations.map((location) => (
                      <div className="mb-3" key={location.id}>
                        <LocationCard
                          companyName={location.name}
                          isComapny={true} // Corrected prop name
                          isLocationManager={true}
                          handleEditClick={() =>
                            handleEditLocation(location.id)
                          }
                          handleManageAdministrator={() =>
                            handleManageAdministrator(location.id)
                          }
                          is_active={location.is_active}
                          handleIsActive={() => {
                            setSelectedLocation(location);
                            setShowConfirmPopup(true);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <NoRecords
                    isDiv={true}
                    message={t("No locations found")}
                    color="text-dark"
                  />
                )}

                {showConfirmPopup && selectedLocation && (
                  <CommonPopUp
                    size="lg"
                    show={showConfirmPopup}
                    onHide={() => setShowConfirmPopup(false)}
                    title={t("Confirm Action")}
                    body={
                      selectedLocation.is_active
                        ? t(
                          "Are you sure you want to make this location inactive?"
                        )
                        : t(
                          "Are you sure you want to make this location active?"
                        )
                    }
                    closeTitle={t("Cancel")}
                    confirmTitle={t("Yes")}
                    onCloseButtonClick={() => setShowConfirmPopup(false)}
                    onConfirmButtonClick={handleConfirm}
                  />
                )}
              </>
            )}
          </div>
        </OverFlowWrapper>
      </div>
    </>
  );
};

export default translate(ManageLocationNew);
