import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectContractType, selectDates, selectEmployeeScheduleById, selectTab } from "../../features/planning/planningSlice";
import { translate } from "../CentralDataMangement/translation/Translation";
import WeekCalendar from "../common/atoms/WeekCalendar";
import PlanningShiftsTimings from "./PlanningShiftsTimings";
import { RootState } from "../../store";
import MonthlySchedule from "./MonthlySchedule";

// Define the types for the props
interface EmployeeShiftTableProps {
  onEmployeeSelected: {
    employee_id: number,
    pc_id: number,
    age: number,
    schedule: ScheduleMap,
    is_birthday: boolean,
    birthday_date: string
  };
}
interface Shifts {
  start_time: string;
  end_time: string;
  sequence: number;
}

interface Schedule {
  location_id: number;
  project_id?: number;
  cost_center_id?: number;
  shifts: Shifts[];
}

interface ScheduleMap {
  [date: string]: Schedule;
}

const EmployeeShiftTable: React.FC<EmployeeShiftTableProps> = ({ onEmployeeSelected }) => {
  const monthView = useSelector(selectContractType);
  const tab_3 = useSelector(selectTab) == 'tab_3';
  const selectedDatesFromRedux = useSelector(selectDates); // Assuming this is from Redux
  // Use the selector to get the employee schedule from the Redux store
  const schedule = useSelector((state: RootState) =>
    selectEmployeeScheduleById(state, onEmployeeSelected.employee_id)
  );
  console.log(onEmployeeSelected);
  return (
    <div className="row">
      <div className="col-12 mx-auto px-lg-0">
        <div className="row mb-3">
          {!tab_3 && monthView === "month" && (
            <div className="col-lg-4 text-start">
              <MonthlySchedule
                employee={onEmployeeSelected.employee_id}
                planningSchedule={schedule}
              />
            </div>
          )}
          {!tab_3 && monthView === "week" && (
            <div className="col-lg-4 my-3 my-lg-0">
              <WeekCalendar
                startDate={selectedDatesFromRedux[0]} // If Redux is providing start date
                planningSchedule={schedule}
                employee={onEmployeeSelected.employee_id}
              />
            </div>
          )}
          <div className={!tab_3 ? (monthView == "month" ? 'col-8' : 'col-lg-8 employeeWeeklyPlanningTable scrollBar mx-auto') : 'col-lg-11 planningOverviewHeight scrollBar'}>
            <PlanningShiftsTimings
              planningSchedule={schedule}
              employee={onEmployeeSelected.employee_id}
              pcId={onEmployeeSelected.pc_id}
              age={onEmployeeSelected.age}
              isBirthday={onEmployeeSelected.is_birthday}
              birthDay={onEmployeeSelected.birthday_date}
            />
          </div>
        </div>
      </div>
    </div >
  );
};

export default translate(EmployeeShiftTable);
