import React, { useContext, useEffect } from "react";
import LinkCoeffEmpContext from "./LinkCoeffEmp/LinkCoeffEmpContext";
import { helpers } from "./LinkCoeffEmpHelper";
import {
  t,
  translate,
} from "../../../CentralDataMangement/translation/Translation";

const EmployeeTypeSecondPart = () => {
  const { state, updateStateChanges } = useContext(LinkCoeffEmpContext);
  const {
    employeeTypeArray,
    valueTypeArray,
    coefficientTypeArray,
    pclinkingValueobj,
    lowHighValidation,
    inputRef,
    valueErrorArray,
    defaultValueError,
    selectedPc,
    lowKey = '1',
    highKey = '3',
    defaultKey = '2',
    minValue = 0,
    maxValue = 10,
  } = state;

  useEffect(() => {
    const current = inputRef.current["secondpart"];
    if (current) {
      const hasOverflow = current.scrollWidth > current.clientWidth;
      updateStateChanges({
        isOverflow: hasOverflow,
        scrollRight: true,
        scrollLeft: false,
        tableWidth: "97%",
      });
    }
  }, [selectedPc, inputRef]);

  const handleValueChange = (target, _EmpId, _Coeffid, _ValId) => {
    let value = target.value;
    const regexp = /^[0-9]*(\.|\,)?[0-9]*$/;

    if (!value.match(regexp) || checkDecimalPointError(value)) return;

    let updatedValueObj = {
      ...pclinkingValueobj,
      [_EmpId]: {
        ...(pclinkingValueobj[_EmpId] || {}),
        [_Coeffid]: {
          ...(pclinkingValueobj[_EmpId]?.[_Coeffid] || {}),
          [_ValId]: value,
        },
      },
    };

    // Validate all three types of errors
    const newValueErrorArray = valueValidation(_EmpId, _Coeffid, _ValId, value);
    const newDefaultValueError = validationForDefaultValue(updatedValueObj, _EmpId, _Coeffid);
    const newLowHighValidation = handleValidation(updatedValueObj, _EmpId, _Coeffid, _ValId);

    updateStateChanges({
      pclinkingValueobj: updatedValueObj,
      valueErrorArray: newValueErrorArray,
      defaultValueError: newDefaultValueError,
      lowHighValidation: newLowHighValidation,
      emptyDataWarning: false,
    });
  };

  const checkDecimalPointError = (value) => {
    if (!value) return false;
    const inputVal = value.replace(",", ".");
    const decimals = inputVal.split(".")[1];
    return decimals && decimals.length > 4;
  };

  const validationForDefaultValue = (valueDataObj, _EmpId, _Coeffid) => {
    const lowHighDef = valueDataObj[_EmpId]?.[_Coeffid] || {};
    const refkey = `${_EmpId}_${_Coeffid}_${defaultKey}`;
    const lowVal = Number(lowHighDef[lowKey]?.replace(",", ".") || 0);
    const highVal = Number(lowHighDef[highKey]?.replace(",", ".") || 0);
    const defaultVal = Number(lowHighDef[defaultKey]?.replace(",", ".") || 0);

    let newDefaultValueError = [...defaultValueError];

    if (lowHighDef[lowKey] && lowHighDef[highKey] && lowHighDef[defaultKey]) {
      if (defaultVal < lowVal || defaultVal > highVal) {
        if (!newDefaultValueError.includes(refkey)) {
          newDefaultValueError.push(refkey);
          helpers.toggleWarningClass(inputRef, refkey);
        }
      } else {
        const index = newDefaultValueError.indexOf(refkey);
        if (index > -1) {
          newDefaultValueError.splice(index, 1);
          helpers.toggleWarningClass(inputRef, refkey, 0);
        }
      }
    }

    return newDefaultValueError;
  };

  const valueValidation = (_EmpId, _Coeffid, _ValId, inputVal) => {
    const value = Number(inputVal.replace(",", "."));
    const refkey = `${_EmpId}_${_Coeffid}_${_ValId}`;
    let newValueErrorArray = [...valueErrorArray];

    if (value < minValue || value > maxValue) {
      if (!newValueErrorArray.includes(refkey)) {
        newValueErrorArray.push(refkey);
        helpers.toggleWarningClass(inputRef, refkey);
      }
    } else {
      const index = newValueErrorArray.indexOf(refkey);
      if (index > -1) {
        newValueErrorArray.splice(index, 1);
        helpers.toggleWarningClass(inputRef, refkey, 0);
      }
    }

    return newValueErrorArray;
  };

  const handleValidation = (valueDataObj, _EmpId, _Coeffid) => {
    const valObj = valueDataObj[_EmpId]?.[_Coeffid] || {};
    const lowVal = valObj[lowKey];
    const highVal = valObj[highKey];

    if (!lowVal || !highVal) return lowHighValidation;

    return compareValues(
      Number(lowVal.replace(",", ".")),
      Number(highVal.replace(",", ".")),
      `${_EmpId}_${_Coeffid}`
    );
  };

  const compareValues = (lowVal, highVal, refkey) => {
    let newLowHighValidation = [...lowHighValidation];
    const lowRef = inputRef.current[`${refkey}_${lowKey}`];
    const highRef = inputRef.current[`${refkey}_${highKey}`];

    if (!lowRef || !highRef) return newLowHighValidation;

    if (lowVal >= highVal) {
      if (!newLowHighValidation.includes(refkey)) {
        newLowHighValidation.push(refkey);
        lowRef.classList.add("warning");
        highRef.classList.add("warning");
      }
    } else {
      const index = newLowHighValidation.indexOf(refkey);
      if (index > -1) {
        newLowHighValidation.splice(index, 1);
        lowRef.classList.remove("warning");
        highRef.classList.remove("warning");
      }
    }

    return newLowHighValidation;
  };

  const getEmployeeTypeTableContent = () => {
    return coefficientTypeArray.map(coefficient =>
      valueTypeArray.map(valueType => (
        <tr key={`${coefficient.id}-${valueType.id}`} className="table-second-part-tbody-tr">
          {employeeTypeArray.map(employeeType => {
            const _EmpId = employeeType.id;
            const _ValId = valueType.id;
            const _Coeffid = coefficient.id;
            const { value } = getPcLinkingValue(_EmpId, _Coeffid, _ValId);

            return (
              <td key={`${_EmpId}-${_Coeffid}-${_ValId}`}>
                <input
                  type="text"
                  className="form-control border border-white shadow-none text-center"
                  value={value}
                  id={`${_EmpId}_${_Coeffid}_${_ValId}`}
                  ref={ref => (inputRef.current[`${_EmpId}_${_Coeffid}_${_ValId}`] = ref)}
                  onChange={e => handleValueChange(e.target, _EmpId, _Coeffid, _ValId)}
                />
              </td>
            );
          })}
        </tr>
      ))
    );
  };

  const getPcLinkingValue = (_EmpId, _Coeffid, _ValId) => {
    const valueObj = pclinkingValueobj[_EmpId]?.[_Coeffid] || {};
    return {
      value: valueObj[_ValId] ? valueObj[_ValId].replace(".", ",") : "",
    };
  };

  return (
    <div ref={ref => (inputRef.current["secondpart"] = ref)}>
      <table className="table mb-0 defaultCoefficientTableRightSection table-bordered customTableProperties">
        <thead>
          <tr className="TableHeader align-middle" style={{
            height: window.innerWidth <= 767
              ? "3.5vw"
              : window.innerWidth <= 1023
                ? "10.99vw"
                : "5.99vw"
          }}>
            {employeeTypeArray.map(emp => (
              <th className="text-center" key={emp.id} title={emp.name}>
                {emp.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {getEmployeeTypeTableContent()}
        </tbody>
      </table>
    </div>
  );
};

export default translate(EmployeeTypeSecondPart);
