import React from "react";

interface ImageProps {
  src?: string;
  altName?: string;
  title?:string;
  imageBorderRadius?: string | number;
  imageWidth?: string | number;
  imageHeight?: string | number;
  imageBackgroundColor?: string;
  className?:string;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
}

const Image: React.FC<ImageProps> = ({
  src,
  altName,
  imageBorderRadius,
  imageWidth,
  title,
  imageHeight,
  imageBackgroundColor,
  className,
  onClick,
}) => {
  // Convert borderRadius, width, and height to style object
  const style: React.CSSProperties = {
    borderRadius:
      typeof imageBorderRadius === "number"
        ? `${imageBorderRadius}px`
        : imageBorderRadius,
    width: typeof imageWidth === "number" ? `${imageWidth}vw` : imageWidth,
    height: typeof imageHeight === "number" ? `${imageHeight}vw` : imageHeight,
    backgroundColor: imageBackgroundColor,
  };

  return (
    <img src={src} alt={altName} title={altName} className={`${className} mx-auto`} style={style} onClick={onClick} />
  );
};

export default Image;
