import React, { ChangeEvent, ReactNode } from "react";

interface Props {
  name?: string;
  value?: string | number;
  ischecked?: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: React.ReactNode;
  customStyle?: React.CSSProperties;
  id?: string;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  inputClass?: string;
  children?: ReactNode;
  isChildren?: boolean;
}

const RadioField: React.FC<Props> = ({
  name = "",
  value = "",
  ischecked = false,
  handleChange,
  label,
  customStyle = {},
  id = "",
  className,
  containerClassName = "d-inline-block w-auto me-4", //default
  disabled = false, // should default to false
  inputClass,
  children,
  isChildren
}) => {

  return (
    <span className={containerClassName}>
      <input
        style={{ ...customStyle, marginRight: "0.25vw" }}
        type="radio"
        name={name}
        checked={ischecked}
        onChange={!disabled ? handleChange : undefined} // Disable change handling if the input is disabled
        id={id}
        value={value}
        disabled={disabled} // Properly apply the disabled state
        className={`form-check-input shadow-none cursor-pointer select_border ${inputClass}`}
      />
      {isChildren && (
        <div
          className="clickable-container cursor-pointer"
        >
          {children}
        </div>
      )}
      <label
        style={{ cursor: disabled ? "not-allowed" : "pointer", lineHeight:"1vw" }} // Ensure cursor shows correctly when disabled
        className={className || ""}
      >
        <span style={{ fontSize: "0.8vw", lineHeight: "1vw" }}>{label}</span>
      </label>
    </span>
  );
};

export default RadioField;
