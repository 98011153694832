import React, { useState } from "react";
import { t } from "../CentralDataMangement/translation/Translation";
import LabelField from "../common/atoms/LabelField";
import RadioField from "../common/atoms/RadioField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import { LabelWithInputField } from "../common/molecules/LabelWithInputField";
import Navigation from "./Navigation";
import { InvoicingProps, Option } from "./Interfaces";
import { getNestedValue } from "./CompanyInformation";
import { validateForm } from "./Service";

const Invoicing: React.FC<InvoicingProps> = ({
  agreementData,
  handleChange,
  section,
  handleRadioChange,
  handleSelectChange,
  countries,
  errors,
  updateErrors,
  handleNext,
  handlePrevious,
  handleSaveAsDraftClick,
}) => {
  const correctionInvoiceOptions: Option[] = [
    { value: 0, label: "Correctie op één document" },
    { value: 1, label: "Creditnota en nieuwe factuur" },
  ];          // Need to update when options API is ready
  const countryOptions = countries?.countries || [];

  const handlePreviousButton = () => {
    console.log("Previous clicked");
    handlePrevious();
  };

  const handleSubmitButton = () => {
    const formErrors = validateForm(agreementData);
    // console.log("Form errors:", formErrors);
    if (Object.keys(formErrors).length > 0) {
      updateErrors(section, formErrors);
      return;
    } else {
      updateErrors(section, formErrors);
      handleNext();
    }
  };

  // Reusable function to render input fields
  const renderInputField = (
    type: string,
    label: string,
    name: string,
    isMandatory = false
  ) => (
    <LabelWithInputField
      type={type}
      label={t(label)}
      value={getNestedValue(agreementData, name) || ""}
      name={name}
      id={name}
      handleChange={(e) => handleChange(e, section)}
      error={errors?.[name] || ""}
      className="ps-2"
      isMandatory={isMandatory}
    />
  );

  // Reusable function to render radio fields
  const renderRadioFieldGroup = (title: string, name: string) => (
    <div className="col-4 mb-3">
      <div className="mb-2">
        <LabelField title={t(title)} isMandatory={true} />
      </div>
      {errors?.[name] && (
        <div className="text-danger mt-1">{errors?.[name]}</div>
      )}
      <RadioField
        name={name}
        label={t("Yes")}
        className="radioBtn"
        ischecked={agreementData?.[name]}
        handleChange={(e) => handleRadioChange?.(name, true, section)}
        id={`${name}Yes`}
        containerClassName="companyLanguage d-inline-flex me-3"
      />
      <RadioField
        name={name}
        label={t("No")}
        className="radioBtn"
        ischecked={!agreementData?.[name]}
        handleChange={(e) => handleRadioChange?.(name, false, section)}
        id={`${name}No`}
        containerClassName="companyLanguage d-inline-flex"
      />
    </div>
  );

  return (
    <>
      <div className="row">
        {/* Input Fields */}
        <div className="col-6 mb-3">
          {renderInputField(
            "text",
            "Invoicing contact person first name",
            "invoice_person_first_name",
            true
          )}
        </div>
        <div className="col-6 mb-3">
          {renderInputField(
            "text",
            "Invoicing contact person last name",
            "invoice_person_last_name",
            true
          )}
        </div>
        <div className="col-4 mb-3">
          {renderInputField(
            "text",
            "Invoicing address: Street",
            "address.street",
            true
          )}
        </div>
        <div className="col-4 mb-3">
          {renderInputField(
            "text",
            "Invoicing address: House number",
            "address.number",
            true
          )}
        </div>
        <div className="col-4 mb-3">
          {renderInputField(
            "text",
            "Invoicing address: Box number",
            "address.box"
          )}
        </div>
        <div className="col-4 mb-3">
          {renderInputField(
            "text",
            "Invoicing address: Postal code",
            "address.postal_code",
            true
          )}
        </div>
        <div className="col-4 mb-3">
          {renderInputField(
            "text",
            "Invoicing address: City",
            "address.city",
            true
          )}
        </div>
        <div className="col-4 multiSelectWrapper mb-3">
          <SelectWithSearch
            fieldLabel={`${t("Invoicing address: Country")}:`}
            title={t("Invoicing address: Country")}
            name="address.country"
            placeHolder={t("Select")}
            search
            isMandatory
            options={countryOptions || []}
            value={
              countryOptions?.find(
                (option: Option) =>
                  option.value === agreementData?.address?.country
              ) || null
            }
            onChange={(selectedOption: Option) => {
              handleSelectChange(selectedOption, "address.country", section);
            }}
            isMulti={false}
            className="select-field"
            error={errors?.["address.country"] || ""}
            isTranslate
          />
          {/* {renderInputField(
            "text",
            "Invoicing address: Country",
            "address.country",
            true
          )} */}
        </div>
        <div className="col-4 mb-3">
          {renderInputField(
            "tel",
            "Invoicing address: Phone number",
            "phone_number"
          )}
        </div>
        <div className="col-4 mb-3">
          {renderInputField(
            "text",
            "Invoicing address: Fax number",
            "fax_number"
          )}
        </div>
        <div className="col-4 mb-3">
          {renderInputField(
            "email",
            "Invoicing address: Email",
            "invoice_mail",
            true
          )}
        </div>
        <div className="col-4 mb-3">
          <div className="position-relative vatRate">
            {renderInputField("text", "VAT rate", "vat_rate", true)}
            <span className="fieldSuffix position-absolute">{t("%")}</span>
          </div>
        </div>

        {/* Radio Fields */}
        {renderRadioFieldGroup("VAT applicable", "vat_applicable")}
        {renderRadioFieldGroup(
          "Payment via domiciliering",
          "domicilry_payment"
        )}
        {renderRadioFieldGroup("Private customer", "private_customer")}
        {renderRadioFieldGroup("Digital Invoicing", "digital_invoice")}
        {renderRadioFieldGroup("Invoice frequency", "invoice_frequency")}
        {renderRadioFieldGroup(
          "Separate invoice per location",
          "invoice_per_location"
        )}
        {renderRadioFieldGroup("Subtotal per cost center", "total_per_cc")}
        {renderRadioFieldGroup(
          "Separate invoicing for workers (arbeiders) and servants (bedienden)",
          "invoice_per_worker_servant"
        )}
        {renderRadioFieldGroup(
          "Line with total of actuals worked hours per cost center needs to be added",
          "actual_work_hours_per_cc"
        )}

        <div className="col-4 mb-3">
          {renderInputField(
            "email",
            "Email address digital invoicing",
            "digital_invoice_mail",
            true
          )}
        </div>

        {/* Select Fields */}
        <div className="col-4 multiSelectWrapper">
          <SelectWithSearch
            fieldLabel={t("Payment condition (standard payment condition)")}
            title={t("Payment condition (standard payment condition)")}
            name="payment_condition"
            placeHolder={t("Select")}
            search
            isDisabled
            options={[{ value: 1, label: "14 dagen" }]}
            value={{ value: 1, label: "14 dagen" }      // Need to update when options API is ready
              // correctionInvoiceOptions?.find(
              //   (option: Option) =>
              //     option.value === agreementData?.payment_condition
              // ) || null
            }
            onChange={(selectedOption: Option) => {
              handleSelectChange(selectedOption, "payment_condition", section);
            }}
            isMulti={false}
            className="select-field"
            error={errors?.payment_condition || ""}
            isTranslate
          />
        </div>
        <div className="col-4 multiSelectWrapper">
          <SelectWithSearch
            fieldLabel={t("Type of correction invoice")}
            title={t("Type of correction invoice")}
            name="invoice_correction_type"
            placeHolder={t("Select")}
            search
            options={correctionInvoiceOptions}
            value={
              correctionInvoiceOptions?.find(
                (option: Option) =>
                  option.value === agreementData?.invoice_correction_type
              ) || null
            }
            onChange={(selectedOption: Option) => {
              handleSelectChange(
                selectedOption,
                "invoice_correction_type",
                section
              );
            }}
            isMulti={false}
            className="select-field"
            error=""
            isTranslate
          />
        </div>
        {renderRadioFieldGroup(
          "Separate invoice per employee",
          "invoice_per_employee"
        )}
        {renderRadioFieldGroup(
          "Invoicing of medical health checks",
          "medical_health_check"
        )}
        {renderRadioFieldGroup(
          "Detailed lines per interim person should be added",
          "interim_person_details"
        )}
        {renderRadioFieldGroup("Discount contact", "contact_discount")}
        {renderRadioFieldGroup(
          "Line with total of actuals worked hours needs to be added",
          "actual_work_hours"
        )}
        <div className="col-4 mb-3">
          {renderInputField(
            "text",
            "Customer code accounting (klantencode boekhouding)",
            "customer_code"
          )}
        </div>
      </div>
      <Navigation
        isLastTab={true}
        onPrevious={handlePreviousButton}
        onSubmit={handleSubmitButton}
        handleSaveAsDraftClick={handleSaveAsDraftClick}
      />
    </>
  );
};

export default Invoicing;
