import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DateAndTime from "../common/atoms/DateWithTime";
import HeaderWIthFilter from "./HeaderWIthFilter";
import PlanningTable from "./PlanningTable";
import PlanningTabs from "./PlanningTabs";
import { selectPlanningShow } from "../../features/planning/planningSlice";
import { selectAuth, selectCurrentLocations } from "../../features/auth/AuthSlice";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import OverFlowWrapper from "../common/OverFlowWrapper";

interface Employee {
  id: number;
  first_name: string;
  last_name: string;
  is_fav: boolean;
  profile_picture_url: string | null;
  employee_type: string;
  employee_type_id: number;
  employee_type_category: string;
  employee_type_category_id: number;
  paritaire_commitee: string;
  paritaire_commitee_id: number;
  functional_salary: string;
  function_id: number;
  function_name: string;
}

interface DateFilteredEmployees {
  [key: string]: Employee[];
}

const DayPlanningOverview = () => {
  const show = useSelector(selectPlanningShow);
  const companyId = useSelector((state: any) => state.auth.currentCompany?.id);
  const locations = useSelector(selectCurrentLocations) ?? [];
  const authData = useSelector(selectAuth);

  const [date, setDate] = useState(new Date());
  const [selectedLocationIndex, setSelectedLocationIndex] = useState(0);
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const [dateFilteredEmployees, setDateFilteredEmployees] = useState<DateFilteredEmployees>({});

  // Format date to use as key in dateFilteredEmployees
  const formatDateKey = (date: Date) => {
    return date.toISOString().split('T')[0];
  };

  const handleDateChange = (newDate: Date) => {
    setDate(newDate);
    // When date changes, load the saved employees for that date if they exist
    const dateKey = formatDateKey(newDate);
    if (dateFilteredEmployees[dateKey]) {
      setSelectedEmployees(dateFilteredEmployees[dateKey]);
    } else {
      setSelectedEmployees([]);
    }
  };

  const handleLocationChange = (index: number) => {
    setSelectedLocationIndex(index);
  };

  const handleEmployeeClick = (employee: Employee) => {
    const dateKey = formatDateKey(date);

    setSelectedEmployees((prevSelected = []) => {
      let newSelected: any;
      if (prevSelected?.find((emp) => emp.id === employee.id)) {
        newSelected = prevSelected?.filter((emp) => emp.id !== employee.id);
      } else {
        newSelected = [...prevSelected, employee];
      }

      // Update the dateFilteredEmployees with the new selection
      setDateFilteredEmployees(prev => ({
        ...prev,
        [dateKey]: newSelected
      }));

      return newSelected;
    });
  };

  const updateEmployeeSchedule = (employeeIds: number[]) => {
    const dateKey = formatDateKey(date);

    const updatedSelectedEmployees = selectedEmployees.filter(emp =>
      !employeeIds.includes(emp.id)
    );

    // Update both the selected employees and the date-filtered map
    setSelectedEmployees(updatedSelectedEmployees);
    setDateFilteredEmployees(prev => ({
      ...prev,
      [dateKey]: updatedSelectedEmployees
    }));
  };

    // Add new function to handle employee removal
    const removeEmployeefromSelected = (employeeId: number) => {
      setSelectedEmployees((prevSelected = []) => {
        const newSelected = prevSelected.filter(emp => emp.id !== employeeId);
        
        // Update dateFilteredEmployees as well
        const dateKey = formatDateKey(date);
        setDateFilteredEmployees(prev => ({
          ...prev,
          [dateKey]: newSelected
        }));
  
        return newSelected;
      });
    };

  return (
    <>
      <div>
        <HeaderWIthFilter
          headerName={t("Planning - Day")}
          type="day"
          selectedLocation={locations[selectedLocationIndex]}
          onLocationChange={handleLocationChange}
          handleEmployeeClick={handleEmployeeClick}
          selectedEmployees={selectedEmployees}
        >
          <DateAndTime
            isDate={true}
            showDate={true}
            showMonth={true}
            showYear={true}
            isDay={true}
            isIcon={true}
            monthFormat="long"
            dataTimeWrapperClassName="d-inline-flex align-items-center colorSkyBlue bg-white planningDateWrapper"
            dataTimeClassName="mx-3 planningPlannedDate"
            dayClassName="me-1"
            iconFillColor="#8571b9"
            onDateChange={handleDateChange}
          />
        </HeaderWIthFilter>
      </div>
      <div className="row">
        <div className="col-12">
          <OverFlowWrapper className="dayPlanningOverviewTable">
            <PlanningTable
              selectedDate={date}
              companyId={companyId}
              selectedLocation={locations[selectedLocationIndex]}
              selectedEmployees={selectedEmployees}
              updateEmployeeSchedule={updateEmployeeSchedule}
              removeEmployee={removeEmployeefromSelected}
              />
          </OverFlowWrapper>
        </div>
      </div>
      {show && <PlanningTabs />}
    </>
  );
};

export default translate(DayPlanningOverview);