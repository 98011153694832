import React, { useState } from "react";
import { t } from "../CentralDataMangement/translation/Translation";
import Header from "../common/layout/Header";
import Search from "../common/atoms/Search";
import ResetBtn from "../common/atoms/ResetBtn";
import SearchBtn from "../common/atoms/SearchBtn";
import BackLink from "../common/atoms/BackLink";
import Button from "../common/atoms/Button";
import TableHeaderSpace from "../common/atoms/TableHeaderSpace";

const ManageCooperationAgreement = () => {
  const [activeTab, setActiveTab] = useState("all"); // Initialize state for active tab

  const tabs = [
    { key: "all", title: t("All") },
    { key: "pending", title: t("Pending") },
    { key: "signed", title: t("Signed") },
  ];

  function handleReset() {}
  function handleSearch() {}

  // Function to handle tab click and set active tab
  const handleTabClick = (key: string) => {
    setActiveTab(key);
  };

  return (
    <>
      <Header
        headerName={t("Manage cooperation agreement")}
        headerClassName="myAccountCardTitle"
      />
      <div className="manageContentBg">
        <div className="row search-bar pb-md-2">
          <BackLink backLink="/" />
          <div className="col-12 pb-3">
            <div>
              <ul className="nav nav-tabs border-0 createTabs">
                {tabs.map((tab) => (
                  <li
                    className={`nav-item me-4 ${
                      activeTab === tab.key ? "active" : ""
                    }`}
                    key={tab.key}
                    onClick={() => handleTabClick(tab.key)}
                  >
                    <Button
                      title={tab.title}
                      handleClick={() => {}}
                      className="btn"
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-3">
            <Search
              value=""
              placeholder={t("PC number")}
              handleChange={() => {}}
            />
          </div>
          <div className="col-3">
            <Search
              value=""
              placeholder={t("Function name")}
              handleChange={() => {}}
            />
          </div>
          <div className="col-3">
            <Search
              value=""
              placeholder={t("Minimum salary")}
              handleChange={() => {}}
            />
          </div>
          <div className="col-3 ms-auto">
            <div className="d-flex align-items-center justify-content-end">
              <ResetBtn handleResetClick={() => handleReset()} />
              <SearchBtn handleSearchClick={() => handleSearch()} />
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-hover customTableProperties">
            <thead>
              <tr className="TableHeader">
                <th>{t("Employer name")}</th>
                <th>
                  {t("Email")}
                  <br />
                  {t("Mobile number")}
                </th>
                <th>
                  {t("Company")}
                  <br />
                  {t("VAT number")}
                </th>
                <th>{t("Sales agent")}</th>
                <th>{t("Date of request")}</th>
                <th>{t("Date of start")}</th>
                <th>{t("Status")}</th>
                <th>{t("Actions")}</th>
              </tr>
              <TableHeaderSpace headerLength={10} />
            </thead>
            <tbody>
              <tr>
                <td>Name</td>
                <td>
                  email
                  <br />
                  mobile
                </td>
                <td>
                  company
                  <br />
                  Vat
                </td>
                <td>Sales agent</td>
                <td>Date of request</td>
                <td>Date of start</td>
                <td>Status</td>
                <td>Actions</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ManageCooperationAgreement;
